import React, { memo, useMemo, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { useLDUserFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { businessHomePath } from '@src/routes';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Root from '@src/components/root';
import SideView from '@src/components/ui/side_view';
import { Button } from '@src/components/ui_v2/buttons';
import { OdConfigIcon, SwitchViewIcon } from '@src/components/utils/icomoon/';

import ConfigForm from './config_form';
import OperationsDashboardTableList from './table/list';

import styles from './style.module.scss';

const DashboardMainView = () => {
  const { data: isMultiBusinessDashboardEnabled } = useLDUserFeatureQuery(window.Docyt.currentAdvisor.id, window.Docyt.Common.Constants.MULTI_BUSINESS_DASHBOARD);
  const [open, setOpen] = useState<boolean>(false);
  const handleShow = () => setOpen(true);
  const handleHide = () => setOpen(false);
  const [searchParams] = useSearchParams();

  const switchToOldViewUrl = useMemo(() => {
    if (searchParams.get('switch_to_old_view_tab')) {
      return `${businessHomePath()}?tab=${searchParams.get('switch_to_old_view_tab')}`;
    }
    return `${businessHomePath()}`;
  }, [searchParams]);

  return (
    <div>
      <div className={ styles['filter-top-container'] }>
        {isMultiBusinessDashboardEnabled && (
          <div>
            <Button
              className={ styles['switch-view-button'] }
              variant="outline"
              onClick={ () => backboneNavigateToUrl(switchToOldViewUrl) }
            >
              <SwitchViewIcon className={ styles['switch-view-icon'] } fontSize={ 24 } />
              Switch to Old View
            </Button>
          </div>
        )}
        <OdConfigIcon
          className={ styles['od-config-icon'] }
          fontSize={ 20 }
          onClick={ handleShow }
        />
      </div>
      <ConfigForm handleHide={ handleHide } open={ open } />
      <OperationsDashboardTableList />
    </div>
  );
};

const DashboardWrapper = () => {
  return (
    <Root>
      <SideView.Provider>
        <DashboardMainView />
        <SideView.Render />
      </SideView.Provider>
    </Root>
  );
};

export default memo(DashboardWrapper);
