import React, { useCallback } from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import styles from './styles.module.scss';

interface IEditProfileActionProps {
  onOpenEditProfile: () => void;
}

const EditProfileAction: React.FC<IEditProfileActionProps> = ({ onOpenEditProfile }) => {

  const handleOnEditProfile = useCallback(() => {
    onOpenEditProfile();
  }, [onOpenEditProfile]);

  return (
    <div className={ styles['action-item'] }>
      <ActionsDropdown.Action
        name="Edit Profile"
        onClick={ handleOnEditProfile }
      />
    </div>
  );
};

export default EditProfileAction;
