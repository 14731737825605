import React, { memo } from "react";

import ChatContainer from "@src/components/ai_chatbot/components/chat_container";
import styles from "@src/components/ai_chatbot/styles.module.scss";

const ChatBody = () => {
  return (
    <div className={styles["chat-content-section"]}>
      <ChatContainer />
    </div>
  );
};

export default memo(ChatBody);
