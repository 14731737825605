/* eslint-disable max-len */
import React, { useState, useEffect, useMemo, useCallback } from 'react';

import debounce from 'lodash/debounce';

import toastr from '@lib/toastr';
import { useCreateServiceDocumentSplits } from '@src/hooks/queries/accounts_payable/service_document_splits';
import { useUpdateTransactionServiceDocumentDoNotLearn } from '@src/hooks/queries/transaction_service_documents';
import { getServiceDocumentSplits } from '@src/requests/accounts_payable/service_document_splits';
import { useCreateDocumentNote, useDeleteDocumentNote } from '@src/requests/all_transactions';
import { IAccountingClass } from '@src/types/accounting_class';
import { IServiceDocumentSplit } from '@src/types/accounts_payable/service_document_split';
import { TID } from '@src/types/common';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { ICategorySplit } from '@src/components/common_v2/category_splits_modal/schema';
import VendorInput from '@src/components/reconciliation_center/journal_entries/header/vendor_input';
import CategorySplitsField from '@src/components/reconciliation_center/match_documents/add_adjustment/category_splits_field/index';
import MutationStatus from '@src/components/utils/mutation_status';

import TransactionTypeSelector from './transaction_type_selector';

import styles from '../../styles.module.scss';

interface IExpenseProps {
  businessId: TID;
  rowData: ITransactionServiceDocument;
  Verifiy: (boolean: boolean) => void;
  setRowData: (rowData: ITransactionServiceDocument) => void;
  vendor: string;
  setVendor: React.Dispatch<React.SetStateAction<string>>;
}
interface IVendorOption {
  value: string;
  label?: string;
  helper?: string;
}

const Expense = ({
  businessId,
  rowData,
  Verifiy,
  setRowData,
  vendor,
  setVendor,
}: IExpenseProps) => {
  const [noDocumentNeeded, setNoDocumentNeeded] = useState(rowData.noDocumentRequired || false);
  const [doNotLearn, setDoNotLearn] = useState(rowData.doNotLearn);
  const [noPushQbo, setNoPushQbo] = useState(rowData.noPushQbo);
  const [documentNote, setDocumentNote] = useState<string>(rowData.documentNote?.note || '');
  const [categorySplits, setCategorySplits] = useState<ICategorySplit[]>([]);
  const isDisabled = rowData.state === 'verified';

  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);

  const { mutateAsync: createDocumentNote } = useCreateDocumentNote();
  const { mutateAsync: deleteDocumentNote } = useDeleteDocumentNote();
  const updateTransactionServiceDocumentDoNotLearn = useUpdateTransactionServiceDocumentDoNotLearn();
  const { mutateAsync: updateTransactionServiceDocumentDoNotLearnMutate } = updateTransactionServiceDocumentDoNotLearn;

  const createServiceDocumentSplits = useCreateServiceDocumentSplits();
  const { mutate } = createServiceDocumentSplits;

  const objectToUpdate = {
    id:                 rowData.id,
    doNotLearn,
    noPushQbo,
    user_vendor_id:     vendor,
    vendor_id:          vendor,
    noDocumentRequired: noDocumentNeeded,
  };

  const debouncedCreateNote = useMemo(
    () => debounce((note: string, docId: number) => {
      createDocumentNote({ documentId: docId, note });
    }, 500),
    [createDocumentNote],
  );

  useEffect(() => {
    return () => {
      debouncedCreateNote.cancel();
    };
  }, [debouncedCreateNote]);

  const handleDocumentNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNote = e.target.value || '';
    setDocumentNote(newNote);
    debouncedCreateNote(newNote, rowData.documentId);
  };

  const handleNoPushQbo = async () => {
    await updateTransactionServiceDocumentDoNotLearnMutate({ ...objectToUpdate, noPushQbo: !noPushQbo });
    setNoPushQbo(!noPushQbo);
  };

  const handleNoDocumentNeeded = async () => {
    if (noDocumentNeeded) {
      setDocumentNote('');
      if (rowData.documentNote && 'id' in rowData.documentNote) {
        deleteDocumentNote({
          documentId: rowData.documentId,
          noteId:     rowData.documentNote.id,
        });
      }
    }
    setNoDocumentNeeded(!noDocumentNeeded);
    await updateTransactionServiceDocumentDoNotLearnMutate({ ...objectToUpdate, noDocumentRequired: !noDocumentNeeded });
  };

  const handleDocytAiLearnChange = async () => {
    await updateTransactionServiceDocumentDoNotLearnMutate({ ...objectToUpdate, doNotLearn: !doNotLearn });
    setDoNotLearn(!doNotLearn);
  };

  const handleVendorChange = async (item: IVendorOption): Promise<void> => {
    try {
      const result = await updateTransactionServiceDocumentDoNotLearnMutate({
        ...objectToUpdate,
        user_vendor_id: item?.value,
        vendor_id:      item?.value,
      });

      // Build category splits based on the vendor change
      if (result) {
        let returnCategorySplits: ICategorySplit[] = [];
        const { transactionServiceDocument } = result;

        const categories = transactionServiceDocument.category.split(';');

        if (categories.length > 1) {
          try {
            const res = await getServiceDocumentSplits({
              documentID: rowData.documentId,
            });

            returnCategorySplits = res?.serviceDocumentSplits.map((split: IServiceDocumentSplit) => ({
              amount:              split.amount,
              chartOfAccountId:    split.chartOfAccountId,
              chartOfAccountName:  split.chartOfAccountName,
              businessId,
              accountingClassId:   split.accountingClass?.id,
              accountingClassName: split.accountingClass?.name,
              businessName:        null,
              memo:                split.memo,
              percentage:          Number(((parseFloat(split.amount || '0') / parseFloat(transactionServiceDocument.amount)) * 100).toFixed(2)),
            })) || [];
          } catch (error) {
            returnCategorySplits = [];
            const errorMessage = (error as Error)?.message || 'An unknown error occurred';
            toastr.error(`Failed to fetch category splits: ${errorMessage}`, 'Error');
          }
        } else {
          returnCategorySplits = categories.map(() => ({
            amount:             transactionServiceDocument.amount,
            chartOfAccountId:   transactionServiceDocument.chartOfAccountId,
            chartOfAccountName: transactionServiceDocument.chartOfAccount?.displayName,
            businessId,
            accountingClassId:  typeof transactionServiceDocument.accountingClass === 'object'
              ? (transactionServiceDocument.accountingClass as IAccountingClass)?.id || null : null,
            accountingClassName: typeof transactionServiceDocument.accountingClass === 'object'
              ? (transactionServiceDocument.accountingClass as IAccountingClass)?.name || null : null,
            businessName: null,
            memo:         transactionServiceDocument.description,
            percentage:   100,
          }));
        }

        setCategorySplits(returnCategorySplits);
        setVendor(item?.value || '');
      }
    } catch (error) {
      const errorMessage = (error as Error)?.message || 'An unknown error occurred';
      toastr.error(`Failed to update vendor: ${errorMessage}`, 'Error');
    }
  };

  const getCategorySplits = useCallback(async (): Promise<ICategorySplit[]> => {
    if (!rowData.category) {
      return [
        {
          amount:              rowData.amount,
          chartOfAccountId:    rowData.chartOfAccountId,
          chartOfAccountName:  rowData.chartOfAccount?.displayName,
          businessId,
          accountingClassId:   null,
          accountingClassName: null,
          businessName:        null,
          memo:                rowData.description,
          percentage:          100,
        },
      ];
    }

    const categories = rowData.category.split(';');

    if (categories.length > 1) {
      try {
        const res = await getServiceDocumentSplits({
          documentID: rowData.documentId,
        });

        return res?.serviceDocumentSplits.map((item: IServiceDocumentSplit) => ({
          amount:              item.amount,
          chartOfAccountId:    item.chartOfAccountId,
          chartOfAccountName:  item.chartOfAccountName,
          businessId,
          accountingClassId:   item.accountingClass?.id,
          accountingClassName: item.accountingClass?.name,
          businessName:        null,
          memo:                item.memo,
          percentage:          Number(((parseFloat(item.amount || '0') / parseFloat(rowData.amount)) * 100).toFixed(2)),
        })) || [];
      } catch (error) {
        const errorMessage = (error as Error)?.message || 'An unknown error occurred';
        toastr.error(`Failed to fetch category splits: ${errorMessage}`, 'Error');
        return [];
      }
    }

    return categories.map(() => ({
      amount:             rowData.amount,
      chartOfAccountId:   rowData.chartOfAccountId,
      chartOfAccountName: rowData.chartOfAccount?.displayName,
      businessId,
      accountingClassId:  typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.id || null : null,
      accountingClassName: typeof rowData.accountingClass === 'object'
        ? (rowData.accountingClass as IAccountingClass)?.name || null : null,
      businessName: null,
      memo:         rowData.description,
      percentage:   100,
    }));
  }, [
    rowData.category,
    rowData.documentId,
    rowData.amount,
    rowData.chartOfAccountId,
    rowData.chartOfAccount,
    rowData.accountingClass,
    rowData.description,
    businessId,
  ]);

  useEffect(() => {
    const loadCategorySplits = async () => {
      const splits = await getCategorySplits();
      setCategorySplits(splits);
    };
    loadCategorySplits();
  }, [getCategorySplits]);

  const handleCategorySplitsChange = (splitChanges: ICategorySplit[] | undefined) => {
    if (splitChanges) {
      mutate(
        {
          documentId:            rowData.documentId,
          serviceDocumentSplits: splitChanges,
        },
        {
          onSuccess: () => {
            setSuccessMsg(
              splitChanges.length > 1
                ? window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SPLIT
                : window.Docyt.Common.Constants.Messages.CHART_OF_ACCOUNT_SET,
            );
          },
        },
      );
      setCategorySplits(splitChanges);
    } else {
      setCategorySplits([]); // When splitChanges is undefined, set it to an empty array.
    }
  };

  useEffect(() => {
    let isVerify = false;

    const isVendorEmpty = !vendor || vendor === 'undefined' || vendor === '';
    const isCategorySplitsEmpty = !categorySplits.length;
    const isDocumentNoteRequired = noDocumentNeeded && !documentNote;

    if (isVendorEmpty || isCategorySplitsEmpty || isDocumentNoteRequired) {
      isVerify = true;
    }

    Verifiy(isVerify);
  }, [vendor, categorySplits, noDocumentNeeded, documentNote, Verifiy]);

  return (
    <div key={ rowData.id }>
      <MutationStatus mutation={ createServiceDocumentSplits } successMessage={ successMsg } />
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />
        <div className={ styles['sidebar-data'] }>
          <span>Vendor*</span>
          <div style={ { width: '280px' } }>
            <VendorInput
              businessId={ businessId }
              disabled={ isDisabled }
              handleSelected={ handleVendorChange }
              value={ vendor }
            />
          </div>
        </div>
        <div className={ styles['sidebar-data'] }>
          <CategorySplitsField
            adjustmentAmount={ rowData.amount }
            isBusinessReadonly={ false }
            isReadonly={ isDisabled }
            label="Category*"
            summaryTitle="Invoice"
            value={ categorySplits }
            onChange={ handleCategorySplitsChange }
          />
        </div>
        <div className={ styles['sidebar-data'] }>
          <div className={ styles['docyt-ai-learn-container'] }>
            <div className={ styles['switch-wrapper'] }>
              <input
                checked={ !doNotLearn }
                className={ styles['switch-input'] }
                disabled={ isDisabled }
                type="checkbox"
                onChange={ () => handleDocytAiLearnChange() }
              />
              <div className={ styles.switch }>
                <span className={ styles.slider } />
              </div>
            </div>
            <span>Docyt AI will learn the above categorization details</span>
          </div>
        </div>
      </div>
      <div className={ styles['sidebar-type-config'] }>
        <div className={ styles['sidebar-data-checkbox'] }>
          <input
            checked={ noDocumentNeeded }
            className={ isDisabled ? styles['disabled-checkbox'] : '' }
            id="no-document-needed"
            type="checkbox"
            onChange={ isDisabled ? () => {} : handleNoDocumentNeeded }
          />
          <label htmlFor="no-document-needed">No document needed</label>
          <a
            href={ `/businesses/${businessId}/reconciliation_center/settings` }
            style={ { color: '#2196F3', marginLeft: '8px', position: 'absolute', right: '26px' } }
          >
            Docyt AI Settings
          </a>
        </div>
        {noDocumentNeeded && (
          <div>
            <textarea
              key={ rowData.documentId }
              disabled={ isDisabled }
              placeholder="Add note"
              rows={ 2 }
              style={ {
                borderColor: documentNote || isDisabled ? 'initial' : 'red',
                width:       '100%',
              } }
              value={ documentNote }
              onChange={ handleDocumentNoteChange }
            />
            {!documentNote && !isDisabled && (
              <div style={ { color: 'red', marginTop: '4px', display: 'flex', alignItems: 'center', gap: '4px' } }>
                <i className="fa fa-warning" />
                Note is required.
              </div>
            )}
          </div>
        )}
        <div className={ styles['sidebar-data-checkbox'] }>
          <input
            checked={ noPushQbo }
            className={ isDisabled ? styles['disabled-checkbox'] : '' }
            id="no-push-qbo"
            type="checkbox"
            onChange={ isDisabled ? () => {} : handleNoPushQbo }
          />
          <label htmlFor="no-push-qbo">Do not push the transaction to ledger</label>
        </div>
      </div>
    </div>
  );
};

export default Expense;
