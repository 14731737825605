import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import Modal from '@src/components/ui/modal';

interface IDeleteDocumentProps extends IUseModalProps {}

const DeleteDocumentModal = ({ isOpen, onDone, onCancel }: IDeleteDocumentProps): JSX.Element => {
  return (
    <Modal.Standard
      proceedTitle="Delete"
      show={ isOpen }
      title="Delete Document?"
      onCancel={ onCancel }
      onProceed={ onDone }
    >
      {() => <div className="text-center font-16">Delete attached document from transaction?</div>}
    </Modal.Standard>
  );
};

const useDeleteDocumentModal = makeUseModal(DeleteDocumentModal);

export { IDeleteDocumentProps, useDeleteDocumentModal, DeleteDocumentModal as default };
