import React, { useCallback } from "react";
import { useSetRecoilState } from "recoil";
import classNames from "classnames";

import { TUUID } from "@src/types/common";
import { Button } from "@src/components/ui_v2/buttons";

import { activeThreadIdAtom } from "@src/components/ai_chatbot/atoms";
import { activeChannelIdAtom } from "@src/components/ai_chatbot/atoms";

import styles from "@src/components/ai_chatbot/styles.module.scss";

interface IStartChatThreadProps {
  message?: string;
  isNewThread?: boolean;
  buttonText: string;
  prefixIcon?: React.ReactElement;
  channelId?: string;
  isActive?: boolean;
  threadId?: TUUID;
}

const StartChatThread = ({
  buttonText,
  prefixIcon,
  channelId,
  isActive = false,
  threadId,
}: IStartChatThreadProps) => {
  const setActiveThreadId = useSetRecoilState(activeThreadIdAtom);
  const setActiveChannelId = useSetRecoilState(activeChannelIdAtom);

  const buttonClass = classNames(
    styles['chat-btn'],
    styles['is-ellipsis'],
    { active: isActive },
  );

  const handleOnButtonClick = useCallback(() => {
    setActiveThreadId(threadId as string);
    setActiveChannelId(channelId as string);
  }, [threadId, channelId, setActiveThreadId, setActiveChannelId]);

  return (
    <Button
      className={ buttonClass }
      prefixIcon={ prefixIcon }
      variant="link"
      onClick={ handleOnButtonClick }
    >
      {buttonText}
    </Button>
  );
};

export default StartChatThread;
