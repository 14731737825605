import React from 'react';

import ChannelList from '@sendbird/uikit-react/ChannelList';

import ChannelListHeader from '@src/components/chat_central/components/channel_list/header';
import ChannelListItem from '@src/components/chat_central/components/channel_list/item';
import Loader from '@src/components/chat_central/components/channel_list/loader';
import { IChannelListProps, IRenderChannelPreviewProps } from '@src/components/chat_central/types';

import styles from '@src/components/chat_central/styles.module.scss';

interface IChannelListViewProps extends IChannelListProps {
  setChannelUrl: (channelUrl: string) => void;
  currentChannelUrl?: string;
  disableAutoSelect?: boolean;
}

const ChannelListView = (
  {
    currentChannelUrl,
    disableAutoSelect = false,
    setChannelUrl,
  }
  :IChannelListViewProps,
) => {
  const [gotChannelFromUrl] = React.useState(false);

  return (
    <ChannelList
      className={ styles['chat-list-container'] }
      disableAutoSelect={ gotChannelFromUrl || disableAutoSelect }
      queries={ {
        channelListQuery: {
          customTypesFilter: ['business_channel'],
          includeEmpty:      true,
          limit:             30,
          order:             'latest_last_message',
        },
      } }
      renderChannelPreview={ (item: IRenderChannelPreviewProps) => {
        return <ChannelListItem { ...item } currentChannelUrl={ currentChannelUrl } />;
      } }
      renderHeader={ () => <ChannelListHeader /> }
      renderPlaceHolderLoading={ () => <Loader /> }
      onChannelSelect={ (channel) => {
        if (channel?.url) {
          setChannelUrl(channel.url);
        }
      } }
    />
  );
};

export default ChannelListView;
