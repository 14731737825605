import React, { useMemo } from 'react';

import { TAuditScanStatus } from '@src/types/operational_dashboard/audit_scan';

import HoverTooltipWrapper from '@src/components/operational_dashboard/components/custom_cells/hover_tooltip_wrapper';
import NumberBox from '@src/components/operational_dashboard/components/custom_cells/number_box';
import AuditScanTooltip from '@src/components/operational_dashboard/components/tooltips/audit_scan_tooltip';
import { IListItem } from '@src/components/operational_dashboard/types';
import { CheckMarkIcon } from '@src/components/utils/icomoon';

interface IReconciliationItemProps {
  tooltipData: IListItem
  value: number;
  lastAuditedOn?: string | number;
  auditScanStatus: TAuditScanStatus;
  generalLedgerConnectionIssues: boolean;
}

const AuditScanItem = ({
  tooltipData,
  value,
  lastAuditedOn,
  auditScanStatus,
  generalLedgerConnectionIssues,
}: IReconciliationItemProps) => {
  const renderValue = useMemo(() => {
    if (generalLedgerConnectionIssues) {
      return <NumberBox value={ { value: true, type: 'indicator' } } />;
    }

    if (auditScanStatus === 'completed') {
      return <CheckMarkIcon color="gray" fontSize={ 20 } />;
    }

    return <NumberBox value={ { value, type: 'number' } } />;
  }, [value, auditScanStatus, generalLedgerConnectionIssues]);

  return (
    <HoverTooltipWrapper
      content={ (
        value !== undefined && (
          <AuditScanTooltip
            auditScanCount={ value }
            item={ tooltipData }
            lastAuditedOn={ lastAuditedOn }
          />
        )
      ) }
    >
      {renderValue}
    </HoverTooltipWrapper>
  );
};

export default AuditScanItem;
