import React from 'react';

import classNames from 'classnames';

import Icomoon, { IIcomoonIconProps } from './icomoon';

import styles from './styles.module.scss';

interface ICheckMarkIconProps extends IIcomoonIconProps {
  color?: 'black' | 'white' | 'gray',
}

const CheckMarkIcon = ({
  color,
  className,
  ...props
}: ICheckMarkIconProps) => {
  const classes = classNames(className, color && styles[`color-${color}`]);
  return (
    <Icomoon
      { ...props }
      className={ classes }
      iconName="check-mark"
    />
  );
};

export default CheckMarkIcon;
