import React, { memo } from 'react';

import BookgptList from '@src/components/operational_dashboard/table/bookgpt_list';
import Root from '@src/components/root';
import SideView from '@src/components/ui/side_view';

const DashboardBookgptWrapper = () => {
  return (
    <Root>
      <SideView.Provider>
        <BookgptList />
        <SideView.Render />
      </SideView.Provider>
    </Root>
  );
};
export default memo(DashboardBookgptWrapper);
