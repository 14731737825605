module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, index, len, ref, vendor;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          <h1 class="modal-title pull-left m-l-10 font-bold">');
    
      __out.push(__sanitize(this.label));
    
      __out.push(':</h1>\n        </div>\n        <div class=\'modal-body\'>\n            <select class="vendor-list show-tick" data-width="100%" title="Choose one of the following..." data-live-search="true">\n              ');
    
      ref = this.vendors;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        vendor = ref[index];
        __out.push('\n                <option data-content=\'\n                  <span class="header__userpic inline-block align-middle line-height-40" style="font-size: 0;">\n                    ');
        if (vendor.image_url) {
          __out.push('\n                    <img src="');
          __out.push(__sanitize(vendor.image_url));
          __out.push('" class="header__userpic-img">\n                    ');
        } else {
          __out.push('\n                    <i class="clients__client-status-icon icon icon-smile"></i>\n                    ');
        }
        __out.push('\n                  </span>\n                  <span>');
        __out.push(__sanitize(vendor.name));
        __out.push('</span>\n                  <small class="text-muted">Invoices: ');
        __out.push(__sanitize(vendor.invoices));
        __out.push('</small>\'>');
        __out.push(__sanitize(vendor.name));
        __out.push('</option>\n              ');
      }
    
      __out.push('\n            </select>\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button save-js ');
    
      __out.push(__sanitize(true ? 'disabled' : ''));
    
      __out.push('" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">Merge</span></button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}