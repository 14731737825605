import React, { useMemo } from 'react';

import { IDocumentRelative } from '@src/types/document_relative';
import { TTransactionType } from '@src/types/transaction_service_documents';

import Table from '@src/components/ui/table';

import Item from './list_item/item';

interface IMatchedDocumentListProps {
  documents: IDocumentRelative[],
  transactionType: TTransactionType
}

const MatchedDocumentList = ({
  documents,
  transactionType,
}: IMatchedDocumentListProps): JSX.Element => {
  const isExpenseType = useMemo(() => {
    return window.Docyt.Common.Constants.EXPENSE_TRANSACTION_TYPES.includes(transactionType);
  }, [transactionType]);

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell widthPercent={ 30 }>Document ID</Table.Cell>
          <Table.Cell textAlign="center" widthPercent={ 50 }>NAME</Table.Cell>
          <Table.Cell textAlign="center" widthPercent={ 20 }>{ isExpenseType ? 'Amount' : '' }</Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          documents.map((document) => {
            return (
              <Item
                key={ document.id }
                document={ document }
                isExpenseType={ isExpenseType }
              />
            );
          })
        }
      </Table.Body>
    </Table>
  );
};

export default MatchedDocumentList;
