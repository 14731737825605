module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var contact, i, len, ref;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog contact-picker-modal-dialog chat-participants-modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class="modal-header">\n        <h1 class="modal-title m-l-10 font-bold">');
    
      __out.push(__sanitize(this.label));
    
      __out.push('</h1>\n        <button type="button" class="close" data-dismiss="modal" aria-label="Close">\n          <span aria-hidden="true">&times;</span>\n        </button>\n      </div>\n      <div class=\'modal-body\'>\n        <div class="contacts-list">\n          <table class="table table-responsive">\n            <tbody>\n              ');
    
      ref = this.contacts.models;
      for (i = 0, len = ref.length; i < len; i++) {
        contact = ref[i];
        __out.push('\n              <tr>\n                <td class="avatar-column">\n                  <div class="header__userpic inline-block">\n                    ');
        if (contact.get('avatar')) {
          __out.push('\n                    <img src="');
          __out.push(__sanitize(contact.get('avatar').avatar_url));
          __out.push('" class="header__userpic-img">\n                    ');
        } else {
          __out.push('\n                    <i class="clients__client-status-icon icon icon-smile"></i>\n                    ');
        }
        __out.push('\n                  </div>\n                </td>\n                <td class="font-18">');
        __out.push(__sanitize(contact.get('parsed_fullname')));
        __out.push('</td>\n              </tr>\n              ');
      }
    
      __out.push('\n            </tbody>\n          </table>\n        </div>\n        ');
    
      if (this.contacts.models.length === 0) {
        __out.push('\n        <div class="text-center m-t-120">\n          <p class="font-14">No participants in this chat yet.</p>\n        </div>\n        ');
      }
    
      __out.push('\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}