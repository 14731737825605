import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCreateRevenueSystem } from '@src/hooks/queries/revenue_systems';
import { IRevenueService } from '@src/types/revenue_services';

import Modal from '@src/components/ui/modal';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './form';
import { IAddRevenueSystemValues } from './schema';
import { useConnectRevenueSystemModal } from '../connect_system/connect_system_modal';

interface IAddRevenueSystemModalProps extends IUseModalProps {
  revenueService: IRevenueService;
  source?: string | undefined;
}

const AddRevenueSystemModal = ({
  isOpen,
  onDone,
  revenueService,
  onCancel,
  source,
  ...props
}: IAddRevenueSystemModalProps) => {
  const createRevenueSystem = useCreateRevenueSystem();
  const { mutate } = createRevenueSystem;

  const handleSubmit = useCallback(
    (data: IAddRevenueSystemValues) => {
      mutate(
        {
          revenueServiceId: revenueService.id,
          data,
        },
        {
          onSuccess: onDone,
        },
      );
    },
    [mutate, revenueService.id, onDone],
  );

  const connectSystemModal = useConnectRevenueSystemModal();

  const handleOnCancel = useCallback(() => {
    if (source === 'ConnectRevenueSystem') {
      connectSystemModal.open();
    }
    onCancel?.();
  }, [connectSystemModal, onCancel, source]);

  return (
    <>
      <Modal.Form
        cancelTitle="Back"
        proceedTitle="Add"
        show={ isOpen }
        title="Add Revenue System"
        onCancel={ handleOnCancel }
        { ...props }
      >
        {({ formId }) => (
          <>
            <MutationStatus mutation={ createRevenueSystem } />
            <Form
              businessId={ revenueService.businessId }
              formId={ formId }
              onSubmit={ handleSubmit }
            />
          </>
        )}
      </Modal.Form>
      {connectSystemModal.Component && (
        <connectSystemModal.Component key="connect-system-modal" { ...connectSystemModal.props } />
      )}
    </>
  );
};

const useAddRevenueSystemModal = makeUseModal(AddRevenueSystemModal);

export { useAddRevenueSystemModal, AddRevenueSystemModal as default };
