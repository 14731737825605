import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { activateBookGPT } from '@src/requests/activate_bookgpt';
import { TID } from '@src/types/common';

export const useActivateBookGPT = () => {
  const queryClient = useQueryClient();

  return useMutation<void, Error & AxiosError, { businessId: TID }>(
    ({ businessId }) => activateBookGPT({ business_id: businessId }),
    {
      onSuccess: (_, { businessId }) => {
        // Invalidate relevant queries after successful activation
        queryClient.invalidateQueries([QueryKey.bookGPT, businessId]);
      },
    },
  );
};
