import React from 'react';

import { IBusinessMetricValue, TBusinessMetricValueSortColumn } from '@src/types/business_metric_value';
import { IMetric } from '@src/types/metrics';

import Spinner from '@src/components/ui/spinner';
import { Button } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import Section from '@src/components/utils_v2/section';

import EditableNumberInput from './editable_number_input'; // Adjust the import path as necessary
import { IMetricValuesCollection } from '../hooks';

import styles from '../styles.module.scss';

interface IMetricDetailsListParams {
  metric: IMetric;
  collection: IMetricValuesCollection;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  isEditMode: boolean;
  editingValues: IBusinessMetricValue[];
  onValueChange: (date: string, value: number) => void;
}

const MetricDetailsList = ({
  metric,
  collection,
  setIsEditMode,
  isEditMode,
  editingValues,
  onValueChange,
}: IMetricDetailsListParams): JSX.Element => {
  const handleValueChange = (date: string) => (value: number) => {
    if (value !== null) {
      onValueChange(date, value);
    }
  };

  const handleAddData = () => {
    setIsEditMode(true);
  };

  let displayData;

  if (isEditMode) {
    displayData = editingValues;
  } else {
    displayData = collection.records.length > 0 ? collection.records : [];
  }

  const renderValueColumn = (metricValue: IBusinessMetricValue) => {
    return isEditMode ? (
      <EditableNumberInput
        handleValueChange={ handleValueChange }
        metricValue={ metricValue }
      />
    ) : (
      <span>{metricValue.value?.toString() || '0'}</span>
    );
  };

  return (
    <div>
      {collection.query.isLoading ? <Spinner /> : null}
      {collection.query.isError ? <div>Error loading metrics. Please try again later.</div> : null}
      <Section.Provider section={ collection.section }>
        <CollectionTable<IBusinessMetricValue, TBusinessMetricValueSortColumn>
          height="100%"
          noDataMessage={ (
            <>
              No data available for
              {' '}
              {metric.name}
              <br />
              <br />
              <Button
                className={ styles['add-data-button'] }
                variant="link"
                onClick={ handleAddData }
              >
                Add Data Manually
              </Button>
            </>
          ) }
          records={ displayData }
          sorting={ collection.sorting }
        >
          <CollectionTable.DateColumn<IBusinessMetricValue, TBusinessMetricValueSortColumn>
            name="date"
            sortColumn="date"
            title="Date"
            value={ (metricValue) => metricValue.date }
            width="25%"
          />
          <CollectionTable.TextColumn<IBusinessMetricValue, TBusinessMetricValueSortColumn>
            name="value"
            sortColumn="value"
            title={ metric.name }
            tooltip={ (metricValue) => metricValue.value?.toString() || '' }
            value={ renderValueColumn }
            width="25%"
          />
        </CollectionTable>
      </Section.Provider>
    </div>
  );
};

export default MetricDetailsList;
