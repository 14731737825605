import React, { useCallback } from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';

interface IResetValidationErrorProps extends IUseModalProps {
  title: string,
  message: string
}

const ResetValidationError = ({
  title = 'Warning',
  message,
  isOpen,
  onCancel,
}: IResetValidationErrorProps) => {
  const handleConfirm = useCallback((e) => {
    e.preventDefault();

    onCancel();
  }, [onCancel]);

  return (
    <Modal
      dialogClassName="bank-statement-validation-error-modal"
      region="second-modal-region"
      show={ isOpen }
      title={ title }
    >
      <Modal.Body>
        <span>
          { message }
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsColor="blue"
          title="Ok"
          onClick={ handleConfirm }
        />
      </Modal.Footer>
    </Modal>
  );
};

const useResetValidationErrorModal = makeUseModal(ResetValidationError);

export {
  IResetValidationErrorProps,
  useResetValidationErrorModal,
  ResetValidationError as default,
};
