import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IImportDataFromLedgerCompletedFayeEvent } from '@src/types/docyt_events/import_data_from_ledger_completed';

import { useFayeChannel } from './common';

const useOperationalDashboardFayeChannel = (
  businessId: TID,
  callback: TFayeEventCallback<IImportDataFromLedgerCompletedFayeEvent>,
) => {
  useFayeChannel(
    `/import_data_from_ledger_completed-${businessId}`,
    callback,
  );
};

export {
  useOperationalDashboardFayeChannel,
};
