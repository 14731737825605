import React, { useCallback, useState } from 'react';

import { useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useAddressAuthorization } from '@src/hooks/queries/vendor_address_book';
import { IAddress } from '@src/types/address';
import { IBusinessVendor } from '@src/types/business_vendors';
import { TID } from '@src/types/common';

import { SuccessNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import {
  QBOOutlineIcon,
  EditIcon,
  TrashcanIcon,
  AuthhorizedAddressIcon,
  UnauthhorizedAddressIcon,
  WarningShieldIcon,
} from '@src/components/utils/icomoon';

import {
  useLedgerAddressModal,
  useAddressEditBookModal,
  useAddressAuthorizationModal,
  useDeleteAddressBookModal,
} from '../../modal';

import styles from '../styles.module.scss';

interface IAddressActionProps {
  businessId: TID,
  vendor: IBusinessVendor,
  setVendorData: React.Dispatch<React.SetStateAction<any>>,
  address: IAddress,
}

const AddressAction = ({
  vendor,
  businessId,
  setVendorData,
  address,
}: IAddressActionProps) => {
  const queryClient = useQueryClient();
  const addressEditBookModal = useAddressEditBookModal();
  const addressAuthorizationModal = useAddressAuthorizationModal();
  const ledgerAddressModal = useLedgerAddressModal();
  const addressAuthorization = useAddressAuthorization();
  const deleteAddressBookModal = useDeleteAddressBookModal();
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const { mutate: authorizeAddress } = addressAuthorization;

  const handleError = useCallback(() => {
    addressAuthorizationModal.open();
  }, [addressAuthorizationModal]);

  const handleAuthorize = useCallback(() => {
    setSuccessMessage(undefined);
    authorizeAddress(
      {
        id:           address.id,
        vendorId:     vendor.id,
        businessId:   vendor.businessId,
        isAuthorized: !address.isAuthorized,
      },
      {
        onSuccess: (response) => {
          queryClient.invalidateQueries(QueryKey.businessVendors);

          const newAddress = response.address;
          const updatedVendor = {
            ...vendor,
            addresses: [...(vendor.addresses || []), newAddress],
          };

          setVendorData(updatedVendor);
          setSuccessMessage(!address.isAuthorized ? 'Address has been successfully authorized.'
            : 'Address has been successfully unauthorized.');
        },
        onError: handleError,
      },
    );
  }, [handleError, vendor, address, authorizeAddress, setVendorData, queryClient]);

  return (
    <>
      {(addressAuthorization.isLoading) && <Spinner />}
      {successMessage && (
      <SuccessNotification
        message={ successMessage }
        onHidden={ () => setSuccessMessage(undefined) }
      />
      )}

      <div className={ styles.header }>
        <ActionsDropdown className={ styles['dropdown-icon-bg'] }>
          <ActionsDropdown.Action
            className={ styles['dropdown-item'] }
            icon={ <EditIcon fontSize={ 18 } /> }
            title="Edit Address"
            onClick={ addressEditBookModal.open }
          />
          <ActionsDropdown.Action
            className={ styles['dropdown-item'] }
            icon={ !address.isAuthorized ? <AuthhorizedAddressIcon fontSize={ 20 } />
              : <UnauthhorizedAddressIcon fontSize={ 20 } /> }
            title={ !address.isAuthorized ? 'Set as Authorized Address' : 'Unauthorize Address' }
            onClick={ handleAuthorize }
          />
          {address?.id !== vendor?.ledgerAddressId && (
          <ActionsDropdown.Action
            className={ styles['dropdown-item'] }
            icon={ <QBOOutlineIcon fontSize={ 20 } /> }
            title="Set as Ledger Address"
            onClick={ ledgerAddressModal.open }
          />
          )}
          <ActionsDropdown.Action
            danger
            className={ styles['dropdown-item'] }
            icon={ <TrashcanIcon fontSize={ 18 } /> }
            title="Delete Address"
            onClick={ deleteAddressBookModal.open }
          />
        </ActionsDropdown>
      </div>

      <addressEditBookModal.Component
        address={ address }
        formId="edit_address_form"
        setVendorData={ setVendorData }
        { ...addressEditBookModal.props }
        businessId={ businessId }
        vendor={ vendor }
      />
      <addressAuthorizationModal.Component
        text={ (
          <div className="text-left">
            <div className={ styles['modal-content'] }>
              {!address.isAuthorized ? (
                <>
                  <span>
                    Only an authorized signatory can authorize payment
                    addresses to be used when making payments by Docyt Check.
                  </span>
                  <br />
                  <span>
                    Contact
                    {' '}
                    <a className={ styles['modal-email'] } href="mailto:support@docyt.com">support@docyt.com</a>
                    {' '}
                    for more information.
                  </span>
                </>
              ) : (
                <>
                  <span>
                    Only an authorized signatory can unauthorize payment addresses.
                  </span>
                  <br />
                  <span>
                    Contact
                    {' '}
                    <a className={ styles['modal-email'] } href="mailto:support@docyt.com">support@docyt.com</a>
                    {' '}
                    for more information.
                  </span>
                </>
              )}

            </div>
          </div>
          ) }
        title={ (
          <div style={ { display: 'flex', justifyContent: 'center', alignItems: 'center' } }>
            <WarningShieldIcon fontSize={ 24 } />
            <p className={ styles['modal-title'] }>
              {!address.isAuthorized ? 'Cannot Authorize Address' : 'Cannot Unauthorize Address'}
            </p>
          </div>
          ) }
        { ...addressAuthorizationModal.props }
      />
      <deleteAddressBookModal.Component
        address={ address }
        setVendorData={ setVendorData }
        text={ (
          <div className="text-left">
            <div className={ styles['modal-content'] }>
              <p>
                Are you sure you want to delete this address?
              </p>

            </div>
          </div>
        ) }
        title={ (
          <p className={ styles['modal-title'] }>
            Delete Address
          </p>
        ) }
        vendor={ vendor }
        { ...deleteAddressBookModal.props }
      />
    </>
  );
};

export default React.memo(AddressAction);
