import { IAccountsPayableServiceDocument } from '@src/types/accounts_payable/accounts_payable_service_documents';
import { IDwollaCustomer } from '@src/types/dwolla_customer';

const totalAmount = (invoices: IAccountsPayableServiceDocument[]) => {
  return invoices.reduce((subtotal: number, invoice: IAccountsPayableServiceDocument) => {
    if (
      [window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_PAYMENT,
        window.configData.ap_invoice_states.DOCUMENT_STATE_READY_FOR_CREDIT,
        window.configData.ap_invoice_states.DOCUMENT_STATE_APPROVAL,
      ].includes(invoice.state)
    ) {
      return subtotal + parseFloat(invoice.invoiceAmount);
    }
    return subtotal;
  }, 0);
};

const bulkPaymentModalTitle = (type: string) => {
  let title;
  if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CASH) {
    title = 'Select Estimated Date of Payment';
  } else if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CREDIT_CARD) {
    title = 'Mark as Paid by Credit Card';
  } else if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_BANK_ACCOUNT) {
    title = 'Mark as Paid by Bank Account';
  } else if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_ADVANCE_SETTLEMENT) {
    title = 'Advanced Settlement';
  } else if ([window.Docyt.Common.Constants.BULK_PAY_BY_DOCYT_CHECK,
    window.Docyt.Common.Constants.BULK_PAY_BY_SELF_PRINT_CHECK].includes(type)) {
    title = 'Multiple vendors and account numbers detected';
  } else if (type === window.Docyt.Common.Constants.BULK_PAY_BY_DOCYT_ACH) {
    title = 'ACH Transfer';
  }

  return title;
};

const paymentMode = (type?: string) => {
  if (!type) return undefined;

  let mode;

  if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CASH) {
    mode = window.Docyt.Common.Constants.INVOICE_PAYMENT_MODE_CASH;
  } else if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_CREDIT_CARD) {
    mode = window.Docyt.Common.Constants.INVOICE_PAYMENT_MODE_CREDIT_CARD;
  } else if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_BANK_ACCOUNT) {
    mode = window.Docyt.Common.Constants.INVOICE_PAYMENT_MODE_BANK_ACCOUNT;
  } else if (type === window.Docyt.Common.Constants.BULK_MARK_AS_PAID.PAY_BY_ADVANCE_SETTLEMENT) {
    mode = window.Docyt.Common.Constants.INVOICE_PAYMENT_MODE_ADVANCED_SETTLEMENT;
  }

  return mode;
};

const dwollAddress = (dwollaCustomer?: IDwollaCustomer) => {
  if (!dwollaCustomer) return '---';

  const addressInfo = [dwollaCustomer.address1, dwollaCustomer.address2, dwollaCustomer.city, dwollaCustomer.state, dwollaCustomer.postalCode].filter(Boolean).join(', ');
  return addressInfo || '---';
};

export {
  totalAmount,
  bulkPaymentModalTitle,
  paymentMode,
  dwollAddress,
};
