import { array, object, string } from 'yup';

const signUpValidation = object({
  email: string().trim().email('Email must be a valid email.').required('Email is required.'),
  fullName: string().trim().required('Full name is required.'),
});

const setPasswordValidation = object({
  password: string().test((value, validationContext) => {
    const { createError } = validationContext;

    if (!value) {
      return createError({ message: 'Password is required' });
    }

    if (!window.PASSWORD_REGEXP.test(value)) {
      return createError({ message: 'Please enter more symbols' });
    }

    return true;
  }),
  confirmPassword: string().test((value, validationContext) => {
    const {
      createError,
      parent: { password },
    } = validationContext;

    if (!value || password !== value) {
      return createError({ message: 'Please enter correct password' });
    }

    return true;
  }),
});

const teamMemberValidation = object({
  members: array()
    .of(
      object({
        name: string().trim().required('Name is required'),
        email: string().trim().email('Email address must be a valid email').required('Email address is required'),
      })
    )
    .min(1, 'At least one team member is required'),
});

interface ISignUpFormValues {
  fullName: string;
  email: string;
}

interface ISetPasswordFormValues {
  password: string;
  confirmPassword: string;
}

interface ITeamMemberFormValues {
  members: {
    name: string;
    email: string;
  }[];
}

export {
  ISetPasswordFormValues,
  ISignUpFormValues,
  ITeamMemberFormValues,
  setPasswordValidation,
  signUpValidation,
  teamMemberValidation,
};
