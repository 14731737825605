import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "@src/hooks/utils";

import AiChatSendButton from "@src/components/ai_chatbot/components/chat_input_send_button";
import TextAreaWIthSuffixIcon from "@src/components/ai_chatbot/components/textarea_with_suffix_icon";
import styles from "@src/components/ai_chatbot/styles.module.scss";

interface IChatInputProps {
  loader: boolean;
  handleMessageSend: (message: string) => void;
}

const MIN_TEXTAREA_HEIGHT = 42;
const MAX_TEXTAREA_HEIGHT = 150;

const ChatInput = ({ loader, handleMessageSend }: IChatInputProps) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  const sendMessage = useCallback(async () => {
    const message = inputValue.trim();
    if (message) {
      await handleMessageSend(message);
      setInputValue("");
    }
  }, [inputValue, handleMessageSend]);

  const handleSuffixClick = useCallback(() => {
    sendMessage();
  }, [sendMessage]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === "Enter" && !event.shiftKey) {
        event.preventDefault();
        sendMessage();
      }
    },
    [sendMessage]
  );

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
      const newHeight = Math.min(textarea.scrollHeight, MAX_TEXTAREA_HEIGHT);
      const currentHeight = textarea.offsetHeight;

      if (Math.abs(newHeight - currentHeight) > 5) {
        textarea.style.height = `${newHeight}px`;
      } else if (currentHeight < MIN_TEXTAREA_HEIGHT) {
        textarea.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
      }
    }
  };
  const debouncedAdjustHeight = useDebouncedCallback(
    adjustHeight,
    [inputValue],
    10
  );
  useEffect(() => {
    debouncedAdjustHeight();
  }, [debouncedAdjustHeight, inputValue]);

  return (
    <div className={ styles['chat-send-container'] }>
      <TextAreaWIthSuffixIcon
        ref={ textareaRef }
        hideClear
        autoComplete="off"
        className={ styles['text-area'] }
        disabled={ loader }
        placeholder="Message Docyt AI"
        suffixIcon={ (
          <AiChatSendButton
            isActive={ !!inputValue }
            onClick={ handleSuffixClick }
          />
        ) }
        value={ inputValue }
        wrapperClasses={ styles['text-area-wrapper'] }
        onChange={ handleInputChange }
        onKeyDown={ handleKeyDown }
      />
    </div>
  );
};

export default memo(ChatInput);
