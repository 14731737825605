import React from 'react';

import ChatBubbleIcon from '@src/components/utils/icomoon/chat-bubble';

import styles from './style.module.scss'; // Import the styles

interface ChatIconWithBadgeProps {
  unreadCount: number;
}

const ChatIconWithBadge: React.FC<ChatIconWithBadgeProps> = ({ unreadCount }) => {
  return (
    <div className={ `${styles['chat-icon-container']}` }>
      <ChatBubbleIcon
        className={ `${styles['bubble-chat-icon']} ${unreadCount > 0 ? styles['bubble-chat-icon--active'] : ''}` }
      />
      {unreadCount > 0 && (
        <span className={ `${styles['badge-count']} badge` }>{unreadCount}</span>
      )}
    </div>
  );
};

export default ChatIconWithBadge;