import React, { useEffect, useRef, useState } from "react";
import toastr from "@lib/toastr";

import { useChatMessageHandler } from "@src/components/ai_chatbot/hooks/use_chat_message_handler";
import useSendbirdHelper from "@src/components/ai_chatbot/hooks/use_sendbird_helper";
import styles from "@src/components/ai_chatbot/styles.module.scss";

const HANDLER_ID = "ASK_DOCYT_AI_CHANNEL_HANDLER";

export const MessageListenerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { context, sendbirdInstance } = useSendbirdHelper();
  const { onMessageReceived } = useChatMessageHandler();
  const isConnected = useRef(false);
  const [connectionState, setConnectionState] = useState<
    "idle" | "loading" | "error" | "success"
  >("idle");

  const setupConnection = async () => {
    setConnectionState("loading");
    try {
      const user = await sendbirdInstance.connect(
        String(context.config.userId),
        context.config.accessToken
      );

      if (user) {
        const channelHandler = new sendbirdInstance.ChannelHandler();
        channelHandler.onMessageReceived = onMessageReceived;
        sendbirdInstance.addChannelHandler(HANDLER_ID, channelHandler);
        isConnected.current = true;
        setConnectionState("success");
      }
    } catch (error) {
      console.error(`Error connecting to Sendbird:`, error);
      toastr.error("Failed to connect to chat service", "Chat");
      setConnectionState("error");
    }
  };

  useEffect(() => {
    if (!isConnected.current) {
      setupConnection();
    }

    return () => {
      if (isConnected.current) {
        sendbirdInstance.removeChannelHandler(HANDLER_ID);
        sendbirdInstance.disconnect();
        isConnected.current = false;
      }
    };
  }, []);

  if (connectionState === "loading") {
    return (
      <div className={styles["connection-container"]}>
        <div className={styles["chat-no-message"]}>
          <div className={styles["no-message-content"]}>
            <div className={styles["spinner"]} />
          </div>
        </div>
      </div>
    );
  }

  if (connectionState === "error") {
    return (
      <div className={styles["connection-container"]}>
        <div className={styles["chat-no-message"]}>
          <div className={styles["no-message-content"]}>
            <div>Error setting up connection</div>
            <button
              onClick={setupConnection}
              className={styles["retry-button"]}
            >
              Retry Connection
            </button>
          </div>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
