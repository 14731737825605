import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const DownloadIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="data-import-budget"
      pathsNumber={ 2 }
    />
  );
};

export default DownloadIcon;
