import React, { useCallback, useEffect } from 'react';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useActivateBookGPT } from '@src/hooks/queries/activate_bookgpt';
import { TID } from '@src/types/common';
import { openQBOConnectWindow } from '@src/utils/qbo_connect';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';
import { QBOIcon } from '@src/components/utils/icomoon';

import styles from './style.module.scss';

type IActivateQboProps = IUseModalProps& {
  currentBusinessId?: TID;
  displayName?: string;
};

const ActivateQboModal = ({
  isOpen,
  displayName,
  currentBusinessId,
  onCancel,
  onDone,
}: IActivateQboProps): JSX.Element => {
  const { mutateAsync } = useActivateBookGPT();

  const handleConnect = useCallback(() => {
    if (!currentBusinessId) return;
    openQBOConnectWindow(currentBusinessId);
  }, [currentBusinessId]);

  useEffect(() => {
    window.Docyt.vent.on('business:quickbooks:connected', (businessId: number) => {
      // vent is trigger for all the business, so we need to check if the businessId is the current businessId
      if (businessId === currentBusinessId) {
        mutateAsync({ businessId })
          .then(() => {
            onDone();
          })
          .catch((error: Error) => {
            toastr.error(`Failed to activate BookGPT: ${error.message}`, 'Error');
          });
      }
    });

    return () => {
      window.Docyt.vent.off('business:quickbooks:connected');
    };
  }, [currentBusinessId, onDone, mutateAsync]);

  return (
    <Modal
      dialogClassName={ styles['activate-qbo-modal'] }
      show={ isOpen }
      title=""
      onCancel={ onCancel }
    >
      <Modal.Body>
        <div className={ styles['activate-qbo-modal-body'] }>
          <div className={ styles['activate-qbo-modal-body-header'] }>
            Activate
            {' '}
            {displayName}
          </div>
          <div className={ styles['activate-qbo-modal-body-text'] }>
            {'Connect this business\'s QuickBooks account to Docyt to complete activation and begin auditing the general ledger.'}
          </div>
          <div className={ styles['activate-qbo-modal-connect-button'] }>
            <Button variant="success" onClick={ handleConnect }>
              <QBOIcon fontSize={ 18 } variant="white" />
              {' '}
              Connect QuickBooks
            </Button>
          </div>
          <div className={ styles['activate-qbo-modal-body-button'] }>
            <Button variant="link" onClick={ onCancel }>
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const MemoizedActivateQboModal = React.memo(ActivateQboModal);
const useActivateQboModal = makeUseModal(MemoizedActivateQboModal);
export {
  useActivateQboModal,
  MemoizedActivateQboModal,
};
