import React from 'react';

import classNames from 'classnames';

import styles from '../styles.module.scss';

interface IOnboardingPageProps {
  className?: string;
  title: string;
  isHideBorder?: boolean;
  children: React.ReactNode;
}

const OnboardingPage = ({ title, children, isHideBorder, className }: IOnboardingPageProps) => {
  const classesContainer = classNames(styles['onboarding-container'], className);
  const classesPanel = classNames(styles['onboarding-panel'], { 'border-none': isHideBorder });
  
  return (
    <div className={ classesContainer }>
      <div className={ styles['onboarding-heading'] }>
        <h1 className="font-24 font-bold text-center">{ title }</h1>
      </div>
      <div className={ classesPanel }>
        { children }
      </div>
    </div>
  );
};

export default OnboardingPage;
