import { useQuery, UseQueryOptions } from 'react-query';

import { getBusinessServicesInfo } from '@src/requests/business_services_info';
import { IBusinessServicesInfo } from '@src/types/business_services_info';

const useGetBusinessServicesInfo = (businessId: number, options?: UseQueryOptions<IBusinessServicesInfo, Error>) => {
  return useQuery<IBusinessServicesInfo, Error>(
    ['businessId', businessId],
    () => getBusinessServicesInfo(businessId),
    { ...options },
  );
};

export {
  useGetBusinessServicesInfo,
};
