import React, { useCallback } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useUniqueDomId } from '@src/hooks/dom';
import { useUpdateBusinessVendor } from '@src/hooks/queries/vendors';
import { IBusinessVendor } from '@src/types/business_vendors';

import SideView from '@src/components/ui_v2/side_view';
import MutationStatus from '@src/components/utils/mutation_status';

import Form from './link_ledger_edit_form';
import NoLedgerConnectedView from './no_ledger_connected_view';

interface IVendorLinkLedgerEditContainerProps {
  vendor: IBusinessVendor,
  editLinkLedger: boolean,
  setEditLinkLedger: React.Dispatch<React.SetStateAction<boolean>>,
  setVendorData: React.Dispatch<React.SetStateAction<IBusinessVendor | null>>,
}

const VendorLinkLedgerEditContainer = ({
  vendor,
  editLinkLedger,
  setEditLinkLedger,
  setVendorData,
}: IVendorLinkLedgerEditContainerProps) => {
  const business = useBusinessContext();
  const formId = useUniqueDomId('modal_form_');
  const updateVendor = useUpdateBusinessVendor();
  const { mutate } = updateVendor;

  const handleSubmit = useCallback((data: { qboName: string, qboId: string }) => {
    const params = {
      ...vendor,
      ...data,
      vendor: {
        ...vendor,
        ...data,
      },
    };

    mutate(params, {
      onSuccess: (res: IBusinessVendor) => {
        setVendorData(res);
      },
    });
  }, [mutate, vendor, setVendorData]);

  return (
    <>
      <SideView.Provider>
        <SideView.Standard
          isRoot
          isOpen={ !editLinkLedger }
          title="Edit Link to Ledger"
          onCancel={ () => setEditLinkLedger(true) }
        >
          {
            () => (
              business.qboConnected ? (
                <Form
                  formId={ formId }
                  vendor={ vendor }
                  onSubmit={ handleSubmit }
                />
              ) : <NoLedgerConnectedView vendor={ vendor } />
            )
          }
        </SideView.Standard>
        <SideView.Render />
      </SideView.Provider>
      <MutationStatus
        mutation={ updateVendor }
        successMessage="Vendor details have been successfully updated."
        successTitle="Success"
      />
    </>
  );
};

export default React.memo(VendorLinkLedgerEditContainer);
