import React, { useState } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { IReport } from '@src/types/report_service/report';
import { IReportConfiguration } from '@src/types/report_service/report_configurations';
import { IReportData } from '@src/types/report_service/report_data';
import { IReportItem } from '@src/types/report_service/report_item';
import { LDFeatureForBusinessEnabled, LDFeatureValueForBusiness } from '@src/utils/config';

import TableSection from '@src/components/ui_v2/table_section';
import Section from '@src/components/utils_v2/section';

import Banners from './banners';
import Table from './table';
import { useReportDatasCollection } from '../tableHooks';

import styles from '../styles.module.scss';

interface IListProps {
  report: IReport;
  items: IReportItem[];
  configurations: IReportConfiguration[];
}

const List = ({ report, items, configurations }: IListProps): JSX.Element => {
  const business = useBusinessContext();

  const collection = useReportDatasCollection({ report });

  const [reportEstimateEnabled, setReportEstimateEnabled] = useState<boolean | undefined>(
    LDFeatureValueForBusiness(
      window.Docyt.Common.Constants.REPORT_ESTIMATE_FLAG,
      String(business.id),
    ),
  );

  if (reportEstimateEnabled === undefined) {
    LDFeatureForBusinessEnabled(
      window.Docyt.Common.Constants.REPORT_ESTIMATE_FLAG,
      String(business.id),
    ).then((res) => {
      setReportEstimateEnabled(res);
    });
  }

  return (
    <Section.Provider section={ collection.section }>
      <Banners
        report={ report }
        reportDatas={ collection.records || [] as IReportData[] }
        reportEstimateEnabled={ reportEstimateEnabled }
      />
      <TableSection className={ styles['report-data-table-section'] }>
        <Table
          collection={ collection }
          configurations={ configurations }
          items={ items }
          report={ report }
          reportEstimateEnabled={ reportEstimateEnabled }
        />
      </TableSection>
    </Section.Provider>
  );
};

export default List;
