import React from 'react';

import { TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';

import { useActivateQboModal } from '../../activate_qbo_modal';

type IActivationButtonProps = {
  businessId?: TID;
  displayName?: string;
  setIsQboConnected: (isQboConnected: boolean) => void;
}

const ActivationButton = ({
  businessId,
  displayName,
  setIsQboConnected,
}: IActivationButtonProps): JSX.Element | null => {
  const {
    Component: ActivateQboModal,
    props: activateQboModalProps,
    open: openActivateQboModal,
  } = useActivateQboModal({
    onDone: () => {
      setIsQboConnected(true);
      window.Docyt.vent.off('business:quickbooks:connected');
    },
  });

  if (!window.Docyt.currentAdvisor.checkUserAccessToBookgpt()) {
    return null;
  }

  return (
    <>
      {businessId && displayName && (
        <ActivateQboModal
          currentBusinessId={ businessId }
          displayName={ displayName }
          { ...activateQboModalProps }
        />
      )}
      <Button size="compact" variant="outline" onClick={ openActivateQboModal }>Activate</Button>
    </>
  );
};

export default React.memo(ActivationButton);
