import React, { useCallback, useState } from 'react';

import { useGetBusinessServicesInfo } from '@src/hooks/queries/business_services_info';
import { businessSettingsPath, clientBusinessSettingsPath } from '@src/routes';
import { TID } from '@src/types/common';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import { Button } from '@src/components/ui_v2/buttons';

import ProgressActionLoader from './progress_action_loader';

const CompleteSetupButton = ({ businessId }: { businessId: TID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { refetch: fetchBusinessServicesInfo } = useGetBusinessServicesInfo(businessId, {
    enabled: false,
  });

  const handleClick = useCallback(() => {
    setIsLoading(true);
    if (window.Docyt.currentAdvisor.isBookgptBusinessUser()) {
      backboneNavigateToUrl(businessSettingsPath(businessId));
    } else {
      fetchBusinessServicesInfo().then((res) => {
        if (res.data && res.data.accountingFirmBusinessId) {
          backboneNavigateToUrl(clientBusinessSettingsPath(businessId, res.data.accountingFirmBusinessId));
        }
      });
    }
    return () => {
      setIsLoading(false);
    };
  }, [fetchBusinessServicesInfo, businessId]);

  if (isLoading) {
    return <ProgressActionLoader status="in_ledger_setup" />;
  }

  return (
    <Button size="compact" variant="outline" onClick={ handleClick }>Complete Setup</Button>
  );
};

export default CompleteSetupButton;
