import React from 'react';

import Skeleton from 'react-loading-skeleton';

import styles from '@src/components/ai_chatbot/styles.module.scss';

const ChatItemLoading = () => {
  return (
    <div className={ styles['loading-container'] }>
      <Skeleton
        borderRadius={ 16 }
        className={ styles['custom-skeleton'] }
        height={ 20 }
        width="80%"
      />
      <Skeleton
        borderRadius={ 16 }
        className={ styles['custom-skeleton'] }
        height={ 20 }
        width="50%"
      />
    </div>
  );
};

export default React.memo(ChatItemLoading);
