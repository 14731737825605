import React, { useCallback, useState, useEffect } from 'react';

import { UseInfiniteQueryResult } from 'react-query';

import { useCreateBusinessMetricBatchValues } from '@src/hooks/queries/business_metric_value';
import { IBusinessMetricValue, TBusinessMetricValueSortColumn } from '@src/types/business_metric_value';
import { TID, TDate } from '@src/types/common';
import { IMetric } from '@src/types/metrics';
import { ISorting } from '@src/types/sorting';
import { formatApiDate, apiMonthToDate, endOfMonthApiDate, getUTCTimezone, endOfMonthDate } from '@src/utils/date_helpers';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { Button, CancelModalButton } from '@src/components/ui/buttons';
import MutationStatus from '@src/components/utils/mutation_status';

import ItemActions from './list_item/actions';
import List from './list_item/list';

import styles from '../styles.module.scss';

interface IBusinessMetricValuesBodyProps {
  businessMetricValues: IBusinessMetricValue[],
  hasValues: boolean,
  selectedMonthStr: string,
  businessMetric: IMetric,
  query: UseInfiniteQueryResult<any, Error>,
  sorting: ISorting<TBusinessMetricValueSortColumn>,
  businessId: TID,
}

const BusinessMetricValuesBody = ({
  businessMetricValues,
  hasValues,
  selectedMonthStr,
  businessMetric,
  query,
  sorting,
  businessId,
}: IBusinessMetricValuesBodyProps): JSX.Element => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isAddMode, setIsAddMode] = useState<boolean>(false);
  const [values, setValues] = useState<IBusinessMetricValue[]>(businessMetricValues);
  const [editingValues, setEditingValues] = useState<IBusinessMetricValue[]>(businessMetricValues);

  useEffect(() => {
    if (!isAddMode) {
      const setMissingValues = (): IBusinessMetricValue[] => {
        if (selectedMonthStr === undefined || businessMetricValues.length === 0) {
          return businessMetricValues;
        }

        const firstDay = apiMonthToDate(selectedMonthStr);
        if (firstDay && businessMetric.startDate
          && new Date(firstDay) > new Date(businessMetric.startDate)) {
          const endDate = endOfMonthDate(getUTCTimezone(new Date(selectedMonthStr)));
          const metricValues: IBusinessMetricValue[] = [];
          const tempDate = getUTCTimezone(new Date(firstDay));
          for (let i = 1; i <= endDate.getDate(); i += 1) {
            const tempDateStr = formatApiDate(tempDate);
            const metricValue = businessMetricValues.find(
              (value) => formatApiDate(getUTCTimezone(new Date(value.date))) === tempDateStr,
            );
            if (metricValue !== undefined) {
              metricValues.push(metricValue);
            } else {
              metricValues.push({
                date: tempDateStr,
              });
            }
            tempDate.setDate(tempDate.getDate() + 1);
          }
          return metricValues;
        }
        return businessMetricValues;
      };

      const metricValues = setMissingValues();
      setValues(metricValues);
      setEditingValues(metricValues);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessMetricValues, isAddMode]);

  useEffect(() => {
    setIsAddMode(false);
    setIsEditMode(false);
  }, [selectedMonthStr]);

  const handleValueChanged = useCallback((date: TDate, value?: number) => {
    if (value === undefined) return;

    setEditingValues((originValues) => {
      const newValues = originValues.map((originValue) => {
        if (originValue.date !== date) return originValue;

        return {
          ...originValue,
          value,
        };
      });

      return newValues;
    });
  }, []);

  const createBusinessMetricBatchValues = useCreateBusinessMetricBatchValues();
  const { mutate } = createBusinessMetricBatchValues;

  const handleEditData = useCallback(() => {
    setIsEditMode(true);
    setIsAddMode(false);
  }, []);

  const handleCancelEdit = useCallback(() => {
    setIsEditMode(false);
    setIsAddMode(false);
    setEditingValues(values);
  }, [values]);

  const handleSaveData = useCallback(() => {
    setIsEditMode(false);
    setValues(editingValues);
    mutate(
      {
        businessMetricId: businessMetric.id,
        values:           editingValues,
      },
    );
  }, [editingValues, mutate, businessMetric]);

  const setAddingValues = useCallback((
    day: string | undefined,
    currentMonth: string | undefined,
  ) => {
    if (!day) return;

    const endDate = new Date(currentMonth ? endOfMonthApiDate(getUTCTimezone(new Date(currentMonth))) : '');
    const nextDate = getUTCTimezone(new Date(day));

    const days = [];
    for (let i = nextDate; i <= getUTCTimezone(endDate); nextDate.setDate(nextDate.getDate() + 1)) {
      days.push(formatApiDate(nextDate));
    }

    const newValues = days.map((d, index) => ({
      id:    index,
      date:  formatApiDate(getUTCTimezone(new Date(d))),
      value: null,
    }));

    setEditingValues((originValues) => [...originValues, ...newValues]);
  }, []);

  const handleAddValues = useCallback(() => {
    sorting.clear();
    setIsEditMode(true);
    setIsAddMode(true);

    const firstDay = apiMonthToDate(selectedMonthStr);
    setAddingValues(
      firstDay && businessMetric.startDate
      && new Date(firstDay) > new Date(businessMetric.startDate)
        ? firstDay : businessMetric.startDate,
      selectedMonthStr,
    );
  }, [sorting, selectedMonthStr, setAddingValues, businessMetric]);

  useEffect(() => {
    switch (sorting.data.orderColumn) {
      case 'value':
        if (sorting.data.orderDirection === 'asc') {
          setValues((originValues) => originValues.sort((a: any, b: any) => a.value - b.value));
          setEditingValues((originValues) => originValues.sort(
            (a: any, b: any) => a.value - b.value,
          ));
        } else {
          setValues((originValues) => originValues.sort((a: any, b: any) => b.value - a.value));
          setEditingValues((originValues) => originValues.sort(
            (a: any, b: any) => b.value - a.value,
          ));
        }
        break;
      case 'date':
        if (sorting.data.orderDirection === 'asc') {
          setValues((originValues) => originValues.sort(
            (a, b) => ((a.date > b.date) ? 1 : -1),
          ));
          setEditingValues((originValues) => originValues.sort(
            (a, b) => ((a.date > b.date) ? 1 : -1),
          ));
        } else {
          setValues((originValues) => originValues.sort(
            (a, b) => ((a.date < b.date) ? 1 : -1),
          ));
          setEditingValues((originValues) => originValues.sort(
            (a, b) => ((a.date < b.date) ? 1 : -1),
          ));
        }
        break;
      default:
        break;
    }
  }, [sorting.data, businessMetricValues]);

  return (
    <>
      <MutationStatus
        mutation={ createBusinessMetricBatchValues }
        successMessage={ isAddMode ? 'Added successfully.' : 'Updated successfully.' }
      />
      { isEditMode && (
        <div className={ styles['edit-data-action-section'] }>
          <CancelModalButton
            className="m-r-20"
            title="Cancel"
            onClick={ handleCancelEdit }
          />
          <Button
            bsColor="blue"
            type="submit"
            onClick={ handleSaveData }
          >
            Save
          </Button>
        </div>
      )}
      <div className={ styles['metric-list-table-wrapper'] }>
        { (!isEditMode && hasValues && businessMetric.dimensions.length === 0) && (
          <div className="actions" style={ { float: 'right' } }>
            <ActionsDropdown>
              <ItemActions
                onEditData={ handleEditData }
              />
            </ActionsDropdown>
          </div>
        )}
        <List
          businessId={ businessId }
          businessMetric={ businessMetric }
          businessMetricValues={ isEditMode ? editingValues : values }
          hasValues={ hasValues || isAddMode }
          isEditMode={ isEditMode }
          query={ query }
          sorting={ sorting }
          onAddValues={ handleAddValues }
          onValueChange={ handleValueChanged }
        />
      </div>
    </>
  );
};

export default BusinessMetricValuesBody;
