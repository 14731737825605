import React, { memo, useRef, useCallback } from 'react';

import { flatten } from 'lodash';
import { InfiniteData } from 'react-query';
import { useRecoilState } from 'recoil';

import toastr from '@lib/toastr';
import {
  IGlobalThreadListResponse,
  useGetListOfThreads,
} from '@src/hooks/queries/ai_chat/ai_chatbot';
import { useInfiniteScroll } from '@src/hooks/scroll';

import { historyItemsAtom, isNavBarVisibleAtom } from '@src/components/ai_chatbot/atoms';
import ChatListSection from '@src/components/ai_chatbot/components/chat_list_section';
import FAQSection from '@src/components/ai_chatbot/components/faq_section';
import StartNewThread from '@src/components/ai_chatbot/components/start_new_thread';
import { PlusIcon } from '@src/components/utils/fa_icons';
import { MinimizeIcon } from '@src/components/utils/icomoon';

import styles from '@src/components/ai_chatbot/styles.module.scss';

const NavBar = () => {
  const [isNavBarVisible, setIsNavBarVisible] = useRecoilState(isNavBarVisibleAtom);
  const [historyItems, setHistoryItems] = useRecoilState(historyItemsAtom);

  const handleThreadListSuccess = useCallback(
    (data: InfiniteData<IGlobalThreadListResponse>) => {
      setHistoryItems((prevHistoryItems) => ({
        ...prevHistoryItems,
        data: flatten(data.pages.map((page) => page.collection)),
      }));
    },
    [setHistoryItems],
  );

  const handleThreadListError = useCallback(
    (error: Error) => {
      toastr.error('Error fetching thread list:', error.message);
    },
    [], // No dependencies needed as toastr is stable
  );

  const query = useGetListOfThreads(
    { pageSize: 20 },
    {
      onSuccess: handleThreadListSuccess,
      onError:   handleThreadListError,
    },
  );

  const infiniteScrollRef = useRef<HTMLDivElement>(null);
  useInfiniteScroll({
    elementRef: infiniteScrollRef,
    query,
  });

  const handleMinimize = () => {
    setIsNavBarVisible(true);
  };

  return (
    <div className={ styles['chat-list-section'] }>
      <div className={ `${styles['chat-btn-thread-content']}` }>
        <MinimizeIcon
          fontSize={ 24 }
          onClick={ handleMinimize }
        />
        <StartNewThread
          buttonText="New Chat"
          prefixIcon={ <PlusIcon fontSize={ 12 } /> }
        />
      </div>

      {!isNavBarVisible && (
        <div className={ styles['chat-list-scroll'] }>
          <FAQSection />
          <ChatListSection
            header="Recent"
            infiniteScrollRef={ infiniteScrollRef }
            isLoading={ query.isFetching }
            list={ historyItems.data }
          />
        </div>
      )}
    </div>
  );
};

export default memo(NavBar);
