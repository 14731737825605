import React from 'react';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';

import AddClientPopup from '../components/add_client_popup';

interface IAddClientModalProps extends IUseModalProps {}

const AddClientModal = ({
  isOpen,
  onCancel,
  onDone,
}: IAddClientModalProps): JSX.Element => {
  return (
    <AddClientPopup
      isOpen={ isOpen }
      onCancel={ onCancel }
      onDone={ onDone }
    />
  );
};

const useAddClientModal = makeUseModal(AddClientModal);

export default useAddClientModal;
