module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var device, i, len, ref;
    
      __out.push('<div class="item-divider m-t-35 p-b-15">\n    <h2 class="service-main-title">Security & Privacy</h2>\n</div>\n<div class="setting-security-content m-t-40 m-b-20">\n    <div class="mfa-checkbox m-b-20">\n        <input type="checkbox" class="pointer" id=\'mfa-checkbox-js\' ');
    
      __out.push(__sanitize(this.checkedMFA ? 'checked' : ''));
    
      __out.push('>\n        <label class="font-16 font-bold in-dark-200" for=\'mfa-checkbox-js\'>Multi Factor Authentication (MFA). With this option enabled, every time you log on a new device, you will be asked to confirm it by providing one-time authorization code that will be sent to your e-mail address.</label>\n    </div>\n    <div class="m-b-20">\n        <label class="font-18 font-bold">Devices</label>\n        <a class="font-14 font-bold pointer pull-right edit-devices-js">Edit</a>\n    </div>\n    <div class="devices-container">\n        ');
    
      ref = this.devices;
      for (i = 0, len = ref.length; i < len; i++) {
        device = ref[i];
        __out.push('\n        ');
        if (device.confirmed_at) {
          __out.push('\n        <div class="row m-b-20">\n            <label class="col-md-5 font-14">');
          __out.push(__sanitize(device.name));
          __out.push('</label>\n            <span class="col-md-7 font-14">Added on ');
          __out.push(__sanitize(device.confirmed_at_string));
          __out.push('</span>\n        </div>\n        ');
        }
        __out.push('\n        ');
      }
    
      __out.push('\n    </div>\n    <p class="font-16 in-dark-200 p-b-10">For additional protection of your data, your account can only be opened in the devices shown above.</p>\n    <p class="font-16 in-dark-200 p-b-15">Note: If you delete and reinstall the Docyt app, you may see multiple instances of the same device. You can remove the duplicates through the \'Edit\' action.</p>\n    <div class="item-divider m-b-15"></div>\n    <div class="item-divider p-b-15">\n        <a class="font-14 font-bold pointer edit-password-js">Update Password</a>\n    </div>\n    <div class="item-divider p-t-15 p-b-15">\n        <a class="font-14 font-bold pointer edit-phone-number-js">Change Primary Phone Number</a>\n    </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}