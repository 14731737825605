import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  useAddBillingMethod,
  useSetBusinessInfo,
  useGetBillingPaymentMethodStatus,
} from '@src/hooks/queries/signup';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import { Button } from '@src/components/ui_v2/buttons';
import { StripeIcon } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

const BillingPageForm = ({
  page,
  setPage,
}: {
  page: string;
  setPage: React.Dispatch<React.SetStateAction<'billing' | 'billing_success'>>;
}) => {
  const businessId = window.Docyt.currentAdvisor.get('default_accounting_firm')?.id;
  const [isCall, setIsCall] = useState<boolean>(false);
  const returnUrl = window.location.href;

  const queryBillingMethodStatus = useGetBillingPaymentMethodStatus({ businessId });
  
  const {
    data,
    isLoading,
    isError,
    error: addBillingMethodError,
  } = useAddBillingMethod({ businessId, returnUrl, isCall });

  const {
    mutate: setBusinessInfo,
    isLoading: isSetBusinessInfoLoading,
    isError: isSetBusinessInfoError,
    error: setBusinessInfoError,
  } = useSetBusinessInfo();

  const error = useMemo(() => {
    if (queryBillingMethodStatus.error) {
      return queryBillingMethodStatus.error.message;
    }

    if (addBillingMethodError) {
      return addBillingMethodError.message;
    }

    return setBusinessInfoError?.message;
  }, [addBillingMethodError, setBusinessInfoError, queryBillingMethodStatus]);

  useEffect(() => {
    if (queryBillingMethodStatus.data?.hasPaymentMethod) {
      setPage('billing_success');
    } else {
      setPage('billing');
    }
  }, [queryBillingMethodStatus, setPage]);

  useEffect(() => {
    if (data?.url) {
      setIsCall(false);
      window.open(data.url, '_blank');
    }
  }, [data?.url]);

  const onAddBillingMethod = useCallback(() => {
    setIsCall(true);
  }, [setIsCall]);

  const onContinueSetup = useCallback(() => {
    if (businessId) {
      setBusinessInfo(
        {
          businessId,
          setupConfig: {
            ...window.Docyt.currentAdvisor.get('default_accounting_firm')?.setup_config,
            billing_method: true,
          },
        },
        {
          onSuccess: () => {
            Backbone.history.navigate('setup_accounting_firm?step=team_members', {
              trigger: true,
            });
          },
        }
      );
    }
  }, [setBusinessInfo, businessId]);



  const renderPage = () => {
    if (page === 'billing') {
      return (

        <>
          <div className="p-l-24 p-r-24 font-16 text-center">
            <b>Start with a free trial!</b>
            <br />
            After the trial, your account will be automatically
            <br />
            charged. You can update or cancel your plan
            <br />
            anytime.
          </div>
          <div className="p-l-24 p-r-24">
            <Button
              className={ styles['form-button'] }
              variant="outline"
              onClick={ onAddBillingMethod }
            >
              <StripeIcon fontSize={ 18 } />
              {' '}
              Add Billing Method
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="p-l-24 p-r-24 font-16 text-center">
          Your payment method has been added.
          <br />
          You&apos;re all set!
        </div>
        <div className="p-l-24 p-r-24">
          <Button
            className={ styles['form-button'] }
            variant="primary"
            onClick={ onContinueSetup }
          >
            Continue Setup
          </Button>
        </div>
      </>
    )
  };

  return (
    <>
      { (isLoading || isSetBusinessInfoLoading || queryBillingMethodStatus.isLoading) && <Spinner /> }
      { (isError || isSetBusinessInfoError || queryBillingMethodStatus.isError) && <ErrorNotification message={ error } /> }

      <div className={ styles['onboarding-form'] }>
        <div className={ styles['onboarding-body'] }>
          {renderPage()}
        </div>
      </div>
    </>
  );
};

export default React.memo(BillingPageForm);
