import React from 'react';
import { Location } from 'react-router-dom';
import Root from '@src/components/root';
import AiChatBotRouter from './ai_chat_bot_router';
import SendbirdWrapper from './components/sendbird_wrapper';
import { MessageListenerProvider } from './provider/message_listener_provider';

interface IAiChatBotPageProps {
  location?: Location;
}

const AiChatBotPage = (): JSX.Element => {
  return (
    <Root>
      <SendbirdWrapper>
        <MessageListenerProvider>
          <AiChatBotRouter />
        </MessageListenerProvider>
      </SendbirdWrapper>
    </Root>
  );
};

export default AiChatBotPage;