import React, { useCallback } from 'react';

import { useResetIntegrationsRun } from '@src/hooks/queries/integrations/runs';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { formatDate } from '@src/utils/date_helpers';

import { useConfirmDeleteModal } from '@src/components/common/confirm_delete';
import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import { ReconciliationOutlineIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import { useResetValidationErrorModal } from './reset_validation_error';

interface IResetRevenueReportActionProps {
  report: IRevenueServiceDocument,
  revenueSystem: IRevenueSystem,
}

const ResetRevenueReportAction = ({
  report,
  revenueSystem,
}: IResetRevenueReportActionProps) => {
  const integrationId = revenueSystem.integrationId;
  const reset = useResetIntegrationsRun();
  const { mutate } = reset;
  const resetNotAllowed = (revenueSystem.depositTrackingStartDate != null
    && report.startDate < revenueSystem.depositTrackingStartDate);
  let message = '';
  if (resetNotAllowed) {
    message = `Deposit tracking start date is ${revenueSystem.depositTrackingStartDate}. 
   You cannot reset revenue before this date.`;
  }

  const handleConfirm = useCallback(() => {
    mutate({
      integrationId: integrationId!,
      startDate:     formatDate(new Date(report.startDate!)),
      endDate:       formatDate(new Date(report.endDate!)),
    }, {
      onSuccess: () => {
        window.Docyt.vent.trigger('revenue:reset:success');
      },
    });
  }, [mutate, integrationId, report]);

  const resetValidationErrorModal = useResetValidationErrorModal();

  const confirmModal = useConfirmDeleteModal({
    onDone: handleConfirm,
  });

  if (!integrationId || !report.startDate || !report.endDate
    || ['pending', 'downloading'].includes(report.status || '')) {
    return null;
  }

  return (
    <>
      <MutationStatus mutation={ reset } />
      <confirmModal.Component
        confirmTitle="Yes, Proceed"
        text={ (
          <span>
            This action will reset your revenue, Non-financial statistics as well as
            { ' ' }
            Accounts receivables data for this date. Do you want to proceed?
          </span>
        ) }
        title="Reset Report"
        { ...confirmModal.props }
      />
      <ActionsDropdown.Action
        icon={ <ReconciliationOutlineIcon fontSize={ 20 } /> }
        title="Reset"
        onSelect={ resetNotAllowed ? resetValidationErrorModal.open : confirmModal.open }
      />
      <resetValidationErrorModal.Component
        message={ message }
        title="Warning"
        { ...resetValidationErrorModal.props }
      />
    </>
  );
};

export default ResetRevenueReportAction;
