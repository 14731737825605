import React from 'react';

import { UseFormReturn } from 'react-hook-form';

import { useStandardCategories } from '@src/hooks/useStandardCategories';

import Form from '@src/components/ui/form';

import { IAddClientFormValues } from './schema';

interface IAddBusinessFormProps {
  formId?: string;
  form: UseFormReturn<IAddClientFormValues>;
  onSubmit: (value: IAddClientFormValues) => void;
}

const AddClientForm: React.FC<IAddBusinessFormProps> = ({
  formId = 'addClientForm',
  onSubmit,
  form,
}) => {
  const { categories, isLoading } = useStandardCategories();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const categoryOptions = [
    { value: '', name: 'Select Industry', id: '' },
    ...categories.map((cat) => ({
      value: String(cat.id),
      name:  cat.name,
      id:    cat.id,
    })),
  ];

  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>

      <Form.SelectField
        error={ errors.standardCategory?.message }
        label="Select Industry:"
        options={ categoryOptions }
        placeholder={ isLoading ? 'Loading categories...' : 'Select Category' }
        { ...register('standardCategory') }
      />

      <Form.TextField
        error={ errors.legalName?.message }
        label="Legal Name:"
        placeholder="Enter Legal Name"
        { ...register('legalName') }
      />

      <Form.TextField
        error={ errors.displayName?.message }
        label="Display Name:"
        placeholder="Enter Display Name"
        { ...register('displayName') }
      />
    </Form>
  );
};

export default AddClientForm;
