import { useEffect, useMemo } from 'react';

import moment from 'moment';
import { UseQueryResult } from 'react-query';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Section } from '@src/constants/sections';
import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  useGetDepartmentDatas,
  useGetReportDatas,
  useGetReportIdentifierItemAccountValues,
  useGetReportIdentifierItemValues,
  useGetTotalReportDatas,
} from '@src/hooks/queries/report_service/report_datas';
import useUserFeatureFlag from '@src/hooks/use_user_feature_flag';
import { IGetReportDatasParams } from '@src/requests/report_service/report_datas';
import { IBusiness } from '@src/types/businesses';
import { TMongoID, TSection } from '@src/types/common';
import { TDateFilter } from '@src/types/filter';
import { IReport, TReportDataPeriodType } from '@src/types/report_service/report';
import { IReportColumn } from '@src/types/report_service/report_column';
import { IBudgetValue, IItemValue, IReportData } from '@src/types/report_service/report_data';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';
import { getReportBTFEndPoint, isReportBTFEnabledKey } from '@src/utils/config';
import {
  API_DATE_FORMAT,
  endOfMonthApiDate,
  endOfWeekApiDate,
  formatApiDate,
  formatDate,
  parseApiDate,
  startOfMonthApiDate,
  startOfWeekApiDate
} from '@src/utils/date_helpers';

import { budgetsSelectAddValueType, forecastSelectAddValueType, multiSelectAddValueType } from '@src/components/ui_v2/filter/atoms';
import { TUseFilterFieldReturn, useFilterField } from '@src/components/ui_v2/filter/hooks';

import { IReportDatasCollection, isBudgetColumn, isForecastColumn, IHeaderColumn, isTotalData, isBasicReport } from './hooks';
import { pickMultiDaily, pickMultiMonths, reportDataPeriodType, reportFilterDateRange, reportsAccountingMethod } from '../atoms';

interface IUseReportDatasCollectionParams {
  report: IReport;
}

const columnCellValues = (values: number[], ptdColumns: IReportColumn[], showMultiMonths: boolean, showMultiDaily: boolean) => {
  let existValue = 0;

  if (showMultiMonths || showMultiDaily) {
    for (let index = 0; index < values.length; index += 1) {
      if (values[index] !== 0) {
        existValue = values[index];
        break;
      }
    }
  } else {
    for (let index = 0; index < values.length; index += 1) {
      if (index < ptdColumns?.length) {
        if (values[index] !== 0) {
          existValue = values[index];
          break;
        }
      }
    }
  }

  return existValue;
};

const initialRowValuesBySingle = (
  item: IReportItem,
  data: IReportData,
  budgetsComparison: string,
  forecastComparison: string,
  column: IReportColumn,
) => {
  const itemVal = data.itemValues.find((iv: IItemValue) => iv.itemId === item.id && iv.columnId === column.id);

  if (!itemVal) {
    if (column && (isBudgetColumn(column) || isForecastColumn(column))) {
      let isBudgetValue = false;

      if (column.name.search('PTD') !== -1 || column.name.search('Budget') !== -1 || column.name.search('Forecast') !== -1) {
        for (let index = 0; index < item.childItems.length; index += 1) {
          let budgValue: IBudgetValue | undefined;
          const it = item.childItems[index];
          const itVal = data.itemValues.find((iv: IItemValue) => iv.itemId === it.id && iv.columnId === column.id);

          if (column.name.search('Budget') !== -1) {
            budgValue = itVal?.businessBudgetValues?.find((v: IBudgetValue) => v.reportComparerId === budgetsComparison);
          }

          if (column.name.search('Forecast') !== -1) {
            budgValue = itVal?.businessForecastValues?.find((v: IBudgetValue) => v.reportComparerId === forecastComparison);
          }

          if (budgValue && Number(budgValue.value) !== 0) {
            isBudgetValue = true;
            break;
          }
        }
      }

      return isBudgetValue ? 1 : 0;
    }

    const lastItem = item.childItems[item.childItems.length - 1];

    if (item.childItems.length > 0) {
      let isParent = false;

      for (let i = 0; i < item.childItems.length; i += 1) {
        const it = item.childItems[i];
        const itVal = data.itemValues.find((iv: IItemValue) => iv.itemId === it.id && iv.columnId === column.id);
        if (itVal && Number(itVal?.value) !== 0) {
          isParent = true;
          break;
        }
      }

      return isParent ? 1 : 0;
    }

    if (lastItem?.identifier?.search('total') !== -1 || lastItem?.childItems?.length === 0) {
      const lastVal = data.itemValues.find((iv: IItemValue) => iv.itemId === lastItem?.id && iv.columnId === column.id);
      return !lastVal?.value ? 0 : Math.round(Number(lastVal?.value || 0));
    }
  }

  if (column && (isBudgetColumn(column as IReportColumn) || isForecastColumn(column as IReportColumn))) {
    let budgetValue: IBudgetValue | undefined;

    if (column?.name?.search('Budget') !== -1) {
      budgetValue = itemVal?.businessBudgetValues?.find((v: IBudgetValue) => v.reportComparerId === budgetsComparison);
    }

    if (column?.name?.search('Forecast') !== -1) {
      budgetValue = itemVal?.businessForecastValues?.find((v: IBudgetValue) => v.reportComparerId === forecastComparison);
    }

    return Math.round(Number(budgetValue?.value || 0));
  }

  return Math.round(Number(itemVal?.value));
};

const initialRowValuesByMulti = (item: IReportItem, data: IReportData, columns: IReportColumn[], ptdColumns: IReportColumn[]) => {
  let itemVal: IItemValue | undefined;
  const ptdColumn = ptdColumns.find((c: IReportColumn) => c.type === 'actual' && c.year === 'current');

  if (columns?.length === 0) {
    itemVal = data?.itemValues?.find((iv: IItemValue) => iv?.itemId === item?.id && iv?.columnId === ptdColumn?.id);
  } else {
    itemVal = data?.itemValues?.find((iv: IItemValue) => columns?.find((c: IReportColumn) => iv?.itemId === item?.id && iv?.columnId === c?.id));
  }

  if (!itemVal) {
    const lastItem = item.childItems[item.childItems.length - 1];

    if (lastItem?.identifier.search('total') !== -1 || lastItem?.childItems?.length === 0) {
      if (columns?.length === 0) {
        const lastVal = data?.itemValues?.find((iv: IItemValue) => iv?.itemId === lastItem?.id && iv?.columnId === ptdColumn?.id);
        return !Math.round(Number(lastVal?.value)) ? 0 : Math.round(Number(lastVal?.value || 0));
      }

      const lastVal = data?.itemValues?.find((iv: IItemValue) => columns?.find((c: IReportColumn) => iv?.itemId === lastItem?.id && iv?.columnId === c?.id));

      return !Math.round(Number(lastVal?.value)) ? 0 : Math.round(Number(lastVal?.value || 0));
    }
  }

  return Math.round(Number(itemVal?.value));
};

const getHideZeroRows = (
  hideZeroRows: boolean,
  item: IReportItem,
  report: IReport,
  reportData: IReportData[] | undefined,
  multiMonthColumns: IReportColumn[],
  ptdColumns: IReportColumn[],
  ytdColumns: IReportColumn[],
  showMultiMonths: boolean,
  showMultiDaily: boolean,
  budgetsComparison: string,
  forecastComparison: string,
) => {
  const columns = showMultiMonths ? multiMonthColumns : [...ptdColumns, ...ytdColumns];
  let data: IReportData[] = [];
  let totalValues: number[] = [];

  if (report?.showOnlyTotalColumn && reportData?.[0]) {
    data = [reportData?.[0]];
  } else {
    data = reportData || [];
  }

  if (!showMultiMonths && !showMultiDaily) {
    if (data?.length === 1) {
      totalValues = columns.map((column: IReportColumn) => initialRowValuesBySingle(item, data?.[0], budgetsComparison, forecastComparison, column));
    }

    if (data?.length > 1) {
      totalValues = data?.map((rep: IReportData) => initialRowValuesByMulti(item, rep, columns, ptdColumns));
    }
  }

  if (showMultiMonths || showMultiDaily) {
    if (columns.length > 0) {
      for (let i = 0; i < data.length; i += 1) {
        const rep = data[i];

        const vals = columns.map((column: IReportColumn) => initialRowValuesBySingle(item, rep, budgetsComparison, forecastComparison, column));

        for (let j = 0; j < vals.length; j += 1) {
          const v = vals[j];
          totalValues.push(v);
        }
      }
    } else {
      totalValues = data?.map((rep: IReportData) => {
        return initialRowValuesByMulti(item, rep, columns, ptdColumns);
      });
    }
  }

  if (hideZeroRows) {
    const reportColumns = columns?.length > 0 ? columns : ptdColumns;
    item.show = columnCellValues(totalValues, reportColumns, showMultiMonths, showMultiDaily) !== 0;
  } else {
    item.show = true;
  }

  for (let index = 0; index < item?.childItems?.length; index += 1) {
    const subItem = item?.childItems?.[index];
    getHideZeroRows(
      hideZeroRows,
      subItem,
      report,
      reportData,
      multiMonthColumns,
      ptdColumns,
      ytdColumns,
      showMultiMonths,
      showMultiDaily,
      budgetsComparison,
      forecastComparison,
    );
  }

  return item;
};

const checkNonZeroChildRow = (items: IReportItem[]) => {
  return items.forEach((item) => {
    if (item.childItems.length > 0) {
      item.show = item.childItems.filter((v) => v.show).length > 0;
      checkNonZeroChildRow(item.childItems);
    }
  });
};

export const useHideZeroRows = (
  hideZeroRows: boolean,
  items: IReportItem[],
  report: IReport,
  reportData: IReportData[] | undefined,
  multiMonthColumns: IReportColumn[],
  ptdColumns: IReportColumn[],
  ytdColumns: IReportColumn[],
  budgetsComparison: string,
  forecastComparison: string,
) => {
  const showMultiMonths = useRecoilValue(pickMultiMonths);
  const showMultiDaily = useRecoilValue(pickMultiDaily);

  for (let index = 0; index < items.length; index += 1) {
    const reportItem = items[index];

    getHideZeroRows(
      hideZeroRows,
      reportItem,
      report,
      reportData,
      multiMonthColumns,
      ptdColumns,
      ytdColumns,
      showMultiMonths,
      showMultiDaily,
      budgetsComparison,
      forecastComparison,
    );
  }

  checkNonZeroChildRow(items);
  return items;
};

const getItemsBySearchName = (item: IReportItem, searchValue: string) => {
  item.search = item.name.toLowerCase().includes(searchValue.toLowerCase().trim());

  for (let index = 0; index < item.childItems.length; index += 1) {
    const subItem = item.childItems[index];

    if (item.search) {
      subItem.search = true;
    } else {
      subItem.search = subItem.name.toLowerCase().includes(searchValue.toLowerCase().trim());

      if (subItem.search) {
        item.search = true;
      } else {
        item.search = false;
      }
    }

    getItemsBySearchName(subItem, searchValue);
  }
};

export const useReportItemsBySearchName = (items: IReportItem[], searchValue: string) => {
  for (let index = 0; index < items?.length; index += 1) {
    const reportItem = items?.[index];
    getItemsBySearchName(reportItem, searchValue);
  }
};

export const useReportOtherDetailItemsBySearchName = (items: IItemAccount[], searchValue: string) => {
  for (let index = 0; index < items.length - 1; index += 1) {
    const item = items?.[index];
    item.search = item?.name?.toLowerCase()?.includes(searchValue.toLowerCase().trim());
  }
};

export const useReportOtherDetailHideZeroRows = (
  report: IReport,
  hideZeroRows: boolean,
  items: IItemAccount[],
  multiMonthColumns: IReportColumn[],
  ptdColumns: IReportColumn[],
  ytdColumns: IReportColumn[],
  itemAccountValues: IItemAccount[],
  headerTopRowColumns: IHeaderColumn[],
) => {
  const showMultiMonths = useRecoilValue(pickMultiMonths);
  const columns = showMultiMonths ? multiMonthColumns : [...ptdColumns, ...ytdColumns];
  const ptdColumn = ptdColumns.find((c: IReportColumn) => c.type === 'actual' && c.year === 'current');

  for (let index = 0; index < items.length - 1; index += 1) {
    let totalValue: number = 0;
    const item: IItemAccount = items[index];

    if (!showMultiMonths) {
      for (let j = 0; j < columns.length; j += 1) {
        const col = columns[j];
        const val = itemAccountValues.find(
          (it: IItemAccount) => it.columnId === col.id
          && it.chartOfAccountId === item.chartOfAccountId
          && it.accountingClassId === item.accountingClassId,
        );

        if (val?.value) {
          totalValue += Number(val?.value);
        } else {
          totalValue += 0;
        }
      }
    }

    if (showMultiMonths) {
      const values = headerTopRowColumns.map((header: IHeaderColumn, key: number) => {
        let vals: IItemAccount[] = [];
        let isActive = false;

        if (columns.length !== 0) {
          if (isTotalData(report, showMultiMonths)) {
            if (key === 0) {
              for (let i = 0; i < columns.length; i += 1) {
                const col: IReportColumn = columns[i];

                for (let iii = 0; iii < headerTopRowColumns.length; iii += 1) {
                  const vvv = headerTopRowColumns[headerTopRowColumns.length - 1 - iii];
                  const val = itemAccountValues.find(
                    (f: IItemAccount) =>
                      f.columnId === col.id
                      && f.reportMonth === vvv.month
                      && f.chartOfAccountId === item.chartOfAccountId
                      && f.accountingClassId === item.accountingClassId
                  );

                  if (val) {
                    vals.push(val);
                    isActive = true;
                    break;
                  } else if (isActive) {
                    vals.push(val as unknown as IItemAccount);
                    break;
                  }
                }
              }
            } else if (key === headerTopRowColumns.length - 1) {
              for (let i = 0; i < columns.length; i += 1) {
                const col: IReportColumn = columns[i];

                for (let j = 0; j < itemAccountValues.length; j += 1) {
                  const f: IItemAccount = itemAccountValues[j];

                  if (
                    f.columnId === col.id
                    && f.reportMonth === header.month
                    && f.chartOfAccountId === item.chartOfAccountId
                    && f.accountingClassId === item.accountingClassId
                  ) {
                    vals.push(f);
                  }
                }
              }

              const tmpVals: IItemAccount[] = [];

              for (let i = 0; i < columns.length; i += 1) {
                const filterColumnGroup = vals.filter((vv: IItemAccount) => columns[i].id === vv.columnId);
                tmpVals.push(filterColumnGroup[filterColumnGroup.length - 1]);
              }

              vals = tmpVals;
            } else {
              for (let i = 0; i < columns.length; i += 1) {
                const col: IReportColumn = columns[i];

                for (let j = 0; j < itemAccountValues.length; j += 1) {
                  const f: IItemAccount = itemAccountValues[j];

                  if (f.columnId === col.id
                    && f.reportMonth === header.month
                    && f.chartOfAccountId === item.chartOfAccountId
                    && f.accountingClassId === item.accountingClassId
                  ) {
                    vals.push(f);
                  }
                }
              }
            }
          } else {
            for (let i = 0; i < columns.length; i += 1) {
              const col: IReportColumn = columns[i];

              for (let j = 0; j < itemAccountValues.length; j += 1) {
                const f: IItemAccount = itemAccountValues[j];

                if (f.columnId === col.id
                  && f.reportMonth === header.month
                  && f.chartOfAccountId === item.chartOfAccountId
                  && f.accountingClassId === item.accountingClassId
                ) {
                  vals.push(f);
                }
              }
            }
          }
        }

        if (columns.length === 0) {
          if (isTotalData(report, showMultiMonths)) {
            if (key === 0) {
              for (let iii = 0; iii < headerTopRowColumns.length; iii += 1) {
                const vvv = headerTopRowColumns[headerTopRowColumns.length - 1 - iii];
                const val = itemAccountValues.find(
                  (f: IItemAccount) =>
                    f.columnId === ptdColumn?.id
                    && f.reportMonth === vvv.month
                    && f.chartOfAccountId === item.chartOfAccountId
                    && f.accountingClassId === item.accountingClassId
                );

                if (val) {
                  vals.push(val);
                  break;
                }
              }
            } else if (key === headerTopRowColumns.length - 1) {
              for (let i = 0; i < itemAccountValues.length; i += 1) {
                const f: IItemAccount = itemAccountValues[i];

                if (
                  f.columnId === ptdColumn?.id
                  && f.reportMonth === header.month
                  && f.chartOfAccountId === item.chartOfAccountId
                  && f.accountingClassId === item.accountingClassId
                ) {
                  vals.push(f);
                }
              }

              const tmpVals: IItemAccount[] = [];
              const filterColumnGroup = vals.filter((vv: IItemAccount) => ptdColumn?.id === vv.columnId);
              tmpVals.push(filterColumnGroup[filterColumnGroup.length - 1]);
              vals = tmpVals;
            } else {
              for (let i = 0; i < itemAccountValues.length; i += 1) {
                const f: IItemAccount = itemAccountValues[i];

                if (f.columnId === ptdColumn?.id
                  && f.reportMonth === header.month
                  && f.chartOfAccountId === item.chartOfAccountId
                  && f.accountingClassId === item.accountingClassId
                ) {
                  vals.push(f);
                }
              }
            }
          } else {
            for (let i = 0; i < itemAccountValues.length; i += 1) {
              const f: IItemAccount = itemAccountValues[i];

              if (f.columnId === ptdColumn?.id
                && f.reportMonth === header.month
                && f.chartOfAccountId === item.chartOfAccountId
                && f.accountingClassId === item.accountingClassId
              ) {
                vals.push(f);
              }
            }
          }
        }

        return vals;
      });

      for (let i = 0; i < values.length; i += 1) {
        const vals: IItemAccount[] = values[i];

        for (let j = 0; j < vals.length; j += 1) {
          const val: IItemAccount = vals[j];

          if (val?.value) {
            totalValue += Number(val?.value);
          } else {
            totalValue += 0;
          }
        }
      }
    }

    if (hideZeroRows) {
      item.show = totalValue !== 0;
    } else {
      item.show = true;
    }
  }
};

const dateRangeFilterToQueryParams = (
  reportId: TMongoID,
  periodType: TReportDataPeriodType,
  reportDataRange?: TDateFilter,
  accountingMethod?: string,
): IGetReportDatasParams => {
  if (periodType === 'daily') {
    return {
      reportId,
      periodType,
      isDaily: true,
      from: formatApiDate(formatDate(reportDataRange?.gte)),
      to: formatApiDate(formatDate(reportDataRange?.lte)),
    };
  }
  if (periodType === 'weekly') {
    const startDate = reportDataRange?.gte ? startOfWeekApiDate(parseApiDate(reportDataRange?.gte)) : '';
    const endDate = reportDataRange?.lte ? endOfWeekApiDate(parseApiDate(reportDataRange?.lte)) : '';
    return {
      reportId,
      periodType,
      includeTotal: true,
      from:         startDate,
      to:           endDate,
    };
  }

  const startDate = reportDataRange?.gte ? startOfMonthApiDate(parseApiDate(reportDataRange?.gte)) : '';
  const endDate = reportDataRange?.lte ? endOfMonthApiDate(parseApiDate(reportDataRange?.lte)) : '';
  return {
    reportId,
    periodType,
    includeTotal: true,
    from:         startDate,
    to:           endDate,
    accounting_method:    accountingMethod,
  };
};

const useGetReportDataRange = (report: IReport, business: IBusiness, index: string, itemIdentifier?: string): {
  periodType: TReportDataPeriodType,
  reportDataRange: TDateFilter | undefined,
  section: TSection
} => {
  const setReportDateRange = useSetRecoilState(reportFilterDateRange);
  const periodType = useRecoilValue(reportDataPeriodType);
  const setPickMultiMonths = useSetRecoilState(pickMultiMonths);
  const setPickMultiDaily = useSetRecoilState(pickMultiDaily);
  const newDrillDownEnabled = useUserFeatureFlag(window.Docyt.Common.Constants.ENABLE_NEW_DRILL_DOWN_OPTION, true);

  const section = useMemo(() => (
    {
      section: `${Section.BusinessReport}_${report.id}`,
    }
  ), [report.id]);

  const [reportDataRange, updateReportDataRange] = <TUseFilterFieldReturn<TDateFilter>>(
    useFilterField('reportDataRange', section)
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const date = new Date();
    let startDate = '';
    let endDate = '';
    const formattedParamFromDate = formatDate(params.get('from'), API_DATE_FORMAT);
    const formattedParamToDate = formatDate(params.get('to'), API_DATE_FORMAT);

    if (periodType) {
      if (periodType === 'daily') {
        if (!params.get('from') || params.get('from') === ''
          || formattedParamFromDate === 'Invalid date'
          || params.get('to') === ''
          || formattedParamToDate === 'Invalid date') {
          startDate = formatDate(date, API_DATE_FORMAT);
          endDate = formatDate(date, API_DATE_FORMAT);
        } else if (moment(formattedParamFromDate).isAfter(moment(formattedParamToDate))) {
          startDate = formattedParamToDate;
          endDate = formattedParamToDate;
        } else {
          startDate = formattedParamFromDate;
          endDate = formattedParamToDate;
        }
      }

      if (periodType === 'weekly') {
        if (!params.get('from') || params.get('from') === ''
          || formattedParamFromDate === 'Invalid date'
          || params.get('to') === ''
          || formattedParamToDate === 'Invalid date') {
          startDate = startOfWeekApiDate(date);
          endDate = endOfWeekApiDate(date);
        } else if (moment(formattedParamFromDate).isAfter(moment(formattedParamToDate))) {
          startDate = startOfWeekApiDate(parseApiDate(params.get('to') as string));
          endDate = endOfWeekApiDate(parseApiDate(params.get('to') as string));
        } else {
          startDate = formattedParamFromDate;
          endDate = formattedParamToDate;
        }
      }

      if (periodType === 'monthly') {
        if (!params.get('from') || params.get('from') === ''
          || formattedParamFromDate === 'Invalid date'
          || params.get('to') === ''
          || formattedParamToDate === 'Invalid date') {
          startDate = startOfMonthApiDate(date);
          endDate = endOfMonthApiDate(date);
        } else if (moment(formattedParamFromDate).isAfter(moment(formattedParamToDate))) {
          startDate = startOfMonthApiDate(parseApiDate(params.get('to') as string));
          endDate = endOfMonthApiDate(parseApiDate(params.get('to') as string));
        } else {
          startDate = startOfMonthApiDate(parseApiDate(params.get('from') as string));
          endDate = endOfMonthApiDate(parseApiDate(params.get('to') as string));
        }
      }

      if (periodType === 'daily') {
        updateReportDataRange({ gte: startDate, lte: endDate });
      } else if (periodType === 'weekly') {
        updateReportDataRange({ gte: startOfWeekApiDate(parseApiDate(startDate)), lte: endOfWeekApiDate(parseApiDate(endDate)) });
      } else {
        updateReportDataRange({ gte: startDate, lte: startOfMonthApiDate(parseApiDate(endDate)) });
      }
    }
  }, [periodType, updateReportDataRange]);

  useEffect(() => {
    let startDate = '';
    let endDate = '';

    if (periodType) {
      if (periodType === 'daily') {
        startDate = reportDataRange?.gte ? formatApiDate(formatDate(reportDataRange?.gte)) : formatApiDate(moment());
        endDate = reportDataRange?.lte ? formatApiDate(formatDate(reportDataRange?.lte)) : formatApiDate(moment());
        setPickMultiDaily(!moment(reportDataRange?.gte).isSame(reportDataRange?.lte, 'day'));
        setPickMultiMonths(false);
      } else if (periodType === 'weekly') {
        startDate = startOfWeekApiDate(parseApiDate(reportDataRange?.gte));
        endDate = endOfWeekApiDate(parseApiDate(reportDataRange?.lte));
        setPickMultiDaily(!moment(reportDataRange?.gte).isSame(reportDataRange?.lte, 'day'));
        setPickMultiMonths(false);
      } else {
        startDate = startOfMonthApiDate(parseApiDate(reportDataRange?.gte));
        endDate = endOfMonthApiDate(parseApiDate(reportDataRange?.lte));
        setPickMultiDaily(false);
        setPickMultiMonths(!moment(reportDataRange?.gte).isSame(reportDataRange?.lte, 'month'));
      }

      setReportDateRange({ gte: startDate, lte: endDate });

      if (index === 'reportDetail') {
        Backbone.history.navigate(
          `/businesses/${business.id}/reports/${report.slug}?from=${startDate}&to=${endDate}`,
          { trigger: false, replace: true },
        );
      }

      if (!newDrillDownEnabled && index === 'reportOtherDetail') {
        Backbone.history.navigate(
          `/businesses/${business.id}/reports/${report.slug}/items/${itemIdentifier}/item_account_values?from=${startDate}&to=${endDate}`,
          { trigger: false, replace: true },
        );
      }
    }
  }, [
    business.id,
    report.slug,
    setPickMultiMonths,
    setPickMultiDaily,
    periodType,
    reportDataRange,
    setReportDateRange,
    itemIdentifier,
    index,
  ]);

  return {
    periodType,
    reportDataRange,
    section,
  };
};

export const useReportDatasCollection = ({ report }: IUseReportDatasCollectionParams): IReportDatasCollection => {
  let query: UseQueryResult<[IReportData[], IReportItem[]], Error> | undefined;
  let records: IReportData[] = [];
  let recordWithTotal: IReportData[] = [];
  let isReportAccess: boolean = false;
  let isDepartmentAccess: boolean = false;
  let endPoint: string = 'reports';
  let isTotalAccess: boolean = false;
  const initReport: IReportData[] = [
    {
      id:                          '',
      reportId:                    report.id,
      startDate:                   '',
      endDate:                     '',
      periodType:                  'monthly',
      updateState:                 'finished',
      updatedAt:                   '',
      updatedTimeString:           '',
      errorMsg:                    '',
      unincludedTransactionsCount: 0,
      budgetIds:                   [],
      itemValues:                  [],
      businessForecastIds:         [],
      businessBudgetIds:           [],
      validationResult:            '',
    },
  ];

  const business = useBusinessContext();
  const dataRange = useGetReportDataRange(report, business, 'reportDetail');
  const reportBTFserviceStatus = isReportBTFEnabledKey(window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.BY_RANGE_RAW_LIST);
  const isBTFServiceEnabled = report.cashBasisEnabled ? false : reportBTFserviceStatus;
  const showMultiMonths = useRecoilValue(pickMultiMonths);
  const showMultiDaily = useRecoilValue(pickMultiDaily);
  const accountingMethod = useRecoilValue(reportsAccountingMethod);
  const getReportDatasParams = useMemo(() => {
    return dateRangeFilterToQueryParams(report.id, dataRange.periodType, dataRange?.reportDataRange as TDateFilter, accountingMethod);
  }, [dataRange?.reportDataRange, report.id, dataRange.periodType, accountingMethod]);

  if (getReportDatasParams.from === '' && getReportDatasParams.to === '') {
    if (getReportDatasParams.isDaily) {
      if (getReportDatasParams.periodType === 'daily') {
        getReportDatasParams.from = formatApiDate(moment());
        getReportDatasParams.to = formatApiDate(moment());
      } else {
        getReportDatasParams.from = startOfWeekApiDate(moment().toDate());
        getReportDatasParams.to = endOfWeekApiDate(moment().toDate());
      }
    } else {
      getReportDatasParams.from = startOfMonthApiDate(moment().toDate());
      getReportDatasParams.to = endOfMonthApiDate(moment().toDate());
    }
  }

  if (getReportDatasParams.from !== ''
    && getReportDatasParams.to !== ''
    && report.templateId === window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.DEPARTMENTAL_REPORT
  ) {
    isDepartmentAccess = true;
  }

  if (getReportDatasParams.from !== ''
    && getReportDatasParams.to !== ''
    && report.templateId !== window.Docyt.Common.Constants.CUSTOM_REPORT_TYPE.DEPARTMENTAL_REPORT
  ) {
    isReportAccess = true;
    if (isBTFServiceEnabled) {
      getReportDatasParams.data_type = 'raw_list';
    }
  }
  const queryDepartment = useGetDepartmentDatas(getReportDatasParams, isDepartmentAccess);
  const recordDepartment = useMemo(() => queryDepartment.data || [], [queryDepartment.data]);

  if (isReportAccess && isBTFServiceEnabled) {
    endPoint = 'report-new';
  }
  const basicReport = isBasicReport(report);
  endPoint = basicReport ? 'report-dc' : 'reports';

  const queryReport = useGetReportDatas(getReportDatasParams, endPoint, isReportAccess, isBTFServiceEnabled);
  const recordReport = useMemo(() => queryReport.data || [], [queryReport.data]);

  if (isReportAccess && isBTFServiceEnabled && queryReport.isSuccess && !basicReport) {
    getReportDatasParams.data_type = 'aggregate_total';
    endPoint = getReportBTFEndPoint(window.Docyt.Common.Constants.BTF_SERVICE_API_LIST_KEYS.BY_RANGE_AGGREGATE_TOTAL);
    isTotalAccess = true;
  }

  if (isDepartmentAccess) {
    query = queryDepartment;
    records = recordDepartment[0];
  }

  if (isReportAccess || isBTFServiceEnabled) {
    query = queryReport;
    records = recordReport[0];
  }

  const queryTotal = useGetTotalReportDatas(getReportDatasParams, endPoint, isTotalAccess, isBTFServiceEnabled);
  if (queryTotal.data?.[0] && isBTFServiceEnabled && isReportAccess && (showMultiMonths || showMultiDaily) && !basicReport) {
    recordWithTotal = [...queryTotal.data[0], ...records];
  }

  if (recordWithTotal.length === 0
    && queryReport.isSuccess
    && report.totalColumnVisible
    && isBTFServiceEnabled
    && isReportAccess
    && (showMultiMonths || showMultiDaily)
    && !basicReport
  ) {
    recordWithTotal = [...initReport, ...records];
  }
  return {
    query:   report.showOnlyTotalColumn && report.totalColumnVisible && showMultiMonths && !basicReport ? queryTotal : query,
    records: recordWithTotal.length !== 0 ? recordWithTotal : records,
    items: queryDepartment.data?.[1],
    section: dataRange.section,
  };
};

export const useReportOtherDetailDataCollection = (report: IReport, identifier: string) => {
  const business = useBusinessContext();
  const multiValue = useRecoilValue(multiSelectAddValueType);
  const budgetsSelectValue = useRecoilValue(budgetsSelectAddValueType);
  const forecastSelectValue = useRecoilValue(forecastSelectAddValueType);

  const dataRange = useGetReportDataRange(report, business, 'reportOtherDetail', identifier);
  const from = dataRange?.reportDataRange?.gte !== '' ? startOfMonthApiDate(parseApiDate(dataRange?.reportDataRange?.gte)) : '';
  const to = dataRange?.reportDataRange?.lte !== '' ? endOfMonthApiDate(parseApiDate(dataRange?.reportDataRange?.lte)) : '';

  const queryParams = {
    from,
    to,
    item_identifier: identifier,
    filter:          {
      budgets_comparison:  false,
      forecast_comparison: false,
      last_year:           multiValue.includes('last_year'),
      per_available_room:  multiValue.includes('per_available_room'),
      per_occupied_room:   multiValue.includes('per_occupied_room'),
      percentage_column:   multiValue.includes('percentage_column'),
      budget:              budgetsSelectValue.length !== 0 ? budgetsSelectValue[0] : null,
      forecast:            forecastSelectValue.length !== 0 ? forecastSelectValue[0] : null,
      ...(budgetsSelectValue.length !== 0 ? { [`checkbox-budget-${budgetsSelectValue[0]}`]: true } : {}),
      ...(forecastSelectValue.length !== 0 ? { [`checkbox-forecast-${forecastSelectValue[0]}`]: true } : {}),
    },
  };

  const queryItemAccountValues = useGetReportIdentifierItemAccountValues(report.id, queryParams);
  const itemAccountValues = useMemo(() => queryItemAccountValues.data || [], [queryItemAccountValues.data]);

  const queryItemValues = useGetReportIdentifierItemValues(report.id, queryParams);
  const itemValues = useMemo(() => queryItemValues.data || [], [queryItemValues.data]);

  return {
    query:   queryItemValues,
    itemValues,
    itemAccountValues,
    section: dataRange.section,
  };
};
