import React from 'react';

import { format } from 'date-fns';

import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { AuditScanDetails, ClosedItem } from '@src/types/operational_dashboard/audit_scan';

import SideView from '@src/components/ui/side_view';
import { Button } from '@src/components/ui_v2/buttons';
import { DownloadIcon } from '@src/components/utils/icomoon';

import styles from './style.module.scss';

type TOpenValue = {
  auditScan: AuditScanDetails;
};

type INotesSideViewProps = IUseModalProps<TOpenValue>;

const NotesSideView = ({
  isOpen,
  onCancel,
  openValue,
}: INotesSideViewProps) => {
  const renderMonthlyStatements = (closedItems: ClosedItem) => {
    const month = format(new Date(closedItems.year, closedItems.month - 1), 'MMMM yyyy');
    return (
      <div key={ `${closedItems.year}-${closedItems.month}` } className={ styles.monthclosedItems }>
        {closedItems.note && (
          <>
            <div className={ styles.closedBy }>
              <div>
                <strong>{closedItems.closedByName}</strong>
                {' '}
                marked the books as closed on
                {format(new Date(closedItems.closedAt), 'MMM dd, yyyy')}
                {' '}
                with following notes:
              </div>
            </div>
            <div className={ styles.noteText }>
              {closedItems.note}
            </div>
          </>
        )}
        <div className={ styles.monthHeader }>
          <span>{month}</span>
        </div>
        <div className={ styles.statementLinks }>
          <Button
            disabled={ !closedItems.plStatementDownloadUrl }
            variant="link"
            onClick={ () => {
              if (closedItems.plStatementDownloadUrl) {
                window.open(closedItems.plStatementDownloadUrl, '_blank');
              }
            } }
          >
            P&L Statement
            {' '}
            <DownloadIcon fontSize={ 18 } />
          </Button>
          <Button
            disabled={ !closedItems.balanceSheetDownloadUrl }
            variant="link"
            onClick={ () => {
              if (closedItems.balanceSheetDownloadUrl) {
                window.open(closedItems.balanceSheetDownloadUrl, '_blank');
              }
            } }
          >
            Balance Sheet
            {' '}
            <DownloadIcon fontSize={ 18 } />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <SideView.Content isOpen={ isOpen }>
      <SideView.Header
        title="Book Closing Notes"
        onClose={ onCancel }
      />
      <SideView.Body>
        {openValue && (
          openValue.auditScan.closedItems.length > 0 ? (
            openValue.auditScan.closedItems.map((item) => {
              return (
                renderMonthlyStatements(item)
              );
            })
          ) : (
            <div className={ styles.noNotes }>
              No notes found
            </div>
          )
        )}
      </SideView.Body>
    </SideView.Content>
  );
};

const MemoizedNotesSideView = React.memo(NotesSideView);

const useNotesSideView = makeUseModal<typeof MemoizedNotesSideView, TOpenValue>(MemoizedNotesSideView);

export {
  useNotesSideView,
  MemoizedNotesSideView as default,
};
