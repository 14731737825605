import { useMutation, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import {
  ICreateServiceDocumentSplitsParams,
  createServiceDocumentSplits,
} from '@src/requests/accounts_payable/service_document_splits';

const useCreateServiceDocumentSplits = () => {
  const queryClient = useQueryClient();

  return useMutation< void, Error, ICreateServiceDocumentSplitsParams >(
    createServiceDocumentSplits,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.accountsPayableServiceDocuments);
        queryClient.invalidateQueries(QueryKey.transactionServiceDocumentsQuery);
      },
    },
  );
};

export {
  useCreateServiceDocumentSplits,
};
