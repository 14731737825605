import React from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';
import { UserAvatar } from '@src/components/ui/avatars';

import EditProfileAction from './edit_profile_action';
import ProfileSection from './profile_section';
import SignOutAction from './sign_out_action';
import UpgradeSubscriptionAction from './upgrade_subscription_action';

import styles from './styles.module.scss';

interface IAvatarDropdownProps {
  onOpenEditProfile: () => void;
}

const AvatarDropdown = ({ onOpenEditProfile }: IAvatarDropdownProps): JSX.Element => {
  const { currentAdvisor } = window.Docyt;
  return (
    <ActionsDropdown
      className={ styles['avatar-dropdown'] }
      title={ (
        <UserAvatar
          avatar={ { avatarUrl: currentAdvisor.getAdvisorAvatarUrl() } }
          size={ 40 }
        />
) }
    >
      <span className={ styles['profile-section-title'] }>
        Profile
      </span>
      <ProfileSection currentAdvisor={ {
        avatarUrl: currentAdvisor.get('avatar_url'),
        fullName:  currentAdvisor.get('full_name'),
        email:     currentAdvisor.get('email'),
      } }
      />
      <EditProfileAction onOpenEditProfile={ onOpenEditProfile } />
      <UpgradeSubscriptionAction />
      <SignOutAction />
    </ActionsDropdown>
  );
};

export default AvatarDropdown;
