import React from 'react';

import newTabLinkImg from '@assets/images/icons/new-tab-link-icon.svg';

import ImgIcon, { IImgIconProps } from './img_icon';

const NewTabLinkIcon = (props: IImgIconProps): JSX.Element => {
  return (
    <ImgIcon
      alt="New Tab Link icon"
      src={ newTabLinkImg }
      { ...props }
    />
  );
};

export default NewTabLinkIcon;
