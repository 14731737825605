import React, { useCallback, useState, useEffect } from 'react';

import { businessChatsPath } from '@src/routes';
import { backboneNavigateToUrl } from '@src/utils/navigate';

import Root from '@src/components/root';

import ChatCentralSession from './chat_central_session';
import ChannelListView from './components/channel_list/list';
import ChatBody from './components/chat_container/chat_body';
import { SendableMessageType } from './types';

import styles from './styles.module.scss';

const ChatCentralHomeView = () => {
  const [currentChannelUrl, setCurrentChannelUrl] = useState<string>();
  const [parentMessage, setParentMessage] = useState<SendableMessageType | null>(null);
  const [gotChannelFromUrl, setGotChannelFromUrl] = useState(false);

  const setChannelUrl = useCallback((channelUrl: string) => {
    setCurrentChannelUrl(channelUrl);
    setParentMessage(null);
  }, []);

  useEffect(() => {
    const hasChannelUrl = window.location.search.includes('channelUrl');
    if (hasChannelUrl) {
      setGotChannelFromUrl(true);
      setChannelUrl(window.location.search.split('=')[1]);
      backboneNavigateToUrl(businessChatsPath());
    }
  }, [setChannelUrl]);

  return (
    <Root>
      <div className={ styles['chat-central-container'] }>
        <ChatCentralSession>
          <ChannelListView
            currentChannelUrl={ currentChannelUrl }
            disableAutoSelect={ gotChannelFromUrl }
            setChannelUrl={ setChannelUrl }
          />
          <ChatBody
            channelUrl={ currentChannelUrl ?? '' }
            parentMessage={ parentMessage }
            setCurrentChannelUrl={ setChannelUrl }
            setParentMessage={ setParentMessage }
          />
        </ChatCentralSession>
      </div>
    </Root>
  );
};

export default ChatCentralHomeView;
