import React, { memo } from 'react';

import { aiChatBotPath } from '@src/routes';

import { AppLink } from '@src/components/ui_v2/buttons';
import { DocytBotAi } from '@src/components/utils/icomoon';

import styles from '../styles.module.scss';

const NavigateBtn = () => {
  return (
    <div className={ styles['navigate-btn'] }>
      <AppLink className={ styles.logo } href={ aiChatBotPath() }>
        <DocytBotAi fontSize={ 22 } />
        <span className={ styles['logo-text'] }>
          Ask Docyt AI
        </span>
      </AppLink>
    </div>
  );
};

export default memo(NavigateBtn);
