import { AxiosError } from 'axios';
import moment from 'moment';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { useUpdateReportDataFayeChannel } from '@src/hooks/faye/update_report_data';
import { useDebouncedCallback } from '@src/hooks/utils';
import {
  IGetReportDatasParams,
  IUpdateReportDatasParams,
  getReportDatas,
  updateReportDatas,
  getDepartmentDatas,
  getReportIdentifierItemAccountValues,
  getReportIdentifierItemValues,
  getBusinessLaborReportMappedByDepartment,
  ILinkBusinessLaborReportsDepartment,
  setLinkBusinessLaborReportsDepartment,
  setUnlinkBusinessLaborReportsDepartment,
  getBusinessLaborReportDepartment,
  getReportUpdateEstimation,
} from '@src/requests/report_service/report_datas';
import { TID, TMongoID } from '@src/types/common';
import { IReportDataUpdatedFayeEvent } from '@src/types/docyt_events/report_data_updated';
import { IItemValue, IReportData } from '@src/types/report_service/report_data';
import { IItemAccount, IReportItem } from '@src/types/report_service/report_item';

const useGetReportDatas = (
  params: IGetReportDatasParams,
  endPoint: string,
  isTotalAccess: boolean,
  isBTFServiceEnabled: boolean,
) => {
  const query = useQuery<[IReportData[], IReportItem[]], Error>(
    [QueryKey.reportDatas, params],
    () => getReportDatas(params, endPoint),
    {
      enabled:   isTotalAccess,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const { refetch } = query;

  const handleReportDataUpdated = useDebouncedCallback((
    fayeEvent: IReportDataUpdatedFayeEvent,
  ) => {
    const startDate = moment(fayeEvent.event.startDate).format('YYYY-MM-DD');
    const endDate = moment(fayeEvent.event.endDate).format('YYYY-MM-DD');

    if (isBTFServiceEnabled) {
      params.data_type = 'raw_list';
    }

    if (isTotalAccess
      && (
        params.isDaily
        || (
          moment(params.from).isSameOrBefore(startDate)
          && moment(params.to).isSameOrAfter(endDate)
        )
      )
    ) {
      refetch();
    }
  }, [refetch], 10000);

  useUpdateReportDataFayeChannel(params.reportId, handleReportDataUpdated);

  return query;
};

const useGetTotalReportDatas = (
  params: IGetReportDatasParams,
  endPoint: string,
  isTotalAccess: boolean,
  isBTFServiceEnabled: boolean,
) => {
  const query = useQuery<[IReportData[], IReportItem[]], Error>(
    [QueryKey.reportDatas, params],
    () => getReportDatas(params, endPoint),
    {
      enabled:   isTotalAccess,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const { refetch } = query;

  const handleReportDataUpdated = useDebouncedCallback((
    fayeEvent: IReportDataUpdatedFayeEvent,
  ) => {
    const startDate = moment(fayeEvent.event.startDate).format('YYYY-MM-DD');
    const endDate = moment(fayeEvent.event.endDate).format('YYYY-MM-DD');

    if (isBTFServiceEnabled) {
      params.data_type = 'aggregate_total';
    }

    if (isTotalAccess
      && (
        params.isDaily
        || (
          moment(params.from).isSameOrBefore(startDate)
          && moment(params.to).isSameOrAfter(endDate)
        )
      )
    ) {
      refetch();
    }
  }, [refetch], 10000);

  useUpdateReportDataFayeChannel(params.reportId, handleReportDataUpdated);

  return query;
};

const useGetDepartmentDatas = (params: IGetReportDatasParams, isTotalAccess: boolean) => {
  const query = useQuery<[IReportData[], IReportItem[]], Error>(
    [QueryKey.reportDatas, params],
    () => getDepartmentDatas(params),
    {
      enabled:   isTotalAccess,
      cacheTime: 0,
      staleTime: 0,
    },
  );

  const { refetch } = query;

  const handleReportDataUpdated = useDebouncedCallback((
    fayeEvent: IReportDataUpdatedFayeEvent,
  ) => {
    const startDate = moment(fayeEvent.event.startDate).format('YYYY-MM-DD');
    const endDate = moment(fayeEvent.event.endDate).format('YYYY-MM-DD');
    if (isTotalAccess
      && moment(params.from).isSameOrBefore(startDate)
      && moment(params.to).isSameOrAfter(endDate)) {
      refetch();
    }
  }, [refetch], 10000);

  useUpdateReportDataFayeChannel(params.reportId, handleReportDataUpdated);

  return query;
};

const useUpdateReportDatas = () => {
  return useMutation<{jobId: string}, Error & AxiosError, IUpdateReportDatasParams>(updateReportDatas);
};

const useGetReportIdentifierItemAccountValues = (reportId: string, params: any) => {
  return useQuery<IItemAccount[], Error>(
    [QueryKey.reportIdentifierItemAccountValues, params],
    () => getReportIdentifierItemAccountValues(reportId, params),
    {
      enabled: Boolean(reportId) && Boolean(params.from) && Boolean(params.to) && Boolean(params.item_identifier),
    },
  );
};

const useGetReportIdentifierItemValues = (reportId: string, params: any) => {
  return useQuery<IItemValue[], Error>(
    [QueryKey.reportIdentifierItemValues, params],
    () => getReportIdentifierItemValues(reportId, params),
    {
      enabled: Boolean(reportId) && Boolean(params.from) && Boolean(params.to) && Boolean(params.item_identifier),
    },
  );
};

const useGetBusinessLaborReportDepartment = (params: {businessId: TID, dimension_code: string}) => {
  return useQuery<{ label: string }[], Error>(
    [QueryKey.reportLaborAllDepartment, params],
    () => getBusinessLaborReportDepartment(params),
    {
      enabled: Boolean(params.businessId) && Boolean(params.dimension_code),
    },
  );
};

const useGetBusinessLaborReportMappedByDepartment = (params: {reportId: string, itemId: string}) => {
  return useQuery<{
    dimensionCode: string,
    dimensionLabel: string,
  }[], Error>(
    [QueryKey.reportLaborDimensions, params],
    () => getBusinessLaborReportMappedByDepartment(params),
    {
      enabled: Boolean(params.reportId) && Boolean(params.itemId),
    },
  );
};

const useSetLinkBusinessLaborReportsDepartment = () => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, ILinkBusinessLaborReportsDepartment>(
    setLinkBusinessLaborReportsDepartment,
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(
          [QueryKey.reportLaborDimensions, { reportId: params.reportId, itemId: params.itemId }],
        );
        queryClient.invalidateQueries([QueryKey.reportItems, { reportId: params.reportId }]);
      },
    },
  );
};

const useSetUnLinkBusinessLaborReportsDepartment = () => {
  const queryClient = useQueryClient();

  return useMutation<null, Error, { reportId: string, itemId: string, dimensionLabel: string }>(
    setUnlinkBusinessLaborReportsDepartment,
    {
      onSuccess: (_, params) => {
        queryClient.invalidateQueries(
          [QueryKey.reportLaborDimensions, { reportId: params.reportId, itemId: params.itemId }],
        );
        queryClient.invalidateQueries([QueryKey.reportItems, { reportId: params.reportId }]);
      },
    },
  );
};

// this is a polling query for report update estimation
const useReportUpdateEstimation = (
  reportId: TMongoID,
  jobId: string,
  enabled = true,
) => {
  return useQuery(
    [QueryKey.reportUpdateEstimation, reportId, jobId],
    () => getReportUpdateEstimation({ reportId, jobId }),
    {
      enabled: Boolean(reportId) && Boolean(jobId) && enabled,
      refetchInterval: 10000, // Poll every 10 seconds
      retry: false,
    },
  );
};

export {
  useGetReportDatas,
  useGetTotalReportDatas,
  useGetDepartmentDatas,
  useUpdateReportDatas,
  useGetReportIdentifierItemValues,
  useGetReportIdentifierItemAccountValues,
  useGetBusinessLaborReportMappedByDepartment,
  useSetLinkBusinessLaborReportsDepartment,
  useSetUnLinkBusinessLaborReportsDepartment,
  useGetBusinessLaborReportDepartment,
  useReportUpdateEstimation,
};
