import React from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useGetIntegration } from '@src/hooks/queries/integrations/integrations';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { TID } from '@src/types/common';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';

import ChangeMappingIntegrationAction from './actions/change_mapping_integration_action';
import ConfigureIntegrationAction from './actions/configure_integration_action';
import DisconectIntegrationAction from './actions/disconnect_integration_action';
import IntegrationHistoryAction from './actions/integration_history_action';
import ToggleIntegrationPauseAction from './actions/toggle_integration_pause_action';

import styles from './styles.module.scss';

interface RevenueCaptureIntegrationSubmenuProps {
  integrationId: TID
}

const RevenueCaptureIntegrationSubmenu = ({
  integrationId,
}: RevenueCaptureIntegrationSubmenuProps) => {
  const business = useBusinessContext();
  const integrationFeatureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_INTEGRATION_OPERATIONS,
  );
  const integrationMappingFeatureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_NEW_REVENUE_MAPPING,
  );

  const integration = useGetIntegration({
    integrationId,
    businessId: business.id,
  });
  if (!integrationFeatureQuery.data || (integrationFeatureQuery.data && !integration)) return null;
  return (
    <ActionsDropdown className={ styles['ml-auto'] }>
      {integration.data && (
        <ConfigureIntegrationAction
          externalSystemConfig={ integration.data?.externalSystemConfig }
          externalSystemId={ integration.data?.externalSystemId }
          externalSystemName={ integration.data?.externalSystemName }
          integrationType={ integration.data?.integrationType }
        />
      )}
      <IntegrationHistoryAction
        integrationId={ integrationId }
      />
      {integration.data && (
        <ToggleIntegrationPauseAction integration={ integration.data } />
      )}
      <DisconectIntegrationAction
        integrationId={ integrationId }
      />
      {integration.data && integrationMappingFeatureQuery.data && (
        <ChangeMappingIntegrationAction integration={ integration.data } />
      )}
    </ActionsDropdown>
  );
};

export default React.memo(RevenueCaptureIntegrationSubmenu);
