import React from 'react';

import { IIntegration } from '@src/types/integrations/integrations';

import ActionsDropdown from '@src/components/ui_v2/actions_dropdown';
import EditMappingIcon from '@src/components/utils/icomoon/edit_mapping';

import { useChangeMappingRevenueReportModal } from '../modal/change_mapping_revenue_report_action_modal';

interface IChangeMappingIntegrationActionProps{
  integration: IIntegration,
}

const ChangeMappingIntegrationAction = ({
  integration,
}: IChangeMappingIntegrationActionProps) => {
  const changeMappingRevenueReportModal = useChangeMappingRevenueReportModal();

  const changeMapping = () => {
    changeMappingRevenueReportModal.open();
  };
  return (
    <>
      <ActionsDropdown.Action
        icon={ <EditMappingIcon fontSize={ 20 } /> }
        title="Change Mapping"
        onSelect={ changeMapping }
      />
      <changeMappingRevenueReportModal.Component
        integration={ integration }
        { ...changeMappingRevenueReportModal.props }
      />
    </>

  );
};
export default React.memo(ChangeMappingIntegrationAction);
