import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { useCreateBusiness } from '@src/hooks/queries/businesses';

import { CancelModalButton } from '@src/components/ui/buttons';
import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';

import AddClientForm from './AddClientForm';
import { IAddClientFormValues, addClientValidation } from './schema';

import styles from '../style.module.scss';

interface IAddClientPopupProps {
  isOpen?: boolean;
  onCancel?: () => void;
  onDone?: () => void;
}

const AddClientPopup = ({
  isOpen: defaultIsOpen = false,
  onCancel,
  onDone,
}: IAddClientPopupProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  const { mutate: createBusiness, isLoading:isSubmitting } = useCreateBusiness();

  // Add check for defaultAccountingFirmId
  const defaultFirmId = window.Docyt?.currentAdvisor?.defaultAccountingFirmId?.();
  if (!defaultFirmId) {
    toastr.error('Required system settings are missing. Please contact support.', 'Configuration Error');
  }

  const form = useForm<IAddClientFormValues>({
    resolver:      yupResolver(addClientValidation),
    mode:          'onBlur',
    defaultValues: {
      serviceProviderBusinessId: defaultFirmId || '',
    },
  });

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => {
    form.reset();
    setIsOpen(false);
    onCancel?.();
  };

  const handleModalOnSuccess = () => {
    form.reset();
    setIsOpen(false);
    onDone?.();
  };

  const handleFormSubmit = (data: IAddClientFormValues) => {
    createBusiness({
      business: {
        addBusinessAdvisor:        true,
        standardCategoryId:        data.standardCategory,
        name:                      data.legalName,
        displayName:               data.displayName,
        serviceProviderBusinessId: data.serviceProviderBusinessId,
      },
    }, {
      onSuccess: () => {
        toastr.success(`${data.displayName} added successfully`, 'Add Client');
        handleModalOnSuccess();
      },
      onError: (error) => {
        const errorMessage = error.message || 'Failed to create Client. Please try again.';
        toastr.error(errorMessage, 'Add Client Failed');
      },
    });
  };

  return (
    <div className={ styles['add-client-popup'] }>
      <Button title="Add Client" variant="primary" onClick={ handleOpen }>
        Add Client
      </Button>
      <Modal
        show={ isOpen }
        title="Add New Client"
        onCancel={ handleClose }
      >
        <Modal.Body>
          <AddClientForm form={ form } onSubmit={ handleFormSubmit } />
        </Modal.Body>
        <Modal.Footer>
          <CancelModalButton title="Cancel" onClick={ handleClose } />
          <Button
            disabled={ isSubmitting }
            form="addClientForm"
            type="submit"
            variant="primary"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddClientPopup;
