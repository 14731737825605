module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal add-client-modal" tabindex="-1" role="dialog">\n  <div class="modal-dialog modal-md">\n    <div class="modal-content">\n      <div class="modal-header">\n        <h4 class="text-center">Add New Client</h4>\n      </div>\n      <div class="modal-body">\n        <div class="form-group flex-wrapper">\n          <label>Select Industry: <span class="required">*</span></label>\n          <div class="input-wrapper">\n            <select class="form-control industry-select">\n              <option value="">Select Industry</option>\n            </select>\n            <p class=\'error-message industry-invalid\'>\n              Please select an industry.\n            </p>\n          </div>\n        </div>\n\n        <div class="form-group flex-wrapper">\n          <label>Legal Name: <span class="required">*</span></label>\n          <div class="input-wrapper">\n            <input class="form-control legal-name-input" type="text" placeholder="Enter Legal Name">\n            <p class=\'error-message legal-name-invalid\'>\n              Please enter a valid legal name.\n            </p>\n          </div>\n        </div>\n\n        <div class="form-group flex-wrapper">\n          <label>Display Name:</label>\n          <div class="input-wrapper">\n            <input class="form-control display-name-input" type="text" placeholder="Enter Display Name">\n            <p class=\'error-message display-name-invalid\'>\n              Please enter a valid display name.\n            </p>\n          </div>\n        </div>\n      </div>\n      <div class="modal-footer">\n        <a class=\'cancel-link\'>Cancel</a>\n        <button type=\'button\' class="btn btn-primary client-save-btn ladda-button" data-style="expand-right">\n          <span class="ladda-label">Add</span>\n        </button>\n      </div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}