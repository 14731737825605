import { IItemAccount } from "@src/types/report_service/report_item";

export const dummyItemAccountValue: IItemAccount = {
  id:                'TMongoID',
  accountingClassId: 1,
  chartOfAccountId:  2,
  columnId:          'TMongoID',
  columnType:        'variance',
  itemId:            'TMongoID',
  itemName:          'TMongoID',
  reportDataId:      'TMongoID',
  valueType:         'TMongoID',
  name:              'TMongoID',
  value:             null,
  reportMonth:       1,
  reportYear:        2024,
  createdAt:         'TDateTime',
  updatedAt:         'TDateTime',
  show:              true,
  search:            false,
};