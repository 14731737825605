import React from 'react';

import { TDateRange } from '@src/types/common';
import { getMonthsInRange } from '@src/utils/date_helpers';

import Table from '@src/components/ui_v2/table';

interface ITableHeaderProps {
  dateRange: TDateRange;
}

const TableHeader = ({ dateRange }: ITableHeaderProps): JSX.Element => {
  const months = getMonthsInRange(dateRange?.startDate || '', dateRange?.endDate || '');

  return (
    <Table.Head>
      <Table.Row>
        <Table.EmptyHCell className="line-item-cell">KPI</Table.EmptyHCell>
        {months.map((month) => (
          <Table.HCell key={ month } className="header-cell">
            {month}
          </Table.HCell>
        ))}
      </Table.Row>
    </Table.Head>
  );
};

export default TableHeader;
