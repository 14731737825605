module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var accountingClass, i, len, ref;
    
      __out.push('<div class="display-flex-column in-black">\n  <div class=\'display-flex align-items-center width-100-percent\'>\n    <span class="inline-block m-r-auto">Select type and then select chart of accounts from QBO to map to ');
    
      __out.push(__sanitize(this.childItem.get('name')));
    
      __out.push('.</span>\n    <button type=\'button\' class="btn btn-blue link-btn pull-right m-l-20" disabled>Map Selected Accounts</button>\n  </div>\n  <span class="inline-block font-semi-bold font-16 m-t-25">Chart of Accounts from QBO</span>\n  <div class=\'display-flex\'>\n    <div>\n      <div class=\'display-flex align-items-center m-t-16\'>\n        <span class="font-14 width-150px">Type</span>\n        <div class="dropdown-wrapper">\n          <div class="dropdown-toggle dropdown-control border-gray" id="select-chart-of-account-type" data-toggle="dropdown">\n            <span class="type-label-js"></span>\n            <span class="fa fa-caret-down"></span>\n          </div>\n          <ul class="dropdown-menu" aria-labelledby="select-chart-of-account-type">\n            ');
    
      if (this.isPLRequired) {
        __out.push('\n              <li class="type-p-l-js" data-value=\'mapped\'>\n                <div class=\'display-flex align-items-center\'>\n                  <span class=\'text m-t-3 m-l-10\'>P&L Accounts</span>\n                </div>\n              </li>\n            ');
      }
    
      __out.push('\n            <li class="type-balance-sheet-js" data-value=\'unmapped\'>\n              <div class=\'display-flex align-items-center\'>\n                <span class=\'text m-t-3 m-l-10\'>Balance Sheet Accounts</span>\n              </div>\n            </li>\n          </ul>\n        </div>\n      </div>\n      ');
    
      if (this.type === 'profit_loss' && this.isPLRequired) {
        __out.push('\n      <div class=\'display-flex align-items-center m-t-16\'>\n        <span class="font-14 width-150px">Select Departments</span>\n        <select id=\'select-accounting-class\' class="form-control" multiple="multiple">\n          ');
        ref = this.accountingClasses;
        for (i = 0, len = ref.length; i < len; i++) {
          accountingClass = ref[i];
          __out.push('\n            <option value="');
          __out.push(__sanitize(accountingClass.get('id')));
          __out.push('">');
          __out.push(__sanitize(accountingClass.get('name')));
          __out.push('</option>\n          ');
        }
        __out.push('\n        </select>\n      </div>\n      ');
      }
    
      __out.push('\n    </div>\n    <div class=\'display-flex map_refresh_btn_region\'>\n      <button type=\'button\' class="btn btn-blue-on-hover apply-btn pull-right m-l-10">Apply</button>\n    </div>\n  </div>\n  <div class=\'display-flex width-100-percent m-t-32 font-14 in-grey-1100\'>\n    <div class="dispaly-fex">\n      <span class="unlinked-label-js"> 0 Unmapped</span>\n      <span> | </span>\n      <span class="linked-label-js">0 Mapped</span>\n    </div>\n    <div class="checkbox checkbox-primary m-t-0 m-b-0 m-l-auto">\n      <input type="checkbox" id="unlinked-categories-checkbox">\n      <label for="unlinked-categories-checkbox">Show only unmapped chart of account</label>\n    </div>\n  </div>\n  <div class="table-responsive transactions-table-wrapper m-t-12">\n    <div class="panel panel-default">\n      <div class="category-filter-container"></div>\n      <div class="category-list-container"></div>\n    </div>\n  </div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}