import React from 'react';

import SetPasswordForm from './form';
import OnboardingPage from '../layout/onboarding_page';
import { useOnboarding } from '../provider';

const SetPasswordPage = ({ userType }: { userType: string }) => {
  const { fullName } = useOnboarding();

  return (
    <OnboardingPage title={ `Hello ${ fullName }!` }>
      <SetPasswordForm formId="set-password-form" userType={ userType } />
    </OnboardingPage>
  );
};

export default SetPasswordPage;
