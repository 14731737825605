import { useMemo } from 'react';

import { useGetStandardCategories } from './queries/operational_dashboard/operation_dashboard_service';

export interface StandardCategoryOption {
  name : string;
  id: number | string;
  disabled?: boolean;
}

export const useStandardCategories = (disabledIds?: Set<number | string>) => {
  const { data: standardCategories, isLoading, error } = useGetStandardCategories();

  const categories = useMemo(() => {
    return standardCategories?.map((category) => ({
      name:     category.name,
      id:       category.id,
      disabled: disabledIds?.has(category.id) || false,
    })) || [];
  }, [standardCategories, disabledIds]);

  return {
    categories,
    isLoading,
    error,
  };
};
