import React, { useEffect, useMemo } from 'react';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import {
  useGetReconciliationPaymentAccounts,
} from '@src/hooks/queries/reconciliation_payment_accounts';
import { ITSDComponentProps } from '@src/types/transaction_service_documents';

import Spinner from '@src/components/ui/spinner';

import DocytAiLearnToggle from './docyt_ai_learn_toggle';
import NoDocumentNeeded from './no_document_needed';
import NoPushQbo from './no_push_qbo';
import SidebarSelectInput from './sidebar_select_input';
import TransactionTypeSelector from './transaction_type_selector';
import useTxnDoc from './use_txn_doc';

import styles from '@src/components/reconciliation_center/all_transactions/styles.module.scss';

const Transfer = ({ rowData, setRowData, verify }: ITSDComponentProps) => {
  const { updateFields, handleCreateDocumentNote, handleNdnChange, commonProps } = useTxnDoc({
    rowData,
    setRowData,
  });

  const { isSubmitting, isDisabled, doNotLearn, noDocumentNeeded, documentNote, noPushQbo, documentRelated } = commonProps;

  const business = useBusinessContext();

  const accountsQuery = useGetReconciliationPaymentAccounts({
    businessId:        business.id,
    noConsiderArchive: true,
  });

  const accountOptions = useMemo(() => {
    const accounts = accountsQuery.data?.reconciliationPaymentAccounts;
    if (!accounts) return [];
    return accounts.map((i) => ({ value: String(i.id), label: i.name }));
  }, [accountsQuery.data?.reconciliationPaymentAccounts]);

  const fromAccountValue = accountOptions.find((i) => i.value === String(rowData.fromAccountId));
  const toAccountValue = accountOptions.find((i) => i.value === String(rowData.toAccountId));

  const requiredValue = fromAccountValue && toAccountValue;
  useEffect(() => {
    let disabled = true;

    if (requiredValue && documentRelated) {
      disabled = false;
    }

    verify(disabled);
  }, [requiredValue, documentRelated, verify]);

  return (
    <>
      {isSubmitting && <Spinner />}
      <div className={ styles['sidebar-type-config'] }>
        <TransactionTypeSelector rowData={ rowData } setRowData={ setRowData } />

        <SidebarSelectInput
          isDisabled={ isDisabled }
          label="From Account*"
          options={ accountOptions.filter((i) => i.value !== String(rowData.toAccountId)) }
          placeholder="Select Payment Account"
          value={ fromAccountValue }
          onChange={ (val) => updateFields({ fromAccountId: val }) }
        />

        <SidebarSelectInput
          isDisabled={ isDisabled }
          label="To Account*"
          options={ accountOptions.filter((i) => i.value !== String(rowData.fromAccountId)) }
          placeholder="Select Payment Account"
          value={ toAccountValue }
          onChange={ (val) => updateFields({ toAccountId: val }) }
        />

        <DocytAiLearnToggle
          checked={ !doNotLearn }
          isDisabled={ isDisabled }
          onChange={ (checked) => updateFields({ doNotLearn: !checked }) }
        />
      </div>

      <div className={ styles['sidebar-type-config'] }>
        <NoDocumentNeeded
          key={ documentNote }
          documentNote={ documentNote }
          isDisabled={ isDisabled }
          noDocumentNeeded={ noDocumentNeeded }
          rowData={ rowData }
          onDocumentNoteChange={ handleCreateDocumentNote }
          onNdnChange={ handleNdnChange }
        />
        <NoPushQbo
          isDisabled={ isDisabled }
          noPushQbo={ noPushQbo }
          onChange={ (checked) => updateFields({ noPushQbo: checked }) }
        />
      </div>
    </>
  );
};

export default Transfer;
