import React from 'react';

import { IAdvisor } from '@src/types/users';

import UserAvatar from '@src/components/ui/avatars/user_avatar';

import styles from './styles.module.scss';

interface ProfileSectionProps {
  currentAdvisor: Pick<IAdvisor, 'firstName' | 'fullName' | 'email' | 'avatarUrl'>;
}

const ProfileSection: React.FC<ProfileSectionProps> = ({ currentAdvisor }) => {
  // const { currentAdvisor } = window.Docyt;

  return (
    <div className={ styles['profile-section'] }>
      <UserAvatar
        avatar={ { avatarUrl: currentAdvisor.avatarUrl } }
        size={ 58 }
      />
      <div className={ styles['user-info'] }>
        <h3 className={ styles['user-info-name'] }>{currentAdvisor.fullName}</h3>
        <span className={ styles['user-info-email'] }>{currentAdvisor.email}</span>
      </div>
    </div>
  );
};

export default ProfileSection;
