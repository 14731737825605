import React, { useCallback, useState } from 'react';

import { useImportDataFromLedger } from '@src/hooks/queries/businesses';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';

import ProgressActionLoader from './progress_action_loader';
import HoverTooltipWrapper from '../hover_tooltip_wrapper';

const StartLearningButton = ({ businessId }: { businessId: TID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate: importDataFromLedger } = useImportDataFromLedger();

  const handleClick = useCallback(() => {
    setIsLoading(true);
    importDataFromLedger({ businessId }, {
      onSuccess: () => {
        setIsLoading(true);
      },
      onError: () => {
        setIsLoading(false);
      },
    });
  }, [businessId, importDataFromLedger]);

  if (isLoading) {
    return <ProgressActionLoader status="in_ledger_setup" />;
  }

  return (
    <HoverTooltipWrapper
      content={ (
        <>
          Setup failed. Retry learning from past data.
        </>
      ) }
    >
      <Button size="compact" variant="outline" onClick={ handleClick }>Start Learning</Button>
    </HoverTooltipWrapper>
  );
};

export default StartLearningButton;
