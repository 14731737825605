// src/components/operational_dashboard/components/custom_cells/business_drive_cell.tsx
import React from 'react';

import { IBusinessCommonProps } from '@src/components/operational_dashboard/types';
import { AppLink } from '@src/components/ui_v2/buttons';
import { BusinessDriveIcon } from '@src/components/utils/icomoon';

import styles from './style.module.scss';

const BusinessDriveCell: React.FC<IBusinessCommonProps> = ({ unreadInboxMessages, businessId }) => {
  return (
    <AppLink
      href={ `/businesses/${businessId}/business_inbox` }
    >
      <div className={ styles['business-actions'] }>
        <BusinessDriveIcon className={ styles[unreadInboxMessages ? 'dark-icon' : 'light-icon'] } />
        {unreadInboxMessages && <div className={ styles['notification-badge'] }>{unreadInboxMessages}</div>}
      </div>
    </AppLink>
  );
};

export default BusinessDriveCell;
