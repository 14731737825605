import { IStandardCategory } from '@src/types/services';

import { apiGet } from './helpers';

const getStandardCategories = async (): Promise<IStandardCategory[]> => {
  const response = await apiGet('/api/v1/standard_categories');

  return response.standard_categories.map((category: any) => ({
    id:   category.id,
    name: category.name,
  }));

};

export { getStandardCategories };
