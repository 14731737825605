import React, { useMemo } from 'react';

import { Section } from '@src/constants/sections';
import { TID } from '@src/types/common';

import { Button } from '@src/components/ui_v2/buttons';
import { useFilterData } from '@src/components/ui_v2/filter/hooks';
import NewDocumentIcon from '@src/components/utils/img_icons/new_document_icon';

import styles from '../styles.module.scss';

interface IEmptyViewProps {
  businessId: TID;
}

const EmptyView: React.FC<IEmptyViewProps> = ({ businessId }) => {
  const section = useMemo(() => {
    return {
      businessId,
      section: Section.AccountsPayableService,
    };
  }, [businessId]);

  const filterData = useFilterData(section);

  const hasFilters = filterData && Object.keys(filterData).length > 0;

  return (
    <div className={ styles['empty-state'] }>
      <NewDocumentIcon className={ styles['empty-state-icon'] } />
      <p className={ styles['empty-state-text-title'] }>No invoices found.</p>
      {hasFilters ? (
        <p className={ styles['empty-state-text'] }>Try adjusting the filters</p>
      ) : (
        <>
          <p className={ styles['empty-state-text'] }>Drag one or more invoices here to add in the queue</p>
          <Button
            className={ styles['empty-state-button'] }
            variant="outline"
          >
            Add Invoice(s)
          </Button>
        </>
      )}
    </div>
  );
};

export default React.memo(EmptyView);
