import React, { useEffect, useState } from 'react';

import { useConnectLedgerStatusFayeChannel } from '@src/hooks/faye/connect_ledger_status';
import { useDebouncedCallback } from '@src/hooks/utils';

import OnboardingPage from '../layout/onboarding_page';

import styles from '../styles.module.scss';

const ConnectLedgerProgress = () => {
  const businessId = window.Docyt.currentAdvisor.get(
    'default_accounting_firm',
  )?.id;
  const [progress, setProgress] = useState<number>(0);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    const progressInterval = setInterval(() => {
      if (!isSuccess) {
        setProgress((prev) => prev + 5);
      } else {
        clearInterval(progressInterval);
        setProgress(100);
      }
    }, 1500);

    return () => clearInterval(progressInterval);
  }, [isSuccess, setProgress]);

  const handleConnectLedgerStatus = useDebouncedCallback(
    ({ event }) => {
      setIsSuccess(true);

      if (
        event?.accountingFirmBusinessId
        === window.Docyt.currentAdvisor.get('default_accounting_firm')?.id
      ) {
        Backbone.history.navigate('setup_accounting_firm?step=billing', {
          trigger: true,
        });
      }
    },
    [businessId],
    10000,
  );

  useConnectLedgerStatusFayeChannel(
    Number(businessId),
    handleConnectLedgerStatus,
  );

  return (
    <OnboardingPage isHideBorder title="Setting up your account...">
      <div className={ styles['onboarding-form'] }>
        <div className={ styles['onboarding-body'] }>
          <div className="display-flex p-b-20 font-14 justify-content-center">
            Please wait while we setup your account...
          </div>
          <div className={ styles['progress-container'] }>
            <div
              className={ styles['progress-bar'] }
              style={ { width: `${progress}%` } }
            />
          </div>
        </div>
      </div>
    </OnboardingPage>
  );
};

export default React.memo(ConnectLedgerProgress);
