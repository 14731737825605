import React from 'react';

import { Button } from '@src/components/ui/buttons';
import Modal from '@src/components/ui_v2/full_page_modal';

import styles from './style.module.scss';

interface IConfigFormModalProps {
  open: boolean;
  handleHide: () => void;
  handleReset: () => void;
  children: React.ReactNode;
}

const ConfigFormModal = ({ open,
  handleReset,
  handleHide,
  children }
    : IConfigFormModalProps) => {
  if (!open) return null;

  return (
    <Modal>
      <Modal.Header
        divider="horizontal"
        title="Show/Hide Items"
        onCancel={ handleHide }
      />
      <Modal.Body>
        <div className={ styles['config-form-container'] }>
          {children}
        </div>
      </Modal.Body>
      <Modal.FooterHeader>
        <div className={ styles['filter-footer'] }>
          <Button className={ styles['reset-cancel-btn'] } onClick={ handleHide }>
            Cancel
          </Button>
          <div>
            <Button
              className={ styles['reset-cancel-btn'] }
              type="button"
              onClick={ handleReset }
            >
              Reset to Default
            </Button>
            <Button
              className={ styles['save-button'] }
              type="submit"
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.FooterHeader>
    </Modal>
  );
};
export default React.memo(ConfigFormModal);
