import React, { useCallback, useMemo } from 'react';

import { useUpdateTransactionServiceDocumentData } from '@src/hooks/queries/transaction_service_documents';
import { useDeleteDocumentNote } from '@src/requests/all_transactions';
import { IDocumentNote } from '@src/types/document_note';
import { ITransactionServiceDocument } from '@src/types/transaction_service_documents';

import { Button } from '@src/components/ui_v2/buttons';
import { CrossIcon } from '@src/components/utils/icomoon';
import MutationStatus from '@src/components/utils/mutation_status';

import styles from '../styles.module.scss';

interface IMatchedDocumentNoteProps {
  document: ITransactionServiceDocument;
  note: IDocumentNote;
}

const MatchedDocumentNote = ({ document, note }: IMatchedDocumentNoteProps): JSX.Element | null => {
  const mutation = useUpdateTransactionServiceDocumentData();
  const { mutateAsync: updateTransactionServiceDocumentData } = mutation;
  const { mutateAsync: deleteDocumentNote } = useDeleteDocumentNote();

  const documentOwner = useMemo(() => {
    if (note.noteByName) return note.noteByName;
    if (note.user) return note.user.parsedFullName;
    return null;
  }, [note.noteByName, note.user]);

  const handleNoDocumentNeeded = useCallback(async () => {
    await updateTransactionServiceDocumentData({ id: document.id, data: { noDocumentRequired: false } });
    await deleteDocumentNote({ documentId: document.documentId, noteId: note.id });
  }, [document, updateTransactionServiceDocumentData, deleteDocumentNote, note]);

  return (
    <div>
      <MutationStatus mutation={ mutation } />
      <Button 
        className={ styles['no-document-needed'] } 
        suffixIcon={ <CrossIcon fontSize={ 14 } /> } 
        variant="primary"
        onClick={ handleNoDocumentNeeded }
      >
        No document needed
      </Button>
      { note.note && (
        <>
          <hr className="m-t-15 m-b-15" />
          <p className="in-grey-1100">{ `Note added by ${documentOwner || ''}` }</p>
          <p>{ note.note }</p>
        </>
      ) }
    </div>
  );
};

export default MatchedDocumentNote;
