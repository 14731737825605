import { number, object } from 'yup';

import { TID } from '@src/types/common';

interface IChangeMappingValues {
  businessId: TID | undefined
  revenueReportTypeId: TID | undefined
}
const selectChangeMappingValidation = object({
  revenueReportTypeId: number().nullable().label('Select Mapping').required(),
});

export {
  IChangeMappingValues,
  selectChangeMappingValidation,
};
