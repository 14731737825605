module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<td class=\'pointer\'>\n  <div class=\'m-l-10 m-t-5 m-b-5\'>\n    ');
    
      if (this.avatarUrl) {
        __out.push('\n      <div class="employee-userpic">\n        <img src="');
        __out.push(__sanitize(this.avatarUrl));
        __out.push('" class=\'clients__userpic-img\'>\n      </div>\n    ');
      } else {
        __out.push('\n      <i class=\'employee-status-icon icon icon-smile in-orange-500\'></i>\n    ');
      }
    
      __out.push('\n  </div>\n</td>\n<td class="pointer font-semi-bold member-clickable">\n  <span class="block-wrapper p-l-10">');
    
      __out.push(__sanitize(this.name));
    
      __out.push('</span>\n</td>\n<td class="pointer member-clickable">\n  <span class="block-wrapper p-l-10 color-quota-pages">');
    
      __out.push(__sanitize(this.contact));
    
      __out.push('</span>\n</td>\n');
    
      if (!Docyt.currentAdvisor.checkUserAccessToBookgpt()) {
        __out.push('\n  <td class="pointer relative member-clickable">\n    <span class="block-wrapper p-l-10 color-quota-pages">');
        __out.push(__sanitize(this.business));
        __out.push('</span>\n  </td>\n');
      }
    
      __out.push('\n');
    
      if (this.clients_visible_status) {
        __out.push('\n<td class="pointer member-clickable">\n  <span class="block-wrapper p-l-10 color-quota-pages">');
        __out.push(__sanitize(this.client));
        __out.push('</span>\n</td>\n');
      }
    
      __out.push('\n<td class="pointer relative">\n  <button class="pointer dropdown-toggle action-menu-toggle-btn pull-right" type="button" id="document-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn" data-toggle="dropdown" data-container="body" aria-haspopup="true" aria-expanded="false">\n    <i class=\'icon icon-3-dots font-20\'></i>\n  </button>\n  <div class="document-action-dropdown dropdown-menu dropdown-menu-right" aria-labelledby="document-');
    
      __out.push(__sanitize(this.id));
    
      __out.push('-action-dropdown-btn">\n    <div class="dropdown-item font-14 member-profile-js" id="');
    
      __out.push(__sanitize(this.model_id));
    
      __out.push('">\n      <i class="icon icon-edit-profile"></i> <span>View Profile</span>\n    </div>\n    ');
    
      if (!Docyt.currentAdvisor.checkUserAccessToBookgpt()) {
        __out.push('\n      <div class="dropdown-item font-14 member-business-js" id="');
        __out.push(__sanitize(this.model_id));
        __out.push('">\n        <i class="icon icon-edit-business"></i> <span>Edit Businesses</span>\n      </div>\n    ');
      }
    
      __out.push('\n    ');
    
      if (this.clients_visible_status) {
        __out.push('\n    <div class="dropdown-item font-14 member-client-js" id="');
        __out.push(__sanitize(this.model_id));
        __out.push('">\n      <i class="icon icon-edit-clients"></i> <span>Edit Clients</span>\n    </div>\n    ');
      }
    
      __out.push('\n    <div class="dropdown-item font-14 member-payment-method-js" id="');
    
      __out.push(__sanitize(this.model_id));
    
      __out.push('">\n      <i class="icon icon-payment-account"></i> <span>View Payment Methods</span>\n    </div>\n    <div class="dropdown-item font-14 remove-member-js">\n      <i class="icon icon-new-trash in-red-400"></i> <span class=\'in-red-400\'>Remove Member</span>\n    </div>\n  </div>\n</td>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}