import React, { useCallback, useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { IGetBusinessChartOfAccountsParams } from '@src/requests/business_chart_of_accounts';
import { IBusiness } from '@src/types/businesses';
import { TAmount, TID } from '@src/types/common';
import { amountToPercent } from '@src/utils/currency';

import BottomModal from '@src/components/ui_v2/bottom_modal';

import Form from './category_splits_form';
import Summary from './category_splits_summary';
import { categorySplitsValidation, ICategorySplit, ICategorySplitsData } from './schema';

interface ICategorySplitsModalProps extends Omit<IUseModalProps, 'onDone'> {
  adjustmentAmount: TAmount,
  business: IBusiness,
  initialChartOfAccountId?: TID,
  isReadonly?: boolean,
  isBusinessReadonly?: boolean,
  splits?: ICategorySplit[],
  summaryTitle?: string,
  onDone: (data: ICategorySplitsData) => void,
  coaQueryParams?: Pick<IGetBusinessChartOfAccountsParams, 'searchAccType'>,
}

const CategorySplitsModal = ({
  adjustmentAmount,
  business,
  initialChartOfAccountId,
  isOpen,
  isReadonly,
  isBusinessReadonly,
  splits,
  summaryTitle,
  coaQueryParams,
  onCancel,
  onDone,
}: ICategorySplitsModalProps) => {
  const form = useForm<ICategorySplitsData>({
    mode:           'onSubmit',
    reValidateMode: 'onChange',
    resolver:       yupResolver(categorySplitsValidation),
  });

  const { handleSubmit, reset } = form;

  // Because I use `useForm` in the modal it's called on each render and before modal
  // appear. But I want to set new default values when modal is opened.
  useEffect(() => {
    if (!isOpen) return;

    reset({
      adjustmentAmount,
      splits: splits || [{
        businessId:        business.id,
        accountingClassId: null,
        chartOfAccountId:  initialChartOfAccountId,
        amount:            adjustmentAmount,
        percentage:        amountToPercent(adjustmentAmount, adjustmentAmount),
        memo:              null,
      }],
    });
  }, [isOpen, initialChartOfAccountId, adjustmentAmount, splits, reset, business]);

  const handleSubmitError = useCallback((err) => {
    if (!err.differenceAmount) return;

    toastr.error(
      window.Docyt.Common.Constants.Messages.INVOICE_AMOUNT_MISMATCH,
      'Amount mismatch',
    );
  }, []);

  return (
    <BottomModal.Standard
      doneTitle={ isReadonly ? 'Close' : 'Save' }
      footer={ <Summary form={ form } title={ summaryTitle } /> }
      isOpen={ isOpen }
      title="Split Category"
      onCancel={ onCancel }
      onDone={ handleSubmit(onDone, handleSubmitError) }
    >
      { () => (
        <Form
          business={ business }
          coaQueryParams={ coaQueryParams }
          form={ form }
          isBusinessReadonly={ isBusinessReadonly }
          isReadonly={ isReadonly }
        />
      ) }
    </BottomModal.Standard>
  );
};

const MemoizedCategorySplitsModal = React.memo(CategorySplitsModal);
const useCategorySplitsModal = makeUseModal(MemoizedCategorySplitsModal);

export {
  useCategorySplitsModal,
  MemoizedCategorySplitsModal as default,
};
