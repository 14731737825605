import React, { useState } from 'react';

import { AuditScanDetails } from '@src/types/operational_dashboard/audit_scan';

import { useNotesSideView } from '@src/components/operational_dashboard/components/sideview/notes_sideview';
import ConfigForm from '@src/components/operational_dashboard/config_form';
import { useDashboardTableCollection } from '@src/components/operational_dashboard/hooks';
import useAddClientModal from '@src/components/operational_dashboard/hooks/useAddClientModal';
import { Filter } from '@src/components/operational_dashboard/table/filter';
import Table from '@src/components/operational_dashboard/table/table';
import { TViewOperationalDashboard } from '@src/components/operational_dashboard/types';
import TableSection from '@src/components/ui_v2/table_section';
import { OdConfigIcon } from '@src/components/utils/icomoon';
import Section from '@src/components/utils_v2/section';

import styles from './style.module.scss';

const BookgptList = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleShow = () => setOpen(true);
  const handleHide = () => setOpen(false);

  const view: TViewOperationalDashboard = window.Docyt.currentAdvisor.isBookgptAccountantUser() ? 'accountant' : 'business';
  const collection = useDashboardTableCollection({ view });
  const { control } = collection.FilterForm;

  const {
    Component: NotesSideView,
    props: notesSideViewProps,
    openWithValue: openNotesSideView,
  } = useNotesSideView();

  const handleOpenNotesSideView = (auditScan?: AuditScanDetails) => {
    if (auditScan) {
      openNotesSideView({
        auditScan,
      });
    }
  };

  const {
    Component: AddClientModalComponent,
    props: addClientModalProps,
  } = useAddClientModal({ onDone: () => {
    collection.query.refetch();
  } });

  return (
    <Section.Provider section={ collection?.section }>
      <NotesSideView
        { ...notesSideViewProps }
      />
      <div className={ styles['bookgpt-wrapper'] }>
        <div className={ styles['bookgpt-list-header'] }>
          <Filter control={ control } />
          { window.Docyt.currentAdvisor.canAddClient() && (
            <AddClientModalComponent
              { ...addClientModalProps }
            />
          ) }
          <div className={ styles['od-config-icon-container'] }>
            <OdConfigIcon
              className={ styles['od-config-icon'] }
              fontSize={ 20 }
              onClick={ handleShow }
            />
            <ConfigForm handleHide={ handleHide } open={ open } />
          </div>
        </div>
        <div className={ styles['bookgpt-list-container'] }>
          <TableSection className={ styles['bookgpt-list-section'] }>
            <Table
              infiniteQuery={ collection.query }
              records={ collection.records }
              sorting={ collection.sorting }
              view={ view }
              viewNotes={ handleOpenNotesSideView }
            />
          </TableSection>
        </div>
      </div>
    </Section.Provider>
  );
};

export default React.memo(BookgptList);
