import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import omit from 'lodash/omit';
import { useController, useForm } from 'react-hook-form';

import { useBusinessContext } from '@src/hooks/contexts/business_context';

import RevenueReportTypeField from '@src/components/common_v2/form_fields/revenue_report_type_field';
import Form from '@src/components/ui_v2/form';

import {
  IChangeMappingValues,
  selectChangeMappingValidation,
} from './schema';

interface IChangeMappingProps {
  formId: string;
  onSubmit: (data: IChangeMappingValues) => void;
  onValidationChange: (isValid: boolean) => void;
}
const ChangeMappingForm = ({
  formId,
  onSubmit,
  onValidationChange,
}: IChangeMappingProps) => {
  const business = useBusinessContext();
  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<IChangeMappingValues>({
    mode:          'onChange',
    resolver:      yupResolver(selectChangeMappingValidation),
    defaultValues: {
      revenueReportTypeId: undefined,
      businessId:          undefined,
    },
  });
  const rrtControl = useController({ control, name: 'revenueReportTypeId' });

  useEffect(() => {
    onValidationChange(isValid);
  }, [isValid, onValidationChange]);
  return (
    <Form id={ formId } onSubmit={ handleSubmit(onSubmit) }>
      <RevenueReportTypeField
        businessId={ business.id }
        error={ errors.revenueReportTypeId?.message }
        label="Select Mapping"
        placeholder="Select Map"
        { ...omit(rrtControl.field, 'ref') }
      />
    </Form>
  );
};
export default React.memo(ChangeMappingForm);
