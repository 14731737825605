import { TID } from '@src/types/common';
import { TFayeEventCallback } from '@src/types/docyt_events/common';
import { IConnectLedgerStatusFetchedFayeEvent } from '@src/types/docyt_events/connect_ledger_status_fetched';

import { useFayeChannel } from './common';

export const useConnectLedgerStatusFayeChannel = (
  businessId: TID,
  callback: TFayeEventCallback<IConnectLedgerStatusFetchedFayeEvent>
) => {
  useFayeChannel(`/import_clients_from_ledger_completed-${businessId}`, callback);
};
