import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useController, useForm } from 'react-hook-form';
import { string, object } from 'yup';

import toastr from '@lib/toastr';
import { IUseModalProps, makeUseModal } from '@src/hooks/modal';
import { useCloseAuditScan } from '@src/hooks/queries/operational_dashboard/audit_scan';
import { TID } from '@src/types/common';

import Modal from '@src/components/ui/modal';
import { Button } from '@src/components/ui_v2/buttons';
import Form from '@src/components/ui_v2/form';

type TOpenValue = {
  auditId: TID;
  businessId: TID;
};
type IMarkBookAsClosedProps = IUseModalProps<TOpenValue>;

type IMarkBookAsClosedFormValues = {
  closeDate: string;
  notes: string;
}

const MarkBookAsClosedModal = ({
  isOpen,
  onDone,
  onCancel,
  openValue,
}: IMarkBookAsClosedProps): JSX.Element => {
  const { control, handleSubmit, formState: { errors }, reset } = useForm<IMarkBookAsClosedFormValues>({
    resolver: yupResolver(object({
      closeDate: string().required('Close date is required'),
    })),
  });

  const [loading, setLoading] = useState(false);

  const dateController = useController({ name: 'closeDate', control });
  const notesController = useController({ name: 'notes', control });

  const closeAuditScanMutation = useCloseAuditScan();

  const onSubmit = async (values: IMarkBookAsClosedFormValues) => {
    setLoading(true);
    try {
      await closeAuditScanMutation.mutateAsync({
        closeDate:  values.closeDate,
        notes:      values.notes,
        id:         openValue?.auditId as number,
        businessId: openValue?.businessId as number,
      });
      reset();
      onCancel();
      onDone();
    } catch (error) {
      toastr.error(error as string, 'Error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={ isOpen }
      title="Set Books Close Date"
    >
      <Form id="mark-book-as-closed-form" onSubmit={ handleSubmit(onSubmit) }>
        <Modal.Body>
          <Form.DateField
            error={ errors?.closeDate?.message }
            label="Books Close Date"
            placeholder="Select Date"
            { ...dateController.field }
          />

          <Form.TextAreaField
            error={ errors?.notes?.message }
            label="Notes"
            placeholder="Write a note about the books close"
            style={ { height: '120px', resize: 'none' } }
            { ...notesController.field }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="cancel-link"
            disabled={ loading }
            variant="link"
            onClick={ onCancel }
          >
            Cancel
          </Button>
          <Button
            disabled={ loading }
            type="submit"
            variant="primary"
          >
            {loading ? 'in progress...' : 'Mark Books As Closed'}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

const MemoizedMarkBookAsClosedModal = React.memo(MarkBookAsClosedModal);
const useMarkBookAsClosedModal = makeUseModal<typeof MemoizedMarkBookAsClosedModal, TOpenValue>(MemoizedMarkBookAsClosedModal);
export {
  useMarkBookAsClosedModal,
  MemoizedMarkBookAsClosedModal,
};
