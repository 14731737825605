import React, { useCallback, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { useCreateUserAccount } from '@src/hooks/queries/signup';
import { getCurrentAdvisor, getCurrentTenant, signIn } from '@src/requests/signup';
import {
  getDeviceUUID,
  getDeviceName,
  DEVICE_COOKIE_NAME,
  setCookie,
  getCookie,
} from '@src/utils/services';

import { ErrorNotification } from '@src/components/ui/notification';
import Spinner from '@src/components/ui/spinner';
import Form from '@src/components/ui_v2/form';

import { ONBOARDING_PAGE_KEYS } from '../constant';
import OnboardingForm from '../layout/onboarding_form';
import { useOnboarding } from '../provider';
import { ISetPasswordFormValues, setPasswordValidation } from '../schema';

import styles from '../styles.module.scss';

interface ISetPasswordFormProps {
  formId: string;
  userType: string;
}

const SetPasswordForm = ({ formId, userType }: ISetPasswordFormProps) => {
  const { email, emailAccessToken, fullName, setPage, setEmailAccessToken } = useOnboarding();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [signInError, setSignInError] = useState<string>('');
  const {
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<ISetPasswordFormValues>({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: yupResolver(setPasswordValidation),
  });

  const { mutate: createUserAccount, isError: isCreateUserAccountError } = useCreateUserAccount();

  const onSubmit = useCallback(
    (data: ISetPasswordFormValues) => {
      setIsLoading(true);
      createUserAccount(
        {
          user: {
            name: fullName,
            password: data.password,
            passwordConfirmation: data.confirmPassword,
            onboardingType: userType,
          },
          emailAccessToken,
        },
        {
          onSuccess: () => {
            const deviceName = getDeviceName();
            let deviceUuid = null;

            if (!getCookie(DEVICE_COOKIE_NAME)) {
              deviceUuid = getDeviceUUID();
              setCookie(DEVICE_COOKIE_NAME, deviceUuid, window.configData.device_expiry_days);
            } else {
              deviceUuid = getCookie(DEVICE_COOKIE_NAME);
            }

            signIn({
              grantType: 'password',
              email,
              password: data.password,
              deviceUuid,
              deviceName,
              clientId: window.configData.web_app_client_id,
              clientSecret: window.configData.web_app_client_secret,
            })
              .then((response) => {
                window.localStorage.setItem('oauth_access_token', response.accessToken);
                window.localStorage.setItem('oauth_refresh_token', response.refreshToken);

                getCurrentTenant()
                  .then((tenant) => {
                    window.Docyt.appLogoUrl = tenant.logUrl;
                    getCurrentAdvisor()
                      .then((rs) => {
                        window.Docyt.currentAdvisor.updateSelf(rs);
                        window.Docyt.setupDocytFayeNotification();

                        if (
                          typeof window.mixpanel.identify === 'function'
                          || window.configData.env !== 'development'
                        ) {
                          window.mixpanel.identify(rs.id);
                          window.mixpanel.track('Signin from Web', { user_id: rs.id });
                        }

                        window.Docyt.initializePendo();
                        Backbone.history.navigate('/', { trigger: true });
                      })
                      .catch((e) => {
                        setSignInError(e.message);
                        setIsLoading(false);
                      });
                  })
                  .catch((e) => {
                    setSignInError(e.message);
                    setIsLoading(false);
                  });
              })
              .catch((e) => {
                setSignInError(e.message);
                setIsLoading(false);
              });
          },
          onError: (e) => {
            setSignInError(e.message);
            setIsLoading(false);
          },
        }
      );
    },
    [createUserAccount, email, emailAccessToken, fullName, userType]
  );

  const handleGoBack = useCallback(() => {
    setPage(ONBOARDING_PAGE_KEYS.SIGN_UP);
    setEmailAccessToken('');
  }, [setPage, setEmailAccessToken]);

  return (
    <>
      {(isCreateUserAccountError || signInError) && <ErrorNotification message={ signInError } />}
      {isLoading && <Spinner />}
      <OnboardingForm
        backButtonLabel="Go Back"
        formId={ formId }
        handleSubmit={ handleSubmit }
        nextButtonLabel="Next"
        onClickBackButton={ handleGoBack }
        onSubmit={ onSubmit }
      > 
        <div className="display-flex p-b-20 font-16 justify-content-center">Set your password</div>
        <div className={ styles['padding-wrapper'] }>
          <span className={ styles['form-label'] }>Password</span>
          <div className={ styles['form-field-layout'] }>
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-value'] }
              error={ errors.password?.message }
              label="Password"
              placeholder="Password"
              prefixIcon={ <span className="sign-up-key-icon icon-password-key-icon font-20" /> }
              type="password"
              { ...register('password') }
            />
            <Form.TextField
              hiddenLabel
              hideClear
              className={ styles['form-value'] }
              error={ errors.confirmPassword?.message }
              label="Re-enter Password"
              placeholder="Re-enter Password"
              prefixIcon={ <span className="sign-up-key-icon icon-password-key-icon font-20" /> }
              type="password"
              { ...register('confirmPassword') }
            />
          </div>
          <p className={ styles['accountant-onboarding-note'] }>
            By continuing with your email id, you accept
            {' '}
            <a href="https://www.docyt.com/terms/" rel="noreferrer" target="_blank">
              Docyt&apos;s Terms
            </a>
            {' '}
            &
            {' '}
            <a href="https://www.docyt.com/privacy/" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
            .
          </p>
        </div>
      </OnboardingForm>
    </>
  );
};

export default SetPasswordForm;