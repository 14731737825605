module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class=\'modal fade\' style=\'display: flex; align-items: center;\'>\n  <div class=\'modal-dialog\'>\n    <div class=\'modal-content\'>\n      <div class="modal-header">\n        <h1 class="font-bold m-l-10 modal-title">\n          <p class="display-inline-flex font-26 m-b-0 m-l-5 m-t-7 vertical-align-top">\n            Extract Items using Docyt AI\n          </p>\n        </h1>\n      </div>\n      <div class="modal-body">\n        <p class="font-16 m-b-2">\n          Before you continue, would you like to automatically extract items using Docyt AI for this vendor every time? <br />\n          You can turn this off anytime through the vendor profile.\n        </p>\n      </div>\n      <div class=\'modal-footer\'>\n        <button type=\'button\' class="btn btn-blue ladda-button extract-line-items-js" tabindex="10" data-color="blue" data-style="expand-right">\n            <span class="ladda-label">Always Extract</span>\n        </button>\n        <a class=\'cancel-link cancel pointer font-semi-bold\'>Cancel</a>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div> ');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}