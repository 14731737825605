import { TUUID } from '@src/types/common';
import { camelizeKeys, underscoreKeys } from '@src/utils/transform_keys';

import {
  IMessageSchema,
  IChatThreadSchema,
  ICreateSessionTokenResponse,
  IGlobalMessageHistoryResponse,
  IMessageHistoryParams,
  IThreadListParams,
  IGlobalThreadListResponse,
  ICreateChatThreadParams,
} from '@src/components/ai_chatbot/types';

import { apiGet, apiPost } from '../helpers';

const getListOfThreads = (
  params: IThreadListParams,
): Promise<IGlobalThreadListResponse> => {
  return apiGet(
    '/gary/api/v1/gary/gary_bot/chat_threads',
    underscoreKeys(params),
  ).then((result) => {
    const cdata = <any>camelizeKeys(result);
    return {
      meta: {
        totalCount: cdata.totalChatThread,
      },
      collection: cdata.chatThreads as IChatThreadSchema[],
    };
  });
};

const getChatThreadById = (chatThreadId: TUUID): Promise<IChatThreadSchema> => {
  return apiGet(`/gary/api/v1/gary/gary_bot/chat_threads/${chatThreadId}`).then(
    (result) => {
      return camelizeKeys(result) as IChatThreadSchema;
    },
  );
};

const getMessageHistory = (
  params: IMessageHistoryParams,
): Promise<IGlobalMessageHistoryResponse> => {
  return apiGet(
    '/gary/api/v1/gary/gary_bot/chat_thread_messages',
    underscoreKeys(params),
  ).then((result) => {
    const cdata = <any>camelizeKeys(result);
    return {
      meta: {
        totalCount: cdata.totalMessages,
      },
      collection: cdata.messagesList as IMessageSchema[],
    };
  });
};

const createSessionToken = (): Promise<ICreateSessionTokenResponse> => {
  return apiPost('/gary/api/v1/gary/gary_bot/session_tokens', {}).then(
    (result) => {
      return camelizeKeys(result) as ICreateSessionTokenResponse;
    },
  );
};

const createChatThread = (
  params: ICreateChatThreadParams,
): Promise<IChatThreadSchema> => {
  return apiPost(
    '/gary/api/v1/gary/gary_bot/chat_threads',
    underscoreKeys(params),
  ).then((result) => {
    return camelizeKeys(result) as IChatThreadSchema;
  });
};

export {
  getListOfThreads,
  getMessageHistory,
  getChatThreadById,
  createSessionToken,
  createChatThread,
};
