import React from 'react';

import { useRecoilValue } from 'recoil';

import { IWidget } from '@src/types/dashboards';
import { currencyLocaleFormatter } from '@src/utils/currency';

import { DefaultTargets } from '@src/components/business_dashboards/edit_targets/default_targets';
import Table from '@src/components/ui_v2/table';

import { toggleTargetsState } from '../../atoms';
import { numberFormatterMaker } from '../charts/chart_options';

import styles from './styles.module.scss';

interface ITableRowProps {
  widget: IWidget;
  targets: Record<string, number>;
  standardCategoryId: number;
}

const TableRow = ({ widget, targets, standardCategoryId }: ITableRowProps): JSX.Element => {
  const getDefaultTarget = (identifier: string) => {
    const defaultTarget = DefaultTargets[standardCategoryId.toString()];
    if (!defaultTarget) return 0;

    return defaultTarget[identifier]?.value || 0;
  };

  const showTargets = useRecoilValue(toggleTargetsState);

  const target = showTargets ? (targets[widget.identifier] || getDefaultTarget(widget.identifier)) : 0;

  const monthlyData = widget.dataSets?.[0]?.values || [];
  const unit = widget.dataSets?.[0]?.unit;

  const formatValue = (value: number) => {
    if (unit === window.Docyt.Common.Constants.DASHBOARD_DATASET_UNIT.DOLLAR) {
      return currencyLocaleFormatter(value);
    }
    const formatter = numberFormatterMaker(unit);
    return formatter(value);
  };

  const cellData = (data: { value: number, id: string }) => {
    const formattedValue = formatValue(data.value);
    const formattedTarget = formatValue(target);
    const changeValuePercentage = target !== 0 ? ((data.value - target) / target) * 100 : 0;
    const changeClass = changeValuePercentage > 0 ? 'positive-change' : 'negative-change';
    const changeValue = changeValuePercentage !== 0 ? `(${changeValuePercentage > 0 ? '+' : ''}${changeValuePercentage.toFixed(2)}%)` : '';
    return { formattedValue, formattedTarget, changeValue, changeClass, changeValuePercentage };
  };

  const tooltipContent = (data: { value: number, id: string }) => {
    const { formattedValue, formattedTarget, changeValuePercentage } = cellData(data);
    return (
      <div>
        <div>
          Actual:
          {' '}
          {formattedValue}
        </div>
        {
          showTargets && (
            <div>
              Target:
              {' '}
              {formattedTarget}
            </div>
          )
        }
        {
          showTargets && (
            <div>
              {Math.round(target) !== 0 ? ` ${Math.abs(changeValuePercentage).toFixed(2)}% ${changeValuePercentage > 0 ? 'above' : 'below'} target` : ''}
            </div>
          )
        }
      </div>
    );
  };

  const renderCell = (data: { value: number, id: string }) => {
    const { formattedValue, changeValue, changeClass } = cellData(data);
    return (
      <div className={ styles['value-container'] }>
        <span>
          {formattedValue}
          {showTargets && changeValue && Math.round(target) !== 0 && <span className={ styles[changeClass] }>{changeValue}</span>}
        </span>
      </div>
    );
  };

  return (
    <Table.Row>
      <Table.TextCell className="line-item-cell">
        {widget.name}
      </Table.TextCell>
      {monthlyData.map((data) => (
        <Table.TextCell key={ data.id } className={ styles['value-cell'] } tooltip={ tooltipContent(data) }>
          {renderCell(data)}
        </Table.TextCell>
      ))}
    </Table.Row>
  );
};

export default TableRow;
