import React from 'react';

import { TID } from '@src/types/common';
import { IIssuesProps } from '@src/types/common_operational_dashboard';

import { DisconnectedBankAccountsTooltipData, DuplicateVendorsTooltipData,
  GeneralLedgerDisconnectedTooltipData, GeneralLedgerConnectionIssuesTooltipData,
  RevenueIntegrationsTooltipData, CoaSyncErrorsData } from '@src/components/operational_dashboard/helper';
import { isSetupStatusComplete } from '@src/components/operational_dashboard/helper/functional';
import { ICommonProps } from '@src/components/operational_dashboard/types';

import HoverTooltipWrapper from './hover_tooltip_wrapper';
import NumberBox from './number_box';
import CorporateCardsTooltip from '../tooltips/corporate_cards_tooltip';

import styles from './style.module.scss';

interface IIssuesCellProps extends ICommonProps {
    businessId?: TID;
    issuesData?: IIssuesProps;
}
const IssueCell = ({
  issuesData,
  setupStatus,
  businessId,
  preferences,
}: IIssuesCellProps) => {
  if (isSetupStatusComplete(setupStatus)) {
    return <h2 className={ styles['setup-heading'] }>Complete Setup</h2>;
  }
  const disconnectedGeneralLedger = issuesData?.disconnectedGeneralLedger ?? false;
  const disconnectedRevenueIntegration = issuesData?.disconnectedRevenueIntegration ?? 0;
  const disconnectedBankAccounts = issuesData?.disconnectedBankAccounts ?? 0;
  const generalLedgerConnectionIssues = issuesData?.generalLedgerConnectionIssues ?? 0;
  const duplicateVendors = issuesData?.duplicateVendors ?? 0;
  const chartOfAccountSyncErrors = issuesData?.chartOfAccountSyncErrors ?? 0;

  return (
    <div className={ `${styles['business-issue-container']} ${styles['expense-header-container']}` }>
      <div className={ styles['issue-icon-list'] }>
        {preferences?.issues?.disconnectedLedger && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ (
              disconnectedGeneralLedger ? (
                <CorporateCardsTooltip
                  businessId={ businessId }
                  item={ GeneralLedgerDisconnectedTooltipData }
                />
              ) : null
              ) }
            >
              <NumberBox value={ { value: disconnectedGeneralLedger, type: 'indicator' } } />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.disconnectedRevenue && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ (
                  disconnectedRevenueIntegration
                    ? (
                      <CorporateCardsTooltip
                        businessId={ businessId }
                        item={ RevenueIntegrationsTooltipData }
                        value={ disconnectedRevenueIntegration }
                      />
                    ) : null
                ) }
            >
              <NumberBox
                isDanger
                value={ { value: disconnectedRevenueIntegration, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.disconnectedBank && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ (
                  disconnectedBankAccounts
                  && (
                  <CorporateCardsTooltip
                    businessId={ businessId }
                    item={ DisconnectedBankAccountsTooltipData }
                    value={ disconnectedBankAccounts }
                  />
                  )
                ) }
            >
              <NumberBox
                isDanger
                value={ { value: disconnectedBankAccounts, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.ledgerIssues && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper
              content={ (
              generalLedgerConnectionIssues && (
              <CorporateCardsTooltip
                businessId={ businessId }
                item={ GeneralLedgerConnectionIssuesTooltipData }
                value={ generalLedgerConnectionIssues }
              />
              )
            ) }
            >
              <NumberBox
                isWarning
                value={ { value: generalLedgerConnectionIssues, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}

        {preferences?.issues?.duplicateVendor && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper content={ (
           duplicateVendors && (
           <CorporateCardsTooltip
             businessId={ businessId }
             item={ DuplicateVendorsTooltipData }
             value={ duplicateVendors }
           />
           )
            ) }
            >
              <NumberBox
                isWarning
                value={ { value: duplicateVendors, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
        {preferences?.issues?.chartOfAccountSyncErrors && (
          <div className={ styles['header-item'] }>
            <HoverTooltipWrapper content={ (
              chartOfAccountSyncErrors && (
              <CorporateCardsTooltip
                businessId={ businessId }
                item={ CoaSyncErrorsData }
                value={ chartOfAccountSyncErrors }
              />
              )
                ) }
            >
              <NumberBox
                isWarning
                value={ { value: chartOfAccountSyncErrors, type: 'number' } }
              />
            </HoverTooltipWrapper>
          </div>
        )}
      </div>
    </div>
  );
};
export default IssueCell;
