import React, { memo, useEffect, useState } from 'react';

import Markdown from 'markdown-to-jsx';
import { useRecoilValue } from 'recoil';

import { chatMessageLoaderAtom } from '@src/components/ai_chatbot/atoms';
import ChatLoadingItem from '@src/components/ai_chatbot/components/chat_loading_item';
import { IMessageSchema } from '@src/components/ai_chatbot/types';
import { DocytBotAi } from '@src/components/utils/icomoon';

import styles from '@src/components/ai_chatbot/styles.module.scss';

interface IChatMessageItemProps extends IMessageSchema {}

const ChatMessageItem = ({
  question,
  answer,
  channelAnswerMessageId,
  messageSignature,
}: IChatMessageItemProps) => {
  const chatMessageLoader = useRecoilValue(chatMessageLoaderAtom);
  const [isLoading, setIsLoading] = useState(false);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    window.open((event.currentTarget as HTMLAnchorElement).href, '_blank');
  };

  useEffect(() => {
    setIsLoading(chatMessageLoader.includes(messageSignature as string));
  }, [chatMessageLoader, messageSignature]);

  return (
    <div className={ styles['chat-message'] }>
      <div className={ styles['message-question-container'] }>
        <div className={ styles['chat-message-question'] }>{question}</div>
      </div>
      <div className={ styles['chat-message-answer-container'] }>
        <div className={ styles['chat-message-answer-logo'] }>
          <DocytBotAi fontSize={ 25 } />
          {' '}
        </div>
        {!answer && isLoading && (
          <div className={ styles['loading-content'] }>
            <span>Working on it...</span>
            <ChatLoadingItem />
          </div>

        )}
        {answer && (
          <Markdown
            className={ styles['chat-message-answer'] }
            options={ {
              overrides: {
                a: {
                  component: 'a',
                  props:     {
                    target:  '_blank',
                    onClick: handleLinkClick,
                  },
                },
              },
            } }
          >
            {answer}
          </Markdown>
        )}
        {!answer && !isLoading && !channelAnswerMessageId && (
          <div className={ styles['chat-message-answer-no-answer'] }>
            No answer found
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ChatMessageItem);
