import { useMutation, useQuery, useQueryClient } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { TID } from '@src/types/common';
import { IDocumentNote } from '@src/types/document_note';
import { IDocument } from '@src/types/documents';
import { underscoreKeys, camelizeKeys } from '@src/utils/transform_keys';

import { apiDelete, apiGet, apiPost, apiPut } from '../helpers';


interface ICreateDocumentNoteParams {
  documentId: TID;
  note: string;
}

interface IDeleteDocumentNoteParams {
  documentId: TID;
  noteId: TID;
}

interface IGetDocumentNotesParams {
  documentId: TID;
}

interface IGetDocumentNotesResponse {
  collection: IDocumentNote[],
}

interface IGetContactsParams {
  search: string;
  perPage: number;
  includeGoogleContacts: boolean;
  allContacts: boolean;
}

interface IContact {
  id: TID;
  name: string;
  email: string;
  phone: string;
  imageUrl: string;
  isExternal: boolean;
}

interface IDocumentNoteResponse {
  documentNote: {
    id: TID;
    note: string;
  }
}

const createDocumentNote = (params: ICreateDocumentNoteParams): Promise<IDocumentNoteResponse> => {
  return apiPost(`/api/v1/documents/${params.documentId}/notes`, underscoreKeys(params))
    .then((data) => camelizeKeys(data) as IDocumentNoteResponse);
};

const useCreateDocumentNote = () => {
  return useMutation<IDocumentNoteResponse, Error, { documentId: TID, note: string }>(createDocumentNote);
};

const deleteDocumentNote = (params: IDeleteDocumentNoteParams): Promise<void> => {
  return apiDelete(`/api/v1/documents/${params.documentId}/notes/${params.noteId}`);
};

const useDeleteDocumentNote = () => {
  const queryClient = useQueryClient();
  return useMutation<void, Error, { documentId: TID, noteId: TID }>(deleteDocumentNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.documentNotes);
    },
  });
};

const getDocumentNotes = (params: IGetDocumentNotesParams): Promise<IGetDocumentNotesResponse> => {
  return apiGet(`/api/v1/documents/${params.documentId}/notes`).then((data) => {
    const cdata = <any>camelizeKeys(data);
    return {
      collection: cdata.documentNotes as IDocumentNote[],
    } as IGetDocumentNotesResponse;
  });
};

const useGetDocumentNotes = (params: IGetDocumentNotesParams, enabled: boolean) => {
  return useQuery<IGetDocumentNotesResponse, Error>(
    [QueryKey.documentNotes, params],
    () => getDocumentNotes(params),
    {
      enabled: !!enabled
    }
  );
};

const getContacts = (params: IGetContactsParams): Promise<IContact[]> => {
  // eslint-disable-next-line max-len
  return apiGet('/api/web/v1/contacts', underscoreKeys(params)).then((data) => camelizeKeys(data.contacts) as IContact[]);
};

const updateCategory = (params: IDocument): Promise<void> => {
  return apiPut(`/api/web/v1/documents/${params.id}/update_category`, underscoreKeys(params));
};

export {
  createDocumentNote,
  useCreateDocumentNote,
  deleteDocumentNote,
  useDeleteDocumentNote,
  getContacts,
  IGetContactsParams,
  updateCategory,
  useGetDocumentNotes,
  IGetDocumentNotesParams,
  IGetDocumentNotesResponse,
};
