import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { QueryKey } from '@src/constants/query_keys';
import { getLatestAuditScans, runAuditScan, closeAuditScan } from '@src/requests/operational_dashboard/audit_scan';
import { TID } from '@src/types/common';
import { AuditScan, ICloseAuditScanParams } from '@src/types/operational_dashboard/audit_scan';

const POLLING_INTERVAL = 10000; // 10 seconds

const useGetLatestAuditScans = (
  businessId: TID | undefined,
  options?: UseQueryOptions<AuditScan, Error>,
  shouldPoll: boolean = true,
) => {
  return useQuery<AuditScan, Error>(
    [QueryKey.auditScans, businessId],
    () => getLatestAuditScans({ businessId }),
    {
      refetchInterval:             shouldPoll ? POLLING_INTERVAL : false,
      refetchOnWindowFocus:        false,
      refetchIntervalInBackground: false,
      staleTime:                   60000, // Data is fresh for 1 minute
      ...options,
    },
  );
};

const useRunAuditScan = () => {
  const queryClient = useQueryClient();
  return useMutation<{}, Error & AxiosError, { businessId: TID }>(
    runAuditScan,
    {
      onSuccess: (_, { businessId }) => {
        queryClient.invalidateQueries([QueryKey.auditScans, businessId]);
      },
    },
  );
};

const useCloseAuditScan = () => {
  const queryClient = useQueryClient();

  return useMutation((data: ICloseAuditScanParams & { businessId: TID }) => closeAuditScan(data), {
    onSuccess: (_, { businessId }) => {
      queryClient.invalidateQueries([QueryKey.auditScans, businessId]);
    },
  });
};

export {
  useGetLatestAuditScans,
  useRunAuditScan,
  useCloseAuditScan,
};
