import React from 'react';

import classNames from 'classnames';
import { SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';

import Button from '@src/components/ui_v2/buttons/button';
import Form from '@src/components/ui_v2/form';

import styles from '../styles.module.scss';

interface IOnboardingFormProps {
  formId: string;
  className?: string;
  backButtonLabel: string;
  nextButtonLabel: string;
  onClickBackButton?: () => void;
  onSubmit?: SubmitHandler<any>,
  handleSubmit?: UseFormHandleSubmit<any>,
  icon?: React.ReactNode;
  isSubmit?: boolean;
  children: React.ReactNode;

}

const OnboardingForm = ({ 
  formId,
  className,
  backButtonLabel,
  nextButtonLabel,
  onClickBackButton,
  onSubmit,
  handleSubmit,
  icon,
  isSubmit,
  children
}: IOnboardingFormProps) => {
  const classes = classNames(styles['onboarding-form'], className);

  const handleSubmitForm = handleSubmit && onSubmit ? handleSubmit(onSubmit) : () => {};

  return (
    <Form
      className={ classes }
      id={ formId }
      onSubmit={ handleSubmitForm }
    >
      <div className={ styles['onboarding-body'] }>
        { children }
      </div>
      <div className={ styles['onboarding-footer'] }>
        <Button
          variant="link"
          onClick={ onClickBackButton }
        >
          { backButtonLabel }
        </Button>
        <Button
          disabled={ isSubmit }
          type="submit"
          variant="primary"
        >
          { isSubmit ? icon : nextButtonLabel }
        </Button>
      </div>

    </Form>
  );
};

export default OnboardingForm;