module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      if (Docyt.currentAdvisor.isBookgptAccountantUser() || Docyt.currentAdvisor.isBookgptBusinessUser()) {
        __out.push('\n  <div class="client-management">\n    <div class="header-section">\n      <div class="left-section">\n        <h3 class="service-main-title">My Clients</h3>\n        <div class="tabs-container">\n          <button class="tab-button active-clients-js ');
        __out.push(__sanitize(this.is_active_tab ? 'active' : void 0));
        __out.push('">Active</button>\n          <button class="tab-button archived-clients-js ');
        __out.push(__sanitize(!this.is_active_tab ? 'active' : void 0));
        __out.push('">Archived</button>\n        </div>\n      </div>\n      <div class="right-section">\n        <button class="btn-primary btn-add-client">Add Client</button>\n      </div>\n    </div>\n\n    <div class="client-table-wrapper">\n      <table class="client-table">\n        <tbody>\n          <!-- Client rows will be rendered here by the ClientListView -->\n        </tbody>\n      </table>\n    </div>\n  </div>\n');
      } else {
        __out.push('\n  <div class="header-wrapper">\n    <h2 class="service-main-title"> My Clients </h2>\n    <div class="tab-wrapper">\n      <a class="active-clients-js ');
        __out.push(__sanitize(this.is_active_tab ? 'active' : ''));
        __out.push('">ACTIVE CLIENTS</a>\n      <a class="archived-clients-js ');
        __out.push(__sanitize(!this.is_active_tab ? 'active' : ''));
        __out.push('">ARCHIVED CLIENTS</a>\n    </div>\n  </div>\n\n  <div class="clearfix">\n    <div class="client-filter-container"></div>\n    <div class="client-table-wrapper"></div>\n  </div>\n');
      }
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}