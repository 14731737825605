import React, { useMemo } from 'react';

import { useGetDefaultAccountingFirm } from '@src/hooks/queries/signup';
import { IBusiness } from '@src/types/businesses';

import TeamMemberForm from './form';
import OnboardingPage from '../layout/onboarding_page';

const TEAM_MEMBER_TITLE = 'Add Your Team Members';

const TeamMemberView = () => {
  const query = useGetDefaultAccountingFirm();
  const business = useMemo(() => query?.data as IBusiness || {}, [query]);

  return (
    <OnboardingPage isHideBorder title={ TEAM_MEMBER_TITLE }>
      <TeamMemberForm business={ business } formId="team-member-form" />
    </OnboardingPage>
  );
};

export default React.memo(TeamMemberView);
