import React, { useState, useCallback, useRef } from 'react';

import { startCase } from 'lodash';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { revenueServiceResolveReportPath } from '@src/routes';
import { TID } from '@src/types/common';
import { IRevenueServiceDocument } from '@src/types/revenue_service_documents';

import Text from '@src/components/ui/text';
import { Button } from '@src/components/ui_v2/buttons';
import Tooltip from '@src/components/ui_v2/tooltip';
import { NotAChargebackIcon,
  CheckCircleBold,
  ErrorCircleIcon,
  DollarCircleIcon,
  CancelCircleIcon } from '@src/components/utils/icomoon';

import { useClickOutsideAndEscape } from './hooks';

import styles from './styles.module.scss';

interface IRevenueStatusProps {
  document: IRevenueServiceDocument;
  activeTooltipId: number | null;
  integrationId: TID | null;
  onToggleTooltip: (id: number | null) => void;
}

const RevenueStatus: React.FC<IRevenueStatusProps> = ({
  document: revenueServiceDocument,
  activeTooltipId,
  integrationId,
  onToggleTooltip,
}) => {
  const identifier = parseInt(revenueServiceDocument.startDate.replace(/-/g, ''), 10);
  const isTooltipVisible = activeTooltipId === identifier;
  const popupRef = useRef<HTMLDivElement | null>(null);
  const business = useBusinessContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const defaultText = integrationId ? 'Revenue is missing. Please reset to update the revenue report.'
    : 'Revenue is missing. Please upload the file and update the revenue report.';

  useClickOutsideAndEscape(popupRef, () => onToggleTooltip(null));
  const handleExpandedToggle = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsExpanded((prev) => !prev);
    },
    [],
  );

  const handleShowTooltip = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      onToggleTooltip(identifier);
    },
    [onToggleTooltip, identifier],
  );

  const isLongError = revenueServiceDocument?.runError && revenueServiceDocument?.runError.length > 125;
  let displayedText = isExpanded || !isLongError
    ? revenueServiceDocument?.runError
    : `${revenueServiceDocument?.runError?.substring(0, 125)}...`;

  if ((displayedText === undefined || displayedText === null) && revenueServiceDocument.status === 'missing') {
    displayedText = defaultText;
  }

  const featureQuery = useLDBusinessFeatureQuery(
    business.id,
    window.Docyt.Common.Constants.REVENUE_CENTER_ISSUE_FLAG,
  );

  const handleResolveIssue = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    const href = revenueServiceResolveReportPath(business.id, revenueServiceDocument.revenueSystemId!, identifier);
    const trigger = true;
    Backbone.history.navigate(href, { trigger });
  }, [business.id, revenueServiceDocument.revenueSystemId, identifier]);

  const renderTooltipContent = useCallback(
    (): JSX.Element => {
      const isUnbalanced = revenueServiceDocument.status === 'unbalanced';

      return (
        <div ref={ popupRef } className={ styles['wrapper-content'] }>
          <Button
            className={ styles['close-button'] }
            variant="link"
            onClick={ (e: React.MouseEvent) => handleShowTooltip(e) }
          >
            <NotAChargebackIcon fontSize={ 18 } style={ { color: '#070C2580' } } />
          </Button>
          <div className={ styles['wrapper-label'] }>
            {isUnbalanced ? (
              <ErrorCircleIcon fontSize={ 18 } />
            ) : (
              <CancelCircleIcon fontSize={ 18 } />
            )}
            <Text className={ styles['title-popup'] } fontSize={ 14 }>
              {isUnbalanced ? 'Unbalanced Report' : 'Missing Revenue Data'}
            </Text>
          </div>
          <Text fontSize={ 12 }>
            {displayedText}
            {isLongError && (
              <Button variant="link" onClick={ (e: React.MouseEvent) => handleExpandedToggle(e) }>
                {isExpanded ? 'View less' : 'View more'}
              </Button>
            )}
          </Text>
          {(revenueServiceDocument?.lastUpdatedAt || revenueServiceDocument?.restartRequestedBy)
          && (
            <div className={ styles['wrapper-updated'] }>
              {revenueServiceDocument?.lastUpdatedAt && (
              <Text display="inline-flex">
                Last updated at:
                {' '}
                {revenueServiceDocument?.lastUpdatedAt ? revenueServiceDocument?.lastUpdatedAt : '-'}
              </Text>
              )}
              {revenueServiceDocument?.restartRequestedBy && (

              <Text display="inline-flex">
                Last updated by:
                {' '}
                {revenueServiceDocument?.restartRequestedBy ? revenueServiceDocument?.restartRequestedBy : '-'}
              </Text>
              )}
            </div>
          )}
          {isUnbalanced && featureQuery?.data && (
            <Button className={ styles['resolve-button'] } variant="primary" onClick={ handleResolveIssue }>
              Resolve Issue
            </Button>
          )}
        </div>
      );
    },
    [
      popupRef,
      handleShowTooltip,
      displayedText,
      isLongError,
      isExpanded,
      featureQuery,
      handleExpandedToggle,
      handleResolveIssue,
      revenueServiceDocument],
  );

  const renderStatusWithIcon = useCallback(
    ({ IconComponent, label }: { IconComponent: React.ElementType; label: string }): JSX.Element => (
      <>
        <IconComponent fontSize={ 20 } />
        <Text className={ styles['status-text'] } fontSize={ 12 }>
          {label}
        </Text>
      </>
    ),
    [],
  );

  const renderStatusLabelWithIcon = (): JSX.Element => {
    const statusText = startCase(revenueServiceDocument.status);
    switch (revenueServiceDocument.status) {
      case 'balanced':
        return renderStatusWithIcon({ IconComponent: CheckCircleBold, label: statusText });
      case 'no_revenue_day':
        return renderStatusWithIcon({ IconComponent: DollarCircleIcon, label: statusText });
      case 'unbalanced':
        return (
          <>
            <ErrorCircleIcon fontSize={ 20 } />
            <Button variant="link" onClick={ (e: React.MouseEvent<Element, MouseEvent>) => handleShowTooltip(e) }>
              <Text className={ styles['unbalanced-text'] } fontSize={ 12 }>
                {statusText}
                : View Error
              </Text>
            </Button>
          </>
        );
      case 'missing':
        return (
          <>
            <CancelCircleIcon fontSize={ 20 } />
            <Button variant="link" onClick={ (e: React.MouseEvent<Element, MouseEvent>) => handleShowTooltip(e) }>
              <Text className={ styles['unbalanced-text'] } fontSize={ 12 }>
                {statusText}
                {' '}
                revenue data
              </Text>
            </Button>
          </>
        );
      default: // 'for_review'
        return renderStatusWithIcon({ IconComponent: CancelCircleIcon, label: `${statusText} revenue data` });
    }
  };

  return (
    <Tooltip.Hover
      content={ revenueServiceDocument.status === 'unbalanced' || revenueServiceDocument.status === 'missing'
        ? renderTooltipContent() : null }
      isControl={ revenueServiceDocument.status === 'unbalanced' || revenueServiceDocument.status === 'missing' }
      isVisibleArrow={ false }
      showTooltip={ isTooltipVisible }
      variant="light"
    >
      <div className={ styles['wrapper-label'] }>
        {renderStatusLabelWithIcon()}
      </div>
    </Tooltip.Hover>
  );
};

export default React.memo(RevenueStatus);
