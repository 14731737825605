import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

interface IBQIconProps extends IIcomoonIconProps {
  variant?: 'on' | 'off' | 'white',
}

const QBOIcon = ({
  variant = 'on',
  ...props
}: IBQIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName={ `qbo-${variant}` }
      pathsNumber={ 2 }
    />
  );
};

export default QBOIcon;
