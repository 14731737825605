import React, { useCallback } from 'react';

import { IBusinessMetricValue } from '@src/types/business_metric_value';
import { TDate, TID } from '@src/types/common';
import { IMetric } from '@src/types/metrics';

import NumberInput from '@src/components/ui/form/inputs/number_input';
import Table from '@src/components/ui/table';

import styles from '../../styles.module.scss';

interface IBusinessMetricValuesListItemProps {
  businessMetricValue: IBusinessMetricValue,
  businessMetric: IMetric,
  isEditMode: boolean,
  hasGranularData: boolean,
  businessId: TID,
  onValueChange: (date: TDate, value?: number) => void,
}

const BusinessMetricValuesListItem = ({
  businessMetricValue,
  isEditMode,
  onValueChange,
  hasGranularData,
  businessId,
  businessMetric,
}: IBusinessMetricValuesListItemProps): JSX.Element => {
  const handleValueChange = useCallback((e) => {
    onValueChange(businessMetricValue.date, e);
  }, [businessMetricValue.date, onValueChange]);

  const handleClick = useCallback(() => {
    if (!hasGranularData) return;

    Backbone.history.navigate(`businesses/${businessId}/metrics_service/${businessMetric.id}/values/${businessMetricValue.date}`, { trigger: true });
  }, [hasGranularData, businessId, businessMetric, businessMetricValue]);

  return (
    <Table.Row
      className={ hasGranularData ? 'pointer' : '' }
      onClick={ isEditMode ? () => {} : handleClick }
    >
      <Table.DateCell
        date={ businessMetricValue.date }
        style={ { paddingLeft: '20px' } }
      />
      <Table.Cell
        style={ { paddingLeft: '20px' } }
      >
        { !hasGranularData &&
          (
            <NumberInput
              autoComplete="off"
              className={ styles['item-value-input'] }
              disabled={ !isEditMode }
              style={ isEditMode
                ? { background: 'rgb(0 0 0 / 4%)' }
                : { background: 'transparent', cursor: 'default' } }
              value={ businessMetricValue.value ?? undefined }
              onChange={ handleValueChange }
            />
          )}
        { hasGranularData && businessMetricValue.value }
      </Table.Cell>
    </Table.Row>
  );
};

export default BusinessMetricValuesListItem;
