import React from 'react';

import { settingsPath } from '@src/routes';

import { NavigateBtn } from '@src/components/ai_chatbot';
import { LinkButton } from '@src/components/ui/buttons';
import { SettingsCogIcon } from '@src/components/utils/icomoon';
import LogoIcon from '@src/components/utils/icomoon/logo';

import AvatarDropdown from '../avatar_dropdown/avatar_dropdown';

import styles from './styles.module.scss';

interface IBookGptHeaderContainerProps {
  onOpenEditProfile: () => void;
}

const BookGptHeaderContainer = ({ onOpenEditProfile }: IBookGptHeaderContainerProps) => {
  return (
    <div className={ styles['header-container'] }>
      <div>
        <LogoIcon className={ styles['logo-icon'] } fontSize={ 20 } />
      </div>
      <div>
        <NavigateBtn />
      </div>
      <div className={ styles['header-right-section'] }>
        <div className={ styles['settings-icon'] }>
          <LinkButton href={ settingsPath() } title="">
            <SettingsCogIcon fontSize={ 25 } />
          </LinkButton>
        </div>
        <div className={ styles['avatar-icon'] }>
          <AvatarDropdown onOpenEditProfile={ onOpenEditProfile } />
        </div>
      </div>
    </div>
  );
};

export default BookGptHeaderContainer;
