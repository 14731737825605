import React from 'react';

import { IWidget } from '@src/types/dashboards';

import Table from '@src/components/ui_v2/table';

import Row from './row';

interface ITableBodyProps {
  widgets: IWidget[];
  hideEmptyRows?: boolean;
  targets: Record<string, number>;
  standardCategoryId: number;
}

const TableBody = ({
  widgets,
  hideEmptyRows = false,
  targets,
  standardCategoryId,
}: ITableBodyProps): JSX.Element => {
  const filteredWidgets = hideEmptyRows
    ? widgets.filter((widget) => widget.dataSets?.[0]?.values?.some((data) => data.value !== 0))
    : widgets;

  return (
    <Table.Body>
      {filteredWidgets.map((widget) => (
        <Row key={ widget.id } standardCategoryId={ standardCategoryId } targets={ targets } widget={ widget } />
      ))}
    </Table.Body>
  );
};

export default TableBody;
