import * as yup from 'yup';

export const addClientValidation = yup.object().shape({
  standardCategory: yup.string().required('Category is required'),
  legalName:        yup.string().required('Legal name is required'),
  displayName:      yup.string().required('Display name is required'),
});

export interface IAddClientFormValues {
  standardCategory: number;
  legalName: string;
  displayName: string;
  serviceProviderBusinessId: number;
}
