import React, { useCallback, useMemo } from 'react';

import toastr from '@lib/toastr';
import { useDeleteMatchedDocument, useDownloadDocument } from '@src/hooks/queries/documents';
import { IDocumentRelative } from '@src/types/document_relative';
import { createAndDownloadBlobFile } from '@src/utils/download_file';

import Table from '@src/components/ui/table';
import { TrashcanIcon } from '@src/components/utils/icomoon';

import { useDeleteDocumentModal } from './delete_document';

interface IMatchedDocumentListItemProps {
  document: IDocumentRelative,
  isExpenseType: boolean
}

const MatchedDocumentListItem = ({
  document,
  isExpenseType
}: IMatchedDocumentListItemProps): JSX.Element => {
  const downloadDocument = useDownloadDocument();
  const deleteDocument = useDeleteMatchedDocument();
  const { mutate } = downloadDocument;

  const handleDownload = useCallback((type: 'original' | 'extracted') => {
    toastr.success(
      window.Docyt.Common.Constants.Messages.DOCUMENT_DOWNLOADING,
      'Download in progress',
    );
    mutate(
      { documentId: document.id, type },
      { onSuccess: (data) => createAndDownloadBlobFile(data.fileData, data.fileName) },
    );
  }, [mutate, document.id]);


  const displayAmount = useMemo(() => {
    const standardDocumentId = document.standardDocumentId;
    if (standardDocumentId && [
      window.configData.receipt_box_receipt_id,
      window.configData.account_payable_invoice_id,
    ].includes(standardDocumentId)) {
      return null;
    }
    return document.amount ?? null;
  }, [document.standardDocumentId, document.amount]);

  const handleDeleteDocument = useCallback(() => {
    deleteDocument.mutate({
      documentId: document.id,
      relatedDocumentId: Number(document.relatedDocumentId),
    });
  }, [deleteDocument, document.id, document.relatedDocumentId]);

  const deleteDocumentModal = useDeleteDocumentModal({ onDone: handleDeleteDocument });

  const renderAmountOrTrashCell = useCallback(() => {
    if (isExpenseType) {
      if (displayAmount) {
        return <Table.AmountCell amount={ displayAmount } textAlign="center" />;
      }
      return <Table.Cell textAlign="center">--</Table.Cell>;
    }
    
    return (
      <Table.Cell pointer textAlign="center">
        <TrashcanIcon fontSize={ 16 } onClick={ deleteDocumentModal.open } />
      </Table.Cell>
    );
  }, [isExpenseType, displayAmount, deleteDocumentModal]);

  return (
    <>
      <Table.Row>
        <Table.Cell
          pointer
          className="in-blue-900"
          onClick={ () => handleDownload('original') }
        >
          { document.docytId }
        </Table.Cell>
        <Table.Cell textAlign="center">
          { document.name || document.originalFileName || document.originalFileKey || document.computedFileName }
        </Table.Cell>
        { renderAmountOrTrashCell() }
      </Table.Row>
      <deleteDocumentModal.Component
        { ...deleteDocumentModal.props }
      />
    </>
  );
};

export default MatchedDocumentListItem;
