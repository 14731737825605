import React from 'react';

import Icomoon, { IIcomoonIconProps } from './icomoon';

const DocytLogoIcon = ({
  ...props
}: IIcomoonIconProps) => {
  return (
    <Icomoon
      { ...props }
      iconName="docyt-bot-ai-new"
      pathsNumber={ 10 }
    />
  );
};

export default DocytLogoIcon;
