import React from 'react';

import { Routes, Route } from 'react-router-dom';

import CategorizedTransactions from './categorized_transactions';

const CategorizedTransactionsRouter = (): JSX.Element | null => {
  return (
    <Routes>
      <Route path="/businesses/:businessId/reconciliation_center/categorized_transactions">
        <Route
          element={ <CategorizedTransactions /> }
          path=":transactionType"
        />
        <Route index element={ <CategorizedTransactions /> } />
      </Route>
    </Routes>
  );
};

export default React.memo(CategorizedTransactionsRouter);
