import { TID } from '@src/types/common';

import { apiPut } from './helpers';

interface IActivateBookGPTParams {
  business_id: TID;
}

const activateBookGPT = (
  params: IActivateBookGPTParams,
): Promise<void> => {
  return apiPut(
    `/api/v1/businesses/${params.business_id}/activate_booksgpt`,
    params,
  );
};

export {
  IActivateBookGPTParams,
  activateBookGPT,
};
