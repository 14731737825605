import React from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import styles from './styles.module.scss';

const SignOutAction: React.FC = () => {
  const { currentAdvisor } = window.Docyt;

  const handleSignOut = () => {
    currentAdvisor.signOut();
  };

  return (
    <div className={ styles['action-item'] }>
      <ActionsDropdown.Action
        name="Sign Out"
        onClick={ handleSignOut }
      />
    </div>
  );
};

export default SignOutAction;
