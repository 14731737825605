import QueryStatus from "@src/components/utils/query_status";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { useCreateSessionToken } from "@src/hooks/queries/ai_chat/ai_chatbot";

// Separate component to use hooks after QueryClientProvider is available
const SendbirdWrapper = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const userId = window.Docyt.currentAdvisor.get('id');
    const sessionTokenQuery = useCreateSessionToken();
  
    if (!sessionTokenQuery.data?.sessionToken) {
      return <QueryStatus query={sessionTokenQuery} />;
    }
  
    return (
      <SendbirdProvider
        accessToken={sessionTokenQuery.data?.sessionToken}
        appId={window.configData.ai_chatbot.app_id}
        uikitOptions={{
          groupChannel: {
            enableTypingIndicator: true,
          },
        }}
        userId={String(userId)}
      >
        {children}
      </SendbirdProvider>
    );
  };

  export default SendbirdWrapper;