import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useRevenueServiceContext } from '@src/hooks/contexts/revenue_service_context';
import { revenueServiceReportPath } from '@src/routes';
import { TID } from '@src/types/common';
import {
  IRevenueServiceDocument,
  TRevenueServiceDocumentsSortColumn,
} from '@src/types/revenue_service_documents';
import { IRevenueService } from '@src/types/revenue_services';
import { IRevenueSystem } from '@src/types/revenue_systems';
import { ISorting } from '@src/types/sorting';

import QBOStatusIcon from '@src/components/common_v2/qbo_status_icon';
import { AppLink } from '@src/components/ui_v2/buttons';
import CollectionTable from '@src/components/ui_v2/collection_table';
import ChatCounter from '@src/components/utils/chat_counter';

import { useRevenueSystemContext } from '../revenue_system_provider';
import { NO_REPORT_ID, documentDisplayValuesSumForSection } from '../utils';
import Actions from './actions';
import RevenueStatus from './actions/revenue_status';
import Filter from './filter';
import { IRSDCollection } from './hooks';

interface IRevenueCaptureTableProps {
  queries: IRSDCollection['queries'],
  records: IRevenueServiceDocument[],
  sorting: ISorting<TRevenueServiceDocumentsSortColumn>,
  integrationId: TID | null,
}

const revenueServiceStatus = (
  activeTooltipId: number | null,
  document: IRevenueServiceDocument,
  integrationId: TID | null,
  handleToggleTooltip: (id: number | null) => void,
) => {
  return (
    <RevenueStatus
      activeTooltipId={ activeTooltipId }
      document={ document }
      integrationId={ integrationId }
      onToggleTooltip={ handleToggleTooltip }
    />
  );
};

const taxValue = (service: IRevenueService, document: IRevenueServiceDocument): number | null => {
  return documentDisplayValuesSumForSection({ service, document, section: 'taxes' });
};

const ppfValue = (service: IRevenueService, document: IRevenueServiceDocument): number | null => {
  return documentDisplayValuesSumForSection({ service, document, section: 'payment_processors_adjustment' });
};

const documentChat = (service: IRevenueService, document: IRevenueServiceDocument) => {
  return (
    <>
      { document.id === NO_REPORT_ID && (
        <ChatCounter messagesCount={ document.unreadMessagesCount } />
      )}
      { document.id !== NO_REPORT_ID && (
        <AppLink href={ revenueServiceReportPath(service.businessId, document.id, 'chat') }>
          <ChatCounter messagesCount={ document.unreadMessagesCount } />
        </AppLink>
      )}
    </>
  );
};

const qboStatus = (document: IRevenueServiceDocument) => {
  const syncStatus = document.document?.syncStatus;
  return <QBOStatusIcon fontSize={ 18 } model={ { qboSyncStatus: syncStatus || 'disabled', qboError: document.qboLink?.qboError } } />;
};

const actions = (report: IRevenueServiceDocument, revenueSystem: IRevenueSystem) => {
  return <Actions report={ report } revenueSystem={ revenueSystem } />;
};

const isNoRevenueDay = (report: IRevenueServiceDocument) => {
  return report.status === 'no_revenue_day';
};

const RevenueCaptureTable = ({
  queries,
  records,
  sorting,
  integrationId,
}: IRevenueCaptureTableProps) => {
  const [activeTooltipId, setActiveTooltipId] = useState<number | null>(null);
  const revenueService = useRevenueServiceContext();
  const revenueSystem = useRevenueSystemContext();

  const handleToggleTooltip = (id: number | null) => {
    setActiveTooltipId((prev) => ((prev === id || null) ? null : id));
  };

  const handleIsRowClickable = useCallback((report: IRevenueServiceDocument) => {
    return report.id !== NO_REPORT_ID;
  }, []);

  const handleRowClick = useCallback((report: IRevenueServiceDocument) => {
    if (report.id === NO_REPORT_ID) return;

    Backbone.history.navigate(
      revenueServiceReportPath(revenueService.businessId, report.id),
      { trigger: true },
    );
  }, [revenueService.businessId]);

  const runStatusTooltip = (document: IRevenueServiceDocument) => {
    if (!revenueSystem.integrationId) {
      return (
        <>
          Business on Manual Setup
          <br />
          Status not available
        </>
      );
    }
    if (!document.runStatus) return null;
    return (
      <>
        {`Download Status: ${document.runStatus}`}
        <br />
        {document?.runError}
      </>
    );
  };

  return (
    <CollectionTable<
        IRevenueServiceDocument,
        TRevenueServiceDocumentsSortColumn
      >
      filter={ <Filter /> }
      height="100%"
      idField="rowKey"
      isRowClickable={ handleIsRowClickable }
      isRowInactive={ isNoRevenueDay }
      noDataMessage="No reports found"
      query={ queries }
      records={ records }
      sorting={ sorting }
      onRowClick={ handleRowClick }
    >
      <CollectionTable.DateColumn<
          IRevenueServiceDocument,
          TRevenueServiceDocumentsSortColumn
        >
        name="end_date"
        sortColumn="end_date"
        title="Date"
        value={ (document) => document.endDate }
        width="13%"
      />
      <CollectionTable.AmountColumn<
          IRevenueServiceDocument,
          TRevenueServiceDocumentsSortColumn
        >
        name="total_revenue"
        sortColumn="display_total_revenue"
        title="Revenue"
        value={ (document) => document.displayTotalRevenue }
        width="20%"
      />
      <CollectionTable.AmountColumn<IRevenueServiceDocument>
        name="tax"
        title="Tax"
        value={ (document) => taxValue(revenueService, document) }
        width="20%"
      />
      <CollectionTable.AmountColumn<IRevenueServiceDocument>
        name="pp_fees"
        title="Fees & Expense Adjustments"
        value={ (document) => ppfValue(revenueService, document) }
        width="20%"
      />
      <CollectionTable.ButtonColumn<IRevenueServiceDocument>
        isVisibleHeader
        name="status"
        title="Status"
        value={ (document) => revenueServiceStatus(activeTooltipId, document, integrationId, handleToggleTooltip) }
        width="18%"
      />
      <CollectionTable.IconColumn<IRevenueServiceDocument>
        name="qbo_status"
        title="QuickBooks Status"
        value={ (document) => qboStatus(document) }
      />

      <CollectionTable.IconColumn<IRevenueServiceDocument>
        name="chat"
        title="Chat"
        value={ (document) => documentChat(revenueService, document) }
      />
      <CollectionTable.ActionsColumn<IRevenueServiceDocument>
        name="actions"
        title="Actions"
        value={ (document) => actions(document, revenueSystem) }
      />
    </CollectionTable>
  );
};

export default React.memo(RevenueCaptureTable);
