module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      __out.push('<div class="modal fade">\n    <div class="modal-dialog">\n      <div class="modal-content">\n        <div class="modal-header">\n          <button class=\'close cancel\' type=\'button\'>\n            <span>&times;</span>\n          </button>\n          <h4 class="modal-title font-28 font-bold">Edit Profile</h4>\n        </div>\n        <div class="modal-body">\n            <div class="update-avatar-container edit-profile-avatar-container">\n                <div class="profile-picture-drop-zone relative m-t-27 p-t-50 p-b-50">\n                    ');
    
      if (!this.isUploading) {
        __out.push('\n                    <div class="text-center">\n                        <img class="change-avatar-img-wrapper" src="');
        __out.push(__sanitize(configData.images['new_photo_logo']));
        __out.push('">\n                        <p class="m-t-20 font-20 text-center">Drag and drop a photo here.</p>\n                        <p class="m-t-10 m-b-10 font-20 text-center">OR</p>\n                        <button class="btn btn-blue-on-hover fileinput-button font-16">\n                            Select from your computer\n                            <input type="file" name="files[]" class="profile-picture-file-input">\n                        </button>\n                    </div>\n                    ');
      } else {
        __out.push('\n                    <div class="text-center p-t-70">\n                        <p class="file-upload-status-text font-18">Uploading... <span>0</span>%</p>\n                        <div class=\'upload-file-statusbar m-l-auto m-r-auto\'>\n                            <div class=\'file-status-line\' style=\'width:0%\'></div>\n                        </div>\n                    </div>\n                    ');
      }
    
      __out.push('\n                </div>\n            </div>\n            <div class="text-center p-l-20 p-r-20 update-profile-container">\n                <div class="m-t-40">\n                    <div class="profile-avatar-wrapper m-l-auto m-r-auto">\n                        ');
    
      if (Docyt.currentAdvisor.getAdvisorAvatarUrl()) {
        __out.push('\n                        <img class="avatar-img-wrapper" src="');
        __out.push(__sanitize(Docyt.currentAdvisor.getAdvisorAvatarUrl()));
        __out.push('">\n                        ');
      } else {
        __out.push('\n                        <i class="avatar-img-wrapper icon icon-smile in-blue-400"></i>\n                        ');
      }
    
      __out.push('\n                    </div>\n                </div>\n                <a class="change-avatar-link">Change Profile Picture</a>\n                <input type="text" class="full-name-change-wrapper m-b-20 font-18 text-center" value="');
    
      __out.push(__sanitize(Docyt.currentAdvisor.get('full_name')));
    
      __out.push('" placeholder="Full Name" />\n            </div>\n        </div>\n        <div class="modal-footer">\n          <a class=\'cancel-link cancel settings-cancel-btn font-bold\'>Cancel</a>\n          <button type="submit" class="btn btn-red btn-save ladda-button" data-color="blue" data-style="expand-right"><span class="ladda-label">Save</span></button>\n        </div>\n      </div>\n    </div>\n</div>\n<div class="modal-backdrop fade in"></div>\n');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}