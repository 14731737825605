import React from 'react';

import ActionsDropdown from '@src/components/ui/actions_dropdown';

import styles from './styles.module.scss';

// TODO: Need to remove this hard code const from here as we are not considering UI for 1st release
// As per the ENG-28254, we are not showing the upgrade subscription action
const HIDE_UPGRADE_SUBSCRIPTION_ACTION = true;

export const UpgradeSubscriptionAction: React.FC = () => {
  if (HIDE_UPGRADE_SUBSCRIPTION_ACTION) {
    return null;
  }

  return (
    <div className={ styles['upgrade-section'] }>
      <ActionsDropdown.Action
        name="🚀 Upgrade to Full Bookkeeping"
        onClick={ () => window.open('https://docyt.com/contact', '_blank') }
      />
    </div>
  );
};

export default UpgradeSubscriptionAction;
