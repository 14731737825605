import React from 'react';

import Table from '../table';

interface IEmptyViewProps {
  columnsCount: number;
  children: React.ReactNode;
}

const EmptyView = ({ columnsCount, children }: IEmptyViewProps) => {
  return (
    <Table.Row>
      <Table.Cell colSpan={ columnsCount }>
        { children }
      </Table.Cell>
    </Table.Row>
  );
};

export default React.memo(EmptyView);
