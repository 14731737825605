module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var i, len, member, ref;
    
      __out.push('<div class="document-chat-participants border-bottom-gray">\n<ul class="m-b-1 m-t-12">\n  ');
    
      ref = this.chatMembers.models;
      for (i = 0, len = ref.length; i < len; i++) {
        member = ref[i];
        __out.push('\n    <li class="m-l-10 text-center">\n        <div class="header__userpic m-t-5 document-chat-header-avatar-container simple m-b-3">\n        ');
        if (member.get('avatar') && member.get('avatar').avatar_url) {
          __out.push('\n        <img src="');
          __out.push(__sanitize(member.get('avatar').avatar_url));
          __out.push('" class="header__userpic-img">\n        ');
        } else {
          __out.push('\n        <i class="clients__client-status-icon icon icon-smile font-30"></i>\n        ');
        }
        __out.push('\n        </div>\n        <span class="font-10">');
        __out.push(__sanitize(member.get('parsed_fullname')));
        __out.push('</span>\n    </li>\n  ');
      }
    
      __out.push('\n</ul>\n<div class="p-l-5 p-r-5 view-more-participants">\n  <a class="more-participants-btn pointer">+<span class="more-participants-count">0</span> more</a>\n</div>\n</div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}