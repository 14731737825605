import React, { useState } from 'react';

import classNames from 'classnames';
import moment from 'moment';

import { useBusinessContext } from '@src/hooks/contexts/business_context';
import { useLDBusinessFeatureQuery } from '@src/hooks/queries/launch_darkly_features';
import { useGetSystemCategories } from '@src/hooks/queries/transaction_service_document';
import { verifyTransactionServiceDocument } from '@src/requests/transaction_service_documents';
import {
  ITransactionServiceDocument,
  IUpdateTransactionServiceDocumentData,
} from '@src/types/transaction_service_documents';

import MatchedDocumentDetails from '@src/components/common/matched_document/details/matched_document_details';
import PastTransactionsDetails from '@src/components/common/past_transactions/details/past_transactions_details';
import RelatedTransactionDetails from '@src/components/common/related_transaction/details/related_transactions_details';
import AskDocytAI from '@src/components/reconciliation_center/view_transaction_context/askDocytAI';
import { TOption } from '@src/components/ui_v2/inputs';
import { TabItem } from '@src/components/ui_v2/tabs';
import { NotAChargebackIcon } from '@src/components/utils/icomoon';

import Chat from './chat';
import useProgressModal from './modals/progress_modal';
import useQuickbooksWarningModal from './modals/quickbooks_warning_modal';
import useResultModal from './modals/result_modal';
import { PayrollCharges } from './transactionType';
import AssetWithdrawal from './transactionType/asset_withdrawal';
import EquityWithdrawal from './transactionType/equity_withdrawal';
import EquityInvestment from './transactionType/equityInvestment';
import Expense from './transactionType/expense';
import LiabilityPayments from './transactionType/liability_payments';
import LoanPayments from './transactionType/loan_payments';
import LoanProceeds from './transactionType/loan_proceeds';
import OtherIncome from './transactionType/other_income';
import OtherReceivable from './transactionType/otherReceivable';
import Revenue from './transactionType/revenue';
import RevenueReversal from './transactionType/revenue_reversal';
import Transfer from './transactionType/transfer';

import styles from '../styles.module.scss';

interface ISidebarProps {
  onClose: () => void;
  rowData: ITransactionServiceDocument;
  businessId: number;
  isLoading: boolean;
  onRefreshData: () => void;
  setRowData: (rowData: ITransactionServiceDocument) => void
}

type TabType = 'data' | 'chat';

interface ITabConfig {
  value: TabType;
  label: string;
}

const TABS: ITabConfig[] = [
  { value: 'data', label: 'Data' },
  { value: 'chat', label: 'Chat' },
];

const transactionTypes = window.configData.transaction_types;

const Sidebar = ({
  onClose,
  rowData,
  businessId,
  isLoading,
  onRefreshData,
  setRowData,
}: ISidebarProps) => {
  const [activeTab, setActiveTab] = useState<TabType>('data');
  const progressModal = useProgressModal();
  const resultModal = useResultModal();
  const warningModal = useQuickbooksWarningModal();
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [doneCount, setDoneCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [vendor, setVendor] = useState<string>(String(rowData.vendor?.id));
  const business = useBusinessContext();

  const classes = classNames('p-l-25 p-r-25', { 'p-t-25': !transactionTypes.EXPENSE });

  const transactionContextData = {
    id:                    rowData.id,
    businessId,
    documentId:            rowData.documentId,
    isFlaggedOrHasMessage: !!rowData.flaggedState || !!rowData.transactionContextMessage,

    transactionDesc:       rowData.description,
    aiContext:             rowData.descriptionExplained || '',
    clientContext:         rowData.transactionContextMessage || '',
    transactionType:       rowData.transactionType,
    flaggedStatus:         rowData.flaggedState || '',
  };
  const systemCategories = useGetSystemCategories(businessId);

  const handleCategorize = async () => {
    // Check if the transaction date is before the reconciliation start date
    if (
      business.reconciliationStartDate
      && moment(rowData.transactionDate) < moment(business.reconciliationStartDate)
    ) {
      warningModal.open();
      return;
    }

    progressModal.open();
    setDoneCount(0);

    try {
      const updateData: IUpdateTransactionServiceDocumentData = {
        userVendorId:         rowData.vendorId,
        userChartOfAccountId: rowData.chartOfAccountId,
        doNotLearn:           rowData.doNotLearn,
        noPushQbo:            rowData.noPushQbo,
        noDocumentRequired:   rowData.noDocumentRequired,
        // eslint-disable-next-line max-len
        chargebackWonAmount:  rowData.chargebackWonAmount ? Number(rowData.chargebackWonAmount) : null,
        // eslint-disable-next-line max-len
        chargebackLostAmount: rowData.chargebackLostAmount ? Number(rowData.chargebackLostAmount) : null,
      };

      await verifyTransactionServiceDocument({
        transactionId: rowData.id,
        data:          updateData,
      });
      setDoneCount(1);
      setSuccess(true);
    } catch (error: any) {
      setSuccess(false);
      setErrorMessage(error.message);
    } finally {
      progressModal.props.onDone?.();
      resultModal.open();
    }
  };

  const featureQuery = useLDBusinessFeatureQuery(
    businessId,
    window.Docyt.Common.Constants.TRANSACTION_TYPES.ALL_TRANSACTION_UNCATEGORIZED,
  );

  const handleTabChange = (tab: TOption) => {
    setActiveTab(tab.value as TabType);
  };

  if (!featureQuery.data) return null;

  const sidebarContentClasses = classNames(styles['sidebar-content'], {
    [styles['is-loading']]: isLoading,
  });

  const dataContentClasses = classNames(styles['sidebar-data-content'], {
    [styles['is-active']]: activeTab === 'data',
  });

  const chatContentClasses = classNames(styles['sidebar-chat-content'], {
    [styles['is-active']]: activeTab === 'chat',
  });

  const categorizeButtonClasses = classNames(styles['categorize-button'], {
    [styles['is-disabled']]: isButtonDisabled,
  });

  const renderTransactionNotes = () => {
    if (rowData.transactionType === transactionTypes.EXPENSE) {
      let contextMessage = '';

      if (rowData.transactionContextMessage && rowData.transactionContextMessage !== '') {
        contextMessage = rowData.transactionContextMessage;
      } else {
        contextMessage = 'Notes awaited from the client';
      }

      return (
        <div className={ styles['transaction-notes'] }>
          <div>Transaction Notes</div>
          <p className={ styles['transaction-notes-text'] }>{ contextMessage }</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={ styles['sidebar-container'] }>
      <div className={ styles['sidebar-header'] }>
        <span>Categorize Transactions</span>
        <NotAChargebackIcon pointer fontSize={ 20 } onClick={ onClose } />
      </div>

      <div className={ sidebarContentClasses }>
        {isLoading ? (
          <div className={ styles['loading-container'] }>
            <div className={ styles['loading-spinner'] } />
            <span>Loading...</span>
          </div>
        ) : (
          <>
            <div className={ styles['sidebar-tabs'] }>
              {TABS.map((tab) => (
                <TabItem
                  key={ tab.value }
                  activeTab={ { label: tab.label, value: activeTab } }
                  tab={ { label: tab.label, value: tab.value } }
                  onTabChange={ handleTabChange }
                />
              ))}
            </div>

            <div className={ chatContentClasses }>
              <Chat
                businessId={ businessId }
                documentId={ rowData.documentId }
              />
            </div>

            <div className={ dataContentClasses }>
              <div className={ styles['sidebar-common-content'] }>
                <div className={ styles['sidebar-data'] }>
                  <div>Date</div>
                  <input
                    disabled
                    className={ styles['sidebar-data-input'] }
                    type="text"
                    value={ moment(rowData.transactionDate).format('MM/DD/YYYY') }
                  />
                </div>
                <div className={ styles['sidebar-data'] }>
                  <div>Amount</div>
                  <input
                    disabled
                    className={ styles['sidebar-data-input'] }
                    type="text"
                    value={ rowData.amount }
                  />
                </div>
                <div className={ styles['sidebar-data'] }>
                  <div>Description</div>
                  <input
                    disabled
                    className={ styles['sidebar-data-input'] }
                    type="text"
                    value={ rowData.description }
                  />
                </div>

                { renderTransactionNotes() }
              </div>
              <AskDocytAI
                isAllTransactions
                setVendor={ setVendor }
                transactionContextData={ transactionContextData }
              />

              {
                rowData.transactionType === transactionTypes.EXPENSE && (
                  <Expense
                    Verifiy={ setIsButtonDisabled }
                    businessId={ businessId }
                    rowData={ rowData }
                    setRowData={ setRowData }
                    setVendor={ setVendor }
                    vendor={ vendor }
                  />
                )
              }
              {
                rowData.transactionType === 'other_income' && (
                  <OtherIncome
                    Verifiy={ setIsButtonDisabled }
                    businessId={ businessId }
                    rowData={ rowData }
                    setRowData={ setRowData }
                  />
                )
              }

              {
                rowData.transactionType === transactionTypes.TRANSFER
                && <Transfer rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              {
                rowData.transactionType === transactionTypes.LOAN_PAYMENTS
                && <LoanPayments rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              {
                rowData.transactionType === transactionTypes.REVENUE
                  && (
                  <Revenue
                    rowData={ rowData }
                    setRowData={ setRowData }
                    verify={ setIsButtonDisabled }
                  />
                  )
              }
              {
                rowData.transactionType === transactionTypes.EQUITY_INVESTMENT
                && <EquityInvestment rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              {
                rowData.transactionType === transactionTypes.PAYROLL
                  && (
                  <PayrollCharges
                    Verifiy={ setIsButtonDisabled }
                    businessId={ businessId }
                    rowData={ rowData }
                    setRowData={ setRowData }
                    systemCategories={ systemCategories }
                  />
                  )
              }
              {
                (rowData.transactionType === transactionTypes.EXPENSE
                  || rowData.transactionType === transactionTypes.CHECK)
                && (
                  <div className={ classes }>
                    <PastTransactionsDetails
                      business={ business }
                      transaction={ rowData }
                    />
                  </div>
                )
              }
              {
                rowData.transactionType === transactionTypes.REVENUE_REVERSAL
                && <RevenueReversal rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              {
                rowData.transactionType === transactionTypes.EQUITY_WITHDRAWAL
                && <EquityWithdrawal rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              {
                rowData.transactionType === transactionTypes.ASSET_PURCHASE
                && <AssetWithdrawal rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              {
                rowData.transactionType === transactionTypes.OTHER_RECEIVABLES
                && <OtherReceivable rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }
              {
                rowData.transactionType === transactionTypes.LIABILITY_PAYMENTS
                && <LiabilityPayments rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              {
                rowData.transactionType === transactionTypes.LOAN_PROCEEDS
                && <LoanProceeds rowData={ rowData } setRowData={ setRowData } verify={ setIsButtonDisabled } />
              }

              <div className={ classes }>
                <RelatedTransactionDetails transactionId={ rowData.id } />
              </div>
              <MatchedDocumentDetails document={ rowData } />
              <div className={ styles['sidebar-footer'] }>
                <button
                  className={ categorizeButtonClasses }
                  disabled={ isButtonDisabled || rowData.state === 'verified' }
                  type="button"
                  onClick={ handleCategorize }
                >
                  Categorize Transaction
                </button>
              </div>
            </div>
          </>
        )}
      </div>

      <progressModal.Component
        doneCount={ doneCount }
        totalCount={ 1 }
        { ...progressModal.props }
      />

      <resultModal.Component
        errorMessage={ errorMessage }
        success={ success }
        onClose={ onClose }
        onRefreshData={ onRefreshData }
        { ...resultModal.props }
      />

      <warningModal.Component
        actionDateStr={ moment(rowData.transactionDate).format('MM/DD/YYYY') }
        actionDateTitle="Transaction Date"
        actionType="updated"
        documentType="transaction"
        paymentAccountStartDateStr=""
        reconciliationClosingDateStr={ moment(business.reconciliationStartDate).format('MM/DD/YYYY') }
        title=" Verify Bank Transaction"
        { ...warningModal.props }
      />
    </div>
  );
};

export default React.memo(Sidebar);
