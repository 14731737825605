import isEmpty from 'lodash/isEmpty';
import QS from 'qs';

import { underscoreKeys } from '@src/utils/transform_keys';

const parseSearch = (search: string, opts = {}) => (
  QS.parse(
    search || '',
    { ignoreQueryPrefix: true, ...opts },
  )
);

const stringifySearch = (params: object, opts = {}) => (
  QS.stringify(
    params,
    { encodeValuesOnly: true, arrayFormat: 'brackets', ...opts },
  )
);

const urlWithQueryParams = (url: string, params?: object) => {
  if (!params || isEmpty(params)) return url;

  return `${url}?${stringifySearch(underscoreKeys(params))}`;
};

interface IFilterParamProps {
  paramName: string,
  params?: object,
  filterStrategy?: 'replace' | 'merge',
}

const urlWithFilterParam = (
  path: string,
  ...restFilterParams: IFilterParamProps[]
) => {
  const allParams = restFilterParams.map(({ paramName, params, filterStrategy = 'replace' }) => {
    if (!params) return null;

    const filterParams = { [paramName]: encodeURIComponent(stringifySearch(params)) };
    if (filterStrategy === 'merge') filterParams.filter_strategy = filterStrategy;
    return filterParams;
  });

  return urlWithQueryParams(path, Object.assign({}, ...allParams));
};

const isDocytUrl = (linkValue: string | undefined): boolean => {
  if (!linkValue) return false;
  try {
    const url = new URL(linkValue);
    return url.hostname.includes('docyt.com');
  } catch (e) {
    // Invalid URL format
    return false;
  }
};

export {
  parseSearch,
  stringifySearch,
  urlWithFilterParam,
  urlWithQueryParams,
  isDocytUrl,
};
