import React from 'react';

import TransactionContext from './transaction_context';

const ViewTransactionContext: React.FC<{
  setVendor?: React.Dispatch<React.SetStateAction<string>>;
  isAllTransactions?: boolean;
}> = ({ setVendor, isAllTransactions }) => {
  return (
    <TransactionContext
      isAllTransactions={ isAllTransactions }
      setVendor={ setVendor }
    />
  );
};

export default ViewTransactionContext;
