module.exports = function(__obj) {
  if (!__obj) __obj = {};
  var __out = [], __capture = function(callback) {
    var out = __out, result;
    __out = [];
    callback.call(this);
    result = __out.join('');
    __out = out;
    return __safe(result);
  }, __sanitize = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else if (typeof value !== 'undefined' && value != null) {
      return __escape(value);
    } else {
      return '';
    }
  }, __safe, __objSafe = __obj.safe, __escape = __obj.escape;
  __safe = __obj.safe = function(value) {
    if (value && value.ecoSafe) {
      return value;
    } else {
      if (!(typeof value !== 'undefined' && value != null)) value = '';
      var result = new String(value);
      result.ecoSafe = true;
      return result;
    }
  };
  if (!__escape) {
    __escape = __obj.escape = function(value) {
      return ('' + value)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;');
    };
  }
  (function() {
    (function() {
      var contact, i, index, len, ref;
    
      __out.push('<div class=\'modal fade\'>\n  <div class=\'modal-dialog modal-lg contact-picker-modal-dialog\'>\n    <div class=\'modal-content\'>\n        <div class="modal-header">\n          ');
    
      if (this.title) {
        __out.push('\n          <h1 class="modal-title m-l-10 font-bold ');
        __out.push(__sanitize(this.titleAlign));
        __out.push('">');
        __out.push(__sanitize(this.title));
        __out.push('</h1>\n          ');
      } else {
        __out.push('\n          <h1 class="modal-title pull-left m-l-10 font-bold">');
        __out.push(__sanitize(this.label));
        __out.push(':</h1>\n          ');
      }
    
      __out.push('\n        </div>\n        <div class=\'modal-body\'>\n            <div class="contact-search-input-wrapper relative">\n                <label class="font-15 font-bold" for="contact-search-input">');
    
      __out.push(__sanitize(this.inputLabel ? this.inputLabel : 'Contact:'));
    
      __out.push('</label>\n                <input class="" placeholder="Type Name/Email/Phone" id="contact-search-input" autocomplete="off"/>\n                <ul class="dropdown-menu phone-input-dropdown-menu p-t-0 p-b-0" role="listbox">\n                    <li class="pointer">\n                      <input type="text" class="form-control phone-input-js" placeholder="Phone">\n                      <span class="font-12 in-grey-950 m-r-10 m-t-13 pull-right pointer phone-number-select-js">Not in your contacts</span>\n                    </li>\n                    <li class="">\n                      <a class="dropdown-item import-google-contacts-js pointer" role="option"><i class="fa fa-plus m-t-3 p-l-13 p-r-13"></i><span>Import Contacts from Gmail</span></a>\n                    </li>\n                </ul>\n            </div>\n            <div class="contacts-list padding-10">\n                ');
    
      if (this.contacts.length > 0 && this.contactNote) {
        __out.push('<p class="m-t-20 m-l-20">');
        __out.push(__sanitize(this.contactNote));
        __out.push('</p>');
      }
    
      __out.push('\n                <table class="table table-responsive">\n                    <tbody>\n                      ');
    
      ref = this.contacts;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        contact = ref[index];
        __out.push('\n                      <tr>\n                        <td class="avatar-column">\n                          <div class="header__userpic inline-block">\n                            ');
        if (contact.image_url) {
          __out.push('\n                            <img src="');
          __out.push(__sanitize(contact.image_url));
          __out.push('" class="header__userpic-img">\n                            ');
        } else {
          __out.push('\n                            <i class="clients__client-status-icon icon icon-smile"></i>\n                            ');
        }
        __out.push('\n                          </div>\n                        </td>\n                        <td class="font-bold">');
        __out.push(__sanitize(contact.name));
        __out.push('</td>\n                        <td>');
        __out.push(__sanitize(contact.email));
        __out.push('</td>\n                        <td>');
        __out.push(__sanitize(contact.phone));
        __out.push('</td>\n                        <td><a class="pointer remove-contact-js" data-id="');
        __out.push(__sanitize(index));
        __out.push('" data-type="');
        __out.push(__sanitize(contact.type));
        __out.push('" data-user_id="');
        __out.push(__sanitize(contact.user_id));
        __out.push('">Remove</a></td>\n                      </tr>\n                      ');
      }
    
      __out.push('\n                    </tbody>\n                </table>\n            </div>\n            ');
    
      if (this.contacts.length === 0) {
        __out.push('\n            <div class="text-center m-t-120">\n                <p class="font-14">No contacts selected yet. All selected contacts will appear here.</p>\n            </div>\n            ');
      }
    
      __out.push('\n        </div>\n        <div class=\'modal-footer\'>\n          <a class=\'cancel-link cancel pointer\'>Cancel</a>\n          <button type=\'button\' class="btn btn-info ladda-button save-js ');
    
      __out.push(__sanitize(this.contacts.length === 0 && !this.empty_enabled ? 'disabled' : ''));
    
      __out.push('" tabindex="10" data-color="blue" data-style="expand-right"><span class="ladda-label">');
    
      __out.push(__sanitize(this.buttonText ? this.buttonText : 'Save'));
    
      __out.push('</span></button>\n        </div>\n    </div>\n  </div>\n</div>\n<div class="modal-backdrop fade in"></div>');
    
    }).call(this);
    
  }).call(__obj);
  __obj.safe = __objSafe, __obj.escape = __escape;
  return __out.join('');
}