import React, { useState } from 'react';

import BillingPageForm from './form';
import OnboardingPage from '../layout/onboarding_page';

const BILLING_TITLE = 'Get Started With A Free Trial';
const BILLING_SUCCESS_TITLE = 'Billing Method Added Successfully';

const BillingView = () => {
  const [page, setPage] = useState<'billing' | 'billing_success'>('billing');
  const title = page === 'billing' ? BILLING_TITLE : BILLING_SUCCESS_TITLE;

  return (
    <OnboardingPage isHideBorder title={ title }>
      <BillingPageForm page={ page } setPage={ setPage } />
    </OnboardingPage>
  );
};

export default React.memo(BillingView);
