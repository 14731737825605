export const chatChannelCreationMessage = {
  errorCreating: {
    message: 'Error starting conversation',
    title:   'Chat',
  },
  errorSendingMessage: {
    message: 'Error sending message',
    title:   'Chat',
  },
};

export const welcomeMessage = {
  title:        'Welcome to Docyt AI - ',
  second_title: 'your accounting assistant!',
  message:      'What can i assist you with today?',
};
