export const ONBOARDING_PAGE_KEYS = {
  SIGN_UP: 'sign_up',
  VERIFY_EMAIL: 'verify_email',
  SET_PASSWORD: 'set_password',
};

export const ONBOARDING_STATUS_MODES = {
  ONBOARDING_STATUS_SETUP: 'setup',
  ONBOARDING_STATUS_READY: 'ready',
};
