import React from 'react';

import VerifyEmailForm from './form';
import OnboardingPage from '../layout/onboarding_page';

const VerifyEmailPage = () => {
  return (
    <OnboardingPage title="Verify Your Email Address">
      <VerifyEmailForm formId="verify-email-form" />
    </OnboardingPage>
  );
};

export default VerifyEmailPage;
