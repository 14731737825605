import React from 'react';

import pluralize from 'pluralize';

import { IVendorPayment } from '@src/types/vendor_payments';

import { AppLink } from '@src/components/ui_v2/buttons';
import Table from '@src/components/ui_v2/table';

import styles from '@src/components/vendors/my_vendors/details/payments/styles.module.scss';

type TSummaryDetailProps = {
  vendorPayment: IVendorPayment;
  splitType?: boolean;
  onMouseEnter: () => void;
}

const ChartOfAccountLabel = React.memo(({ chartOfAccount }:
  { chartOfAccount: string }) => {
  return (
    <div className={ styles['coa-summary-details'] }>
      <div className={ styles['summary-info-item'] } title={ chartOfAccount }>
        <span>{chartOfAccount}</span>
      </div>
    </div>
  );
});

ChartOfAccountLabel.displayName = 'ChartOfAccountLabel';

const ChartOfAccountDetail = React.memo(({ chartOfAccount }:
  { chartOfAccount: string | string[] }) => {
  if (Array.isArray(chartOfAccount)) {
    return (
      <div className={ styles['coa-summary-details'] }>
        <span className={ styles['multiple-payment-detail'] }>
          {`${chartOfAccount.length} ${pluralize('splits', chartOfAccount.length)}`}
        </span>
      </div>
    );
  }
  return <ChartOfAccountLabel chartOfAccount={ chartOfAccount } />;
});

ChartOfAccountDetail.displayName = 'ChartOfAccountDetail';

const SummaryDetail = ({
  vendorPayment,
  splitType = false,
  onMouseEnter,
}: TSummaryDetailProps) => {
  return (
    <Table wrapperClassName={ styles['table-wrapper'] } onMouseEnter={ onMouseEnter }>
      <Table.Head>
        <Table.Row>
          <Table.HCell>Docyt ID</Table.HCell>
          <Table.HCell>Invoice Date</Table.HCell>
          <Table.HCell>Chart of Account</Table.HCell>
          <Table.HCell>Accrual Period</Table.HCell>
          <Table.AmountHCell>Amount</Table.AmountHCell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            {vendorPayment?.documentId?.map((documentId) => (
              <div key={ documentId } className={ styles['cell-content'] }>
                <AppLink newWindow className={ styles['invoice-no'] } href={ `/document/${documentId}` }>
                  <span onMouseEnter={ onMouseEnter }>{documentId}</span>
                </AppLink>
              </div>
            ))}
          </Table.Cell>
          <Table.Cell>
            {vendorPayment?.documentDates?.map((docDate, index) => (
              <div key={ docDate || index } className={ styles['cell-content'] }>
                <span>{docDate ?? 'NA'}</span>
              </div>
            ))}
          </Table.Cell>
          <Table.Cell>
            {vendorPayment?.chartOfAccount?.map((chart) => (
              <div key={ chart } className={ styles['cell-content'] }>
                {splitType ? (
                  <div className={ styles['coa-summary-details-wrapper'] }>
                    {Array.isArray(chart) ? (
                      chart.map((splitItem) => (
                        <ChartOfAccountLabel key={ splitItem } chartOfAccount={ splitItem } />
                      ))
                    ) : (
                      <ChartOfAccountLabel chartOfAccount={ chart } />
                    )}
                  </div>
                ) : (
                  <ChartOfAccountDetail chartOfAccount={ chart } />
                )}
              </div>
            ))}
          </Table.Cell>
          <Table.Cell>
            {vendorPayment?.accrualDate ? (
              vendorPayment?.accrualDate?.map((accDate, index) => (
                <div key={ accDate || index } className={ styles['cell-content'] }>
                  <span>{accDate ?? 'NA'}</span>
                </div>
              ))
            ) : (
              vendorPayment?.documentId?.map((documentId) => (
                <div key={ documentId } className={ styles['cell-content'] }>
                  <span>NA</span>
                </div>
              ))
            )}
          </Table.Cell>
          <Table.Cell className={ styles['payment-amount-hcell'] }>
            {vendorPayment?.documentAmount?.map((amount, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={ `${index}-${amount}` } className={ styles['cell-content'] }>
                <span className={ styles['payment-amount-label'] }>{amount ? `$${amount}` : 'NA'}</span>
              </div>
            ))}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
      <Table.Foot>
        <Table.Row className={ styles['table-footer'] }>
          <Table.Cell colSpan={ 4 }>Total Payment Amount:</Table.Cell>
          <Table.AmountCell amount={ vendorPayment.amount } />
        </Table.Row>
      </Table.Foot>
    </Table>
  );
};

export default SummaryDetail;
